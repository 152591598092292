import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IBookmarks } from "./bookmark-interface";
import { Wellbeing } from "../challenges/challenges.interface";

const initBookmarks = {
  wtools: [],
  wresources: [],
  sresource: [],
  cstudies: [],
  loading: false,
  error: "",
};

const bookmarksState = createSlice({
  name: "@@bookmarks",
  initialState: initBookmarks,
  reducers: {
    setBookmarks: (
      state: IBookmarks,
      action: PayloadAction<Partial<Wellbeing>[]>
    ) => {
      action.payload.map((item: Partial<Wellbeing>) => {
        if (item.type) {
          state[`${item.type}`].push(item);
          //   state[`${item.type}`].push({ title: "" });
        }
      });
    },
    reset: () => initBookmarks,
  },
});

export const { reset, setBookmarks } = bookmarksState.actions;

export default bookmarksState.reducer;
