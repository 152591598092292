import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { get_all_posts } from "store/blog/blog-thunk";
import { Post, categories } from "store/blog/blog.interfaces";
import s from "./Blog.module.css";
import { NavLink } from "react-router-dom";
import { Button, Htag } from "components";
import {
  Checkbox,
  Chip,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { GetInTouch } from "layout/GetInTouch/GetInTouch.component";
import { Helmet } from 'react-helmet';

const MenuProps = {
  PopoverClasses: {
    root: "customMenuPopover",
  },

  style: { backgroundColor: "#ff0000!important" },
};

const BootstrapInput = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    borderRadius: 0,
    position: "relative",
    backgroundColor: "transparent",

    fontSize: 16,
    height: 30,
    minHeight: 28,
    padding: 0,
  },

  "& .MuiInputBase-input:focus": {
    borderRadius: 0,
  },
}));
export const Blog = () => {
  const dispatch = useAppDispatch();
  const userToken = useAppSelector((state) => state.userState.token);
  const [categoriesSelect, setCategoriesSelect] = useState<string[]>([]);

  const posts = useAppSelector((state) => state.blogPostsState.userPosts);
  const [pageState, setPageState] = useState<{
    data: Post[];
    total: number;
    page: number;
    pageSize: number;
  }>({
    data: [],
    total: 0,
    page: 1,
    pageSize: 6,
  });

  const handleCategoies = (
    event: SelectChangeEvent<typeof categoriesSelect>
  ) => {
    const {
      target: { value },
    } = event;

    setCategoriesSelect(typeof value === "string" ? value.split(",") : value);
  };

  const loadMore = () => {
    setPageState((old) => ({ ...old, page: pageState.page + 1 }));
  };

  useEffect(() => {
    dispatch(get_all_posts({ page: pageState.page, limit: pageState.pageSize }))
      .unwrap()
      .then((resp) => {
        setPageState((old) => ({
          ...old,
          data: resp.data,
          total: resp.meta.total,
        }));
      });
  }, [dispatch, pageState.page, pageState.pageSize]);

  return (
    <>
      <Helmet>
        <title>Insights on Divorce | SeparateSpace</title>
        <meta
          name="description"
          content="Read the latest articles and insights on separation and divorce. The SeparateSpace blog provides valuable information and advice to help you navigate your journey."
        />
      </Helmet>
      <div className="section">
        <div className={s.filter}>
          <p>
            <strong>Filter by interest</strong>
          </p>
          <FormControl sx={{ m: 1, minWidth: 120 }} className={s.selectFilter}>
            {!categoriesSelect.length && (
              <InputLabel className={s.filterLabel}>All</InputLabel>
            )}
            <Select
              value={categoriesSelect}
              onChange={handleCategoies}
              displayEmpty
              multiple
              renderValue={(selected) => (
                <div className={s.filterChips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} variant="outlined" />
                  ))}
                </div>
              )}
              label="All"
              MenuProps={MenuProps}
              input={<BootstrapInput className={s.inputSelect} />}
            >
              {categories.map((x, index) => (
                <MenuItem key={index} value={x}>
                  <Checkbox checked={categoriesSelect.indexOf(x) > -1} /> {x}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={s.postsList}>
          {posts
            .filter((x) => {
              if (!categoriesSelect.length) return x;
              if (!x.categories && categoriesSelect) return !x;

              return x.categories.some((ai) => categoriesSelect.includes(ai));
            })
            .map((post) => (
              <NavLink
                key={post.id}
                to={`/blog/${post.slug}`}
                className={s.postItem}
              >
                <div
                  className={s.postImg}
                  style={{ backgroundImage: `url("${post.image}"` }}
                ></div>
                <div className={s.postData}>
                  <Htag className={s.post_title} tag="h4">
                    {post.title}
                  </Htag>

                  {post.categories && (
                    <div className={s.categories}>
                      {post.categories.map((tag: string, index) => (
                        <Chip key={Math.random()} label={`${tag}`} />
                      ))}
                    </div>
                  )}
                </div>
              </NavLink>
            ))}
        </div>
        {pageState.total > 6 && posts.length !== pageState.total && (
          <Button
            className={s.loadMoreBtn}
            apearance="default"
            onClick={loadMore}
          >
            Load more
          </Button>
        )}
      </div>
      {!userToken && <GetInTouch isHome={false} />}
    </>
  );
};
