import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import { IMember, IWaitingList } from "./waitingList.interface";
import {
  deleteMember,
  get_subscribers_list,
  get_waiting_list,
} from "./waitingList-thunk";
import {
  refundDelete,
  refundRequestList,
  refundResponse,
} from "store/stripe/stripe-thunk";

const initialState: IWaitingList = {
  waitingList: [],
  subscribers: {
    users: [],
    totalPages: 0,
    filterModel: { items: [] },
  },
  refundsList: {
    users: [],
    totalUsers: 0,
    totalPages: 0,
  },
  error: "",
  loading: false,
};

const waitingList = createSlice({
  name: "@@waitingList",
  initialState,
  reducers: {
    setFilterModel: (state, action) => {
      state.subscribers.filterModel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initialState;
      })
      .addCase(
        get_waiting_list.fulfilled,
        (state: IWaitingList, action: PayloadAction<IMember[]>) => {
          state.waitingList = action.payload;
        }
      )

      .addCase(refundRequestList.fulfilled, (state, action) => {
        state.refundsList.users = action.payload.data;
        state.refundsList.totalUsers = action.payload.meta.total;
      })
      .addCase(get_subscribers_list.fulfilled, (state, action) => {
        state.subscribers.users = action.payload.data;
        state.subscribers.totalPages = action.payload.meta.total;
      })

      .addCase(deleteMember.fulfilled, (state, action) => {
        state.subscribers.users = state.subscribers.users.filter(
          (item) => item.id !== action.meta.arg
        );
      })

      .addCase(refundResponse.fulfilled, (state, action) => {
        state.refundsList.users = state.refundsList.users.map((user) => {
          if (user.id == Number(action.meta.arg.id)) {
            user.status = "succeeded";
            return user;
          }
          return user;
        });
      })
      .addCase(refundDelete.fulfilled, (state, action) => {
        state.refundsList.users = state.refundsList.users.filter(
          (item) => item.id !== Number(action.meta.arg)
        );
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;

          if (action.payload) {
            state.error = action.payload;
          } else if (action.error.message) {
            state.error = action.error.message;
          } else {
            state.error = "Some server error";
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = "";
          state.loading = false;
        }
      );
  },
});
export const { setFilterModel } = waitingList.actions;
export default waitingList.reducer;
