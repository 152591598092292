import { Card } from "components";
import { AttachmentItemProps } from "./AttachmentItemProps";
import { getAttachTypeName } from "utils/utils";
import { Chip } from "@mui/material";
import s from "./AttachmentItem.module.css";
import { StackItemComponent } from "components/StackItem/StackItem.component";
export const AttachmentItemComponent = ({
  att,

  ...props
}: AttachmentItemProps) => {
  const data = {
    title: att.name || "",
    type: att.type || "sresource",
  };

  return (
    <StackItemComponent data={data}>
 

      <Chip
        label={getAttachTypeName(+att.type)?.toLowerCase()}
        variant="outlined"
        size="medium"
        className={s.mailChimp}
      />
    </StackItemComponent>
  );
};
