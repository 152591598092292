import s from "../WellbeingAdmin.module.css";

import { useParams } from "react-router-dom";
import { AddFiledIcon, Button, ErrorField, Htag } from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { Challenge } from "../../../../store/challenges/challenges.interface";
import { useState } from "react";

import { updateChallenge } from "../../../../store/challenges/challenges-thunk";
import { Alert, FormControl, Snackbar } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";

export const EditChallenge = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const challenge: Challenge = useAppSelector((state) =>
    state.wellbeing.challenges.challenges.filter(
      (challenge: Challenge) => challenge.id == id
    )
  )[0];

  const validationChallengeAdd = yup.object({
    title: yup.string().required("Title is required"),
  });

  const formikAddChallenge = useFormik({
    initialValues: { title: challenge.title },
    validationSchema: validationChallengeAdd,
    onSubmit: (values) => {
      dispatch(
        updateChallenge({ challenge_id: Number(id), title: values.title })
      )
        .unwrap()
        .then(() => {
          setOpen(true);
        });
    },
  });
  const updateChallengeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    formikAddChallenge.handleChange(e);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div className={s.wellbeing_form}>
        <Htag tag="h3">Edit {challenge.title} description</Htag>
        <form onSubmit={formikAddChallenge.handleSubmit}>
          <FormControl fullWidth>
            <input
              id="title"
              name="title"
              type="text"
              value={formikAddChallenge.values.title || ""}
              onChange={(e) => updateChallengeData(e)}
              onBlur={formikAddChallenge.handleBlur}
            />
            {formikAddChallenge.touched.title &&
            formikAddChallenge.errors.title ? (
              <ErrorField error={formikAddChallenge.errors.title} />
            ) : null}
          </FormControl>
          <Button apearance="primary-light" type="submit">
            <AddFiledIcon />
            Save Challenge
          </Button>
        </form>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Description is updated!
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};
