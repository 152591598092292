import s from "./GeneralOnboarding.module.css";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import {
  addResourceId,
  removeResourceId,
} from "store/system_resources/system_resource-reducer";
import { Accordion, Button, ErrorField, Htag } from "components";
import { QuestionnaireProps } from "pages/Dashboard/Spaces/Questionnaire.props";
import { useFormik } from "formik";

import * as yup from "yup";
import { addId, removeId } from "store/system_tickets/system_ticket-reducer";

const validationForm10 = yup.object({
  pensions: yup.string().nullable().required("Please select an option."),
});

const desc = `<p>Pensions are treated differently from other assets (such as houses, cars and savings) because most people can’t access them immediately.  They are also subject to different tax rules to other categories of assets and income.  Unlike those other assets, a pension is not available until retirement age.</p>
  <p>The court has specific powers to divide and transfer pension pots between spouses on divorce.  The person receiving a share as part of the divorce settlement can purchase a pension in their own name which will provide them with their own income on retirement.</p>`;

export const FormGB10 = ({
  handleBack,
  handleNextStep,
  setData,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );
  const dispatch = useAppDispatch();

  const updateInterview = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      if (value == "yes" || value == "dont_know") {
        dispatch(addResourceId(["FR13", "FR25", "NG6", "FR26", "NG34"]));
        dispatch(addId(["F13"]));
      } else if (value == "no") {
        dispatch(removeResourceId(["FR13", "FR25", "NG6", "FR26", "NG34"]));
        dispatch(removeId(["F13"]));
      }

      formik.handleChange(event);
      setData(name, value);
    },
    [dispatch, generalOnboarding]
  );

  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationForm10,
    onSubmit: () => {
      handleNextStep();
    },
  });

  return (
    <form id="form-10" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>

      <>
        <Htag tag="h3">Do you or your ex have any pensions? </Htag>
        <div className={s.form_oneline}>
          <div className={s.formField}>
            <RadioGroup row name="pensions" className={s.radioboxes}>
              <FormControlLabel
                value="yes"
                control={<Radio color="default" onChange={updateInterview} />}
                label="Yes"
                checked={formik.values.pensions == "yes"}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" onChange={updateInterview} />}
                label="No"
                checked={formik.values.pensions == "no"}
              />
              <FormControlLabel
                value="dont_know"
                control={<Radio color="default" onChange={updateInterview} />}
                label="I don't know"
                checked={formik.values.pensions == "dont_know"}
              />
            </RadioGroup>
            {formik.touched.pensions && formik.errors.pensions && (
              <ErrorField error={formik.errors.pensions} />
            )}
          </div>
        </div>
      </>

      <Accordion
        className={s.desc}
        accordionData={[
          {
            title: `Why are we asking?`,
            content: desc,
          },
        ]}
      />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
