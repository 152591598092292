import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import s from "../SpecialSources.module.css";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Button, Htag } from "components";
import dayjs from "dayjs";
import { deleteCS, get_all_cs } from "store/caseStudy/caseStudy_thunk";
import { ICaseStudies } from "store/caseStudy/caseStudy.interface";
interface deleteObj {
  id: number;
  delFunc: (id: number) => void;
}
export const ListCaseStudies = () => {
  const dispatch = useAppDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [checkedItem, setCheckedItem] = useState<deleteObj | null>(null);
  const case_studies: ICaseStudies[] = useAppSelector(
    (state) => state.caseStudiesState.caseStudiesArray
  );

  const copy = async (id: string) => {
    await navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/dashboard/case_studies/${id}`
    );
  };
  useEffect(() => {
    dispatch(get_all_cs());
  }, [dispatch]);

  const confirmDelete = () => {
    checkedItem?.delFunc(checkedItem.id);
    setCheckedItem(null);
    setOpenDeleteDialog(false);
  };

  const handleClose = () => {
    setOpenDeleteDialog(false);
    setCheckedItem(null);
  };

  const handleDeleteCS = (id: number) => {
    if (!id) return;
    dispatch(deleteCS(id));
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: s.headerClassName,
      width: 70,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "title",
      headerClassName: s.headerClassName,
      headerName: "Title",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },

    {
      field: "created_at",
      headerName: "Create Date",
      headerClassName: s.headerClassName,
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <>{dayjs(params.row.created_at).format("MMMM DD, YYYY")}</>;
      },
    },
    {
      field: "action",
      headerName: `Action`,
      headerClassName: s.headerClassName,
      sortable: false,
      width: 160,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className={s.actions}>
            <NavLink
              to={`/admin-dashboard/caseStudies/edit-page/${params.row.id}`}
            >
              <IconButton aria-label="edit" color="info">
                <Edit />
              </IconButton>
            </NavLink>

            <ContentCopyIcon
              className={s.externalLink}
              onClick={() => copy(params.id.toString())}
            />
            <IconButton
              aria-label="delete"
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                // handleDeleteTool(Number(params.id));
                setCheckedItem({
                  id: Number(params.row.id),
                  delFunc: handleDeleteCS,
                });
                setOpenDeleteDialog(true);
              }}
            >
              <Delete />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Htag tag="h3">List of case studies</Htag>
      <Box sx={{ height: 838, width: "100%" }}>
        <DataGrid
          rows={case_studies}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          className={s.tableWrap}
          pageSizeOptions={[10, 30, 50, 70, 100]}
        />
      </Box>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={s.deleteConfirmDialog}
      >
        <DialogContent className={s.deleteConfirmText}>
          Please, confirm deleting
        </DialogContent>
        <DialogActions className={s.deleteConfirmBtn}>
          <Button apearance="secondaryDark" onClick={handleClose}>
            NO
          </Button>
          <Button apearance="secondaryDark" onClick={confirmDelete}>
            AGREE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
