import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import { GET_ALL_RESULTS } from "../config";
import { ISearchResult } from "./search-interface";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const get_search_result = createAsyncThunk<
  ISearchResult[],
  string,
  { getState: IInitState; rejectValue: string }
>("@@search", async function (search, { getState, rejectWithValue, dispatch }) {
  return await getRequestFabric(
    API_TYPE.GET,
    getState,
    GET_ALL_RESULTS(search),
    rejectWithValue,
    dispatch
  );
});
