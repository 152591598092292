import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "utils/hooks";
import { setSourceVisible } from "store/challenges/challenges-reducer";

import {
  loadSingleResource,
  loadSingleTool,
  viewedResource,
  viewedTool,
} from "store/challenges/challenges-thunk";
import s from "./Wellbeing.module.css";
import { Wellbeing } from "store/challenges/challenges.interface";
import { Page404 } from "../../404/404.component";
import { ArticleContent } from "components";

export const WellbeingArticleComponent = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [type, setType] = useState("");
  const href = window.location.pathname;
  const getId = (str: string | undefined) => {
    if (!str) return;
    const numsStr = str.replace(/[^0-9]/g, "");
    return parseInt(numsStr);
  };
  const item: Wellbeing = useAppSelector((state) => {
    if (href.includes("resource")) {
      return state.wellbeing.resources.filter(
        (item: Wellbeing) => item.id == getId(id)
      )[0];
    } else {
      return state.wellbeing.tools.filter(
        (item: Wellbeing) => item.id == getId(id)
      )[0];
    }
  });

  useEffect(() => {
    if (!item) return;
    if (href.includes("resource") && item.id) {
      dispatch(viewedResource(item.id))
        .unwrap()
        .then(() => {
          dispatch(setSourceVisible({ id: item.id, type: "resource" }));
        });
    }
    if (href.includes("tool") && item.id) {
      dispatch(viewedTool(item.id))
        .unwrap()
        .then(() => {
          dispatch(setSourceVisible({ id: item.id, type: "tool" }));
        });
    }
  }, [dispatch, href]);

  useEffect(() => {
    if (!getId(id)) return;
    if (!item || !Object.prototype.hasOwnProperty.call(item, "content")) {
      if (href.includes("resource")) {
        dispatch(loadSingleResource(Number(getId(id))));
      }
      if (href.includes("tool")) {
        dispatch(loadSingleTool(Number(getId(id))));
      }
    }
  }, []);

  useEffect(() => {
    if (href.includes("resource")) {
      setType("wresources");
    } else {
      setType("wtools");
    }
  }, []);

  return (
    <>
      {item ? (
        <div className={s.article}>
          <ArticleContent data={item} type={type} />
        </div>
      ) : (
        <Page404 className={s.componentNotFound} />
      )}
    </>
  );
};
