import * as yup from "yup";
export const validationCreateSchema = yup.object({
  title: yup
    .string()
    .min(3, "Title should be at least 3 characters length")
    .required("Title is required"),
  reading_time: yup.string().nullable().required("Title is required"),
  need_journal: yup.bool(),
  content: yup.string().required("Content is required"),
});

export const validationCreatePage = yup.object({
  title: yup
    .string()
    .min(3, "Title should be at least 3 characters length")

    .required("Title is required"),

  content: yup.string().required("Content is required"),
});


export const validationCreateSchemaTicket = yup.object({
  title: yup
    .string()
    .min(3, "Title should be at least 3 characters length")

    .required("Title is required"),
  reading_time: yup.string(),

  description: yup.string().required("Content is required"),
});