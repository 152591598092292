import {  FormControl, SelectChangeEvent } from "@mui/material";
import { useRef, useState } from "react";

import {
  Button,
  ErrorField,
  Htag,
  EditorComponent,
  InputImageComponent,
} from "components";
import s from "./AddPage.module.css";
import a from "../SpecialSources.module.css";
import { createNewPage } from "store/simplePages/simplePages-thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import { useFormik } from "formik";
import { validationCreatePage } from "../validation";
import { formTypes, pageTemplates } from "./config";
import { MarketingPage } from "models/PageTemplates.model";
import { SelectComponent } from "components/Select/Select.component";
import cn from "classnames";
import {
  CustomSnackbar,
  CustomSnackbarRef,
} from "components/CustomSnackbar/CustomSnackbar.component";

export const AddPage = () => {
  const [pageTemplate, setPageTemplate] = useState("");
  const [formType, setFormType] = useState<number>(1);

  const dispatch = useAppDispatch();

  const { error } = useAppSelector((state) => state.specialTicketsState);
  const ref = useRef<CustomSnackbarRef>(null);

  const formik = useFormik({
    initialValues: new MarketingPage(),
    validationSchema: validationCreatePage,
    onSubmit: (values) => {
      const updatedValue = formik.initialValues.formData(values);
      dispatch(createNewPage(updatedValue))
        .unwrap()
        .then(() => {
          formik.resetForm();

          ref.current?.refresh();
        });
    },
  });

  const handlePageTemplate = (e: SelectChangeEvent) => {
    const value = e.target.value;

    formik.setFieldValue("type", value);

    setPageTemplate(value);
  };

  const handleFormType = (e: SelectChangeEvent) => {
    setFormType(Number(e.target.value));
    formik.setFieldValue("form_type", e.target.value);
  };

  return (
    <>
      <Htag tag="h3">Add New Page</Htag>

      <form onSubmit={formik.handleSubmit}>
        <div className={a.formField}>
          <Htag tag="h4">Templates: </Htag>
          <SelectComponent
            selectData={pageTemplate}
            label={pageTemplate === null ? "Page template" : ""}
            selectArray={pageTemplates}
            handleData={handlePageTemplate}
          />
        </div>
        <div className={a.formField}>
          <Htag tag="h4">Title</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>
        {pageTemplate !== "default" && (
          <div className={a.formField}>
            <Htag tag="h4" className={s.headline}>
              Upload preview image
            </Htag>
            <InputImageComponent
              handleFiles={formik.setFieldValue}
              handleOnBlur={formik.setFieldValue}
              file={formik.values.image}
              id="image"
              name="image"
            />
          </div>
        )}
        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            handleChange={(e) => formik.setFieldValue("content", e)}
          />

          {formik.touched.content && formik.errors.content && (
            <ErrorField error={formik.errors.content} />
          )}
        </div>
        <div className={a.formField}>
          <Htag tag="h4">Meta data:</Htag>
          <FormControl fullWidth className={cn(s.title, s.meta)}>
            <p>
              <input
                id="meta_title"
                name="meta.title"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.meta.title}
                onChange={formik.handleChange}
                placeholder="Meta title"
              />
              {formik.touched.meta?.title && formik.errors.meta?.title && (
                <ErrorField error={formik.errors.meta.title} />
              )}
            </p>
            <p>
              <input
                id="meta_description"
                name="meta.description"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.meta.description}
                onChange={formik.handleChange}
                placeholder="Meta description"
              />
              {formik.touched.meta?.description &&
                formik.errors.meta?.description && (
                  <ErrorField error={formik.errors.meta.description} />
                )}
            </p>
          </FormControl>
        </div>
        {pageTemplate == "marketing" && (
          <div className={a.formField}>
            <Htag tag="h3">Choose form</Htag>
            <div>
              <SelectComponent
                selectData={formType}
                label={formType === null ? "Type of form" : ""}
                selectArray={formTypes}
                handleData={handleFormType}
              />
            </div>
          </div>
        )}
        {error &&
          Array.isArray(error) &&
          error.map((item, index) => (
            <ErrorField
              key={index}
              error={`Server error: ${Object.values(item)[0]}`}
            />
          ))}
        {error && typeof error == "string" && <ErrorField error={error} />}
        <Button apearance="secondary" type="submit">
          Save
        </Button>
      </form>
      <CustomSnackbar message={"Page is created successfully"} ref={ref} />
    </>
  );
};
