import { createAsyncThunk } from "@reduxjs/toolkit";
import { MEMORY_CREATE } from "store/config";
import { IInitState } from "store/root-interface";
import { API_TYPE, getRequestFabric } from "../thunk-helper";

export interface IMemory {
  id?: number;
  name: string;
  value: string;
}

export const createMemory = createAsyncThunk<
  { success: string; memory: IMemory },
  IMemory,
  { getState: IInitState; rejectValue: string }
>(
  "@@memory/createMemory",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      MEMORY_CREATE(),
      rejectWithValue,
      dispatch,
      JSON.stringify(data)
    );
  }
);
