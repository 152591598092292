import { Htag } from "components";

import { Outlet } from "react-router-dom";
import s from "./Wellbeing.module.css";
import cn from "classnames";
import { useAppSelector } from "utils/hooks";

export const WellbeingPage = () => {
  const { routeState } = useAppSelector((state: any) => state.routeState);

  return (
    <>
      <Outlet />
    </>
  );
};
