import { NavLink } from "react-router-dom";
import { Card, Challenges, Emotions } from "components";

import s from "./Wellbeing.module.css";
import cn from "classnames";
import { useAppDispatch } from "utils/hooks";
import { useEffect } from "react";
import { loadResources, loadTools } from "store/challenges/challenges-thunk";

export const WellBeingIndex = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadResources());
    dispatch(loadTools());
  }, [dispatch]);

  return (
    <div className={s.wellbeingWrap}>
      <div className={s.wellbeing_explain_text}>
        <p>Welcome to your Wellbeing Space.</p>
        <p>What do you want to do today?</p>
      </div>
      <div className={s.WellBeingChallenges}>
        <NavLink to="/dashboard/my-wellbeing/feelling">
          <Card className={cn(s.challenge_item, s.item_feel)}>
            <div className={s.emoji}>
              <Emotions />
            </div>
            <div className={s.card_title}>Explore how I’m feeling</div>
          </Card>
        </NavLink>
        <NavLink to="/dashboard/my-wellbeing/challenges">
          <Card className={cn(s.challenge_item, s.item_challenge)}>
            <div className={s.chalangeImg}>
              <Challenges />
            </div>
            <div className={s.card_title}>
              Explore challenges
              <br /> I'm facing
            </div>
          </Card>
        </NavLink>
      </div>
    </div>
  );
};
