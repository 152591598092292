import s from "./Ticket.module.css";
import cn from "classnames";
import {
  deleteTicket,
  setNewTicketStatus,
} from "../../../store/tickets/tickets-thunk";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { Draggable } from "react-beautiful-dnd";
import { useState } from "react";
import { Checkbox } from "@mui/material";
import { addToEndBoard } from "../../../store/tickets/tickets-reducer";

import { Card, Htag } from "../..";

import { TicketItemProps } from "./Ticket.props";
import { TicketModalView } from "../../TicketModalView/TicketModalView.component";
import { TicketItemView } from "./TicketItemView.component";
import { getDots } from "../../../utils/utils";
import ReactHtmlParser from "html-react-parser";

const TicketItem = ({ ticket, ...props }: TicketItemProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const boards = useAppSelector((state) => state.tickets.boards);

  const [openTicket, setOpenTicket] = useState<boolean>(false);
  const handleOpenTicket = (e: any) => {
    setOpenTicket(true);
  };
  const handleCloseTicket = (): void => {
    setOpenTicket(false);
  };

  const handleDeleteTicket = () => {
    dispatch(deleteTicket(ticket.id));
  };

  const handleSetStatus = (id: number): void => {
    const el = {
      ticket_id: ticket.id,
      status: id,
      sort: boards[id].tickets.length,
    };

    dispatch(setNewTicketStatus(el))
      .unwrap()
      .then(() => {
        dispatch(addToEndBoard(el));
      });
  };

  return (
    <>
      <Draggable
        draggableId={ticket.id ? ticket.id.toString() : ""}
        index={props.index}
      >
        {(provided) => (
          <div
            className={s.ticketCardContainer}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
            {...props}
          >
            <Card className={cn(s.ticketCard)}>
              <div className={s.activeTitle}>
                <div className={s.checkbox}>
                  <Checkbox
                    color="default"
                    name="terms"
                    onChange={(e) => {
                      handleSetStatus(Number(!ticket.status));
                    }}
                    checked={!!ticket.status}
                    className={ticket.status ? s.checkboxChecked : s.unchecked}
                  />
                </div>

                <Htag tag="h3" onClick={handleOpenTicket}>
                  {ticket.title && ticket.title.slice(0, 66)}
                </Htag>
              </div>

              <div className={s.shortDesc} onClick={handleOpenTicket}>
                {ReactHtmlParser(getDots(ticket.description, 66))}
              </div>
            </Card>
          </div>
        )}
      </Draggable>

      <TicketModalView open={openTicket} close={handleCloseTicket}>
        <TicketItemView
          complete={(value: number) => handleSetStatus(value)}
          ticket={ticket}
          deleteItem={handleDeleteTicket}
        />
      </TicketModalView>
    </>
  );
};

export default TicketItem;
