import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";

import SidebarItem from "./SidebarItem";
import { RouteType } from "../../routes/config";
import { Link } from "react-router-dom";
import s from "./Sidebar.module.css";
import { DropDownArrowIcon } from "../../components";
import { useAppSelector } from "../../utils/hooks";

type Props = {
  item: RouteType;
};

const SidebarItemCollapse = ({ item }: Props) => {
  const [open, setOpen] = useState(false);

  const { routeState } = useAppSelector((state) => state.routeState);

  useEffect(() => {
    if (routeState.includes(item.state)) {
      setOpen(true);
    }
  }, [routeState, item]);

  return item.menuProps ? (
    <>
      {item.path ? (
        <ListItemButton
          onClick={() => {
            setOpen(!open);
          }}
          component={Link}
          to={`${item.path}`}
          className={s.collapsedItem}
        >
          <ListItemText
            disableTypography
            primary={item.menuProps.displayText}
          />
          <ListItemIcon className={s.collapcedIcon}>
            {open ? (
              <DropDownArrowIcon className={s.down} />
            ) : (
              <DropDownArrowIcon />
            )}
          </ListItemIcon>
        </ListItemButton>
      ) : (
        <ListItemButton
          onClick={() => {
            setOpen(!open);
          }}
          className={s.collapsedItem}
        >
          <ListItemText
            disableTypography
            primary={item.menuProps.displayText}
          />
          <ListItemIcon className={s.collapcedIcon}>
            {open ? (
              <DropDownArrowIcon className={s.down} />
            ) : (
              <DropDownArrowIcon />
            )}
          </ListItemIcon>
        </ListItemButton>
      )}
      <Collapse in={open} timeout="auto">
        <List>
          {item.child?.map((route: RouteType, index: number) =>
            route.menuProps ? (
              route.child ? (
                <SidebarItem item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          )}
        </List>
      </Collapse>
    </>
  ) : null;
};
export default SidebarItemCollapse;
