import { Alert, FormControl, Snackbar } from "@mui/material";
import { useFormik } from "formik";
import { Button, Htag } from "../../../components";
import { AddFiledIcon } from "../../../components/Icons/Icons.component";
import * as yup from "yup";

import { addNewChallenge } from "../../../store/challenges/challenges-thunk";
import { useAppDispatch } from "../../../utils/hooks";
import s from "./WellbeingAdmin.module.css";
import { useState } from "react";

export const WellbeingAdmin = () => {
  const dispatch = useAppDispatch();
  const validationChallengeAdd = yup.object({
    title: yup.string().required("Title is required"),
  });
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const formikAddChallenge = useFormik({
    initialValues: { title: "" },
    validationSchema: validationChallengeAdd,
    onSubmit: (values) => {
      dispatch(addNewChallenge(values.title));
      handleClick();
      formikAddChallenge.resetForm();
    },
  });
  const updateChallengeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    formikAddChallenge.handleChange(e);
  };

  return (
    <>
      <div className={s.wellbeing_form}>
        <Htag tag="h3">Add New Challenge</Htag>
        <form onSubmit={formikAddChallenge.handleSubmit}>
          <FormControl fullWidth>
            <input
              id="title"
              name="title"
              type="text"
              placeholder="Add challenge"
              value={formikAddChallenge.values.title || ""}
              onChange={(e) => updateChallengeData(e)}
              onBlur={formikAddChallenge.handleBlur}
            />
            {formikAddChallenge.touched.title &&
            formikAddChallenge.errors.title ? (
              <span>{formikAddChallenge.errors.title}</span>
            ) : null}
          </FormControl>
          <Button apearance="primary-light" type="submit">
            <AddFiledIcon />
            Add Challenge
          </Button>
        </form>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Challenge added!
        </Alert>
      </Snackbar>
    </>
  );
};
