
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPrice, ISubscription } from "./price-interface";

const initialSubscriptions: ISubscription = {
  month: {
    name: "",
    price_id: "",
    type: "",
    price: "",
  },
  halfYear: {
    name: "",
    price_id: "",
    type: "",
    price: "",
  },
  pricesArray: [],
};

const subscriptionsState = createSlice({
  name: "@@prices",
  initialState: initialSubscriptions,
  reducers: {

    addBasicProduct: (state: ISubscription, action: PayloadAction<IPrice>) => {
      state.month = action.payload;

    },
    addJointProduct: (state: ISubscription, action: PayloadAction<IPrice>) => {
       state.halfYear = action.payload;

    },

  },
});

export const {
  addBasicProduct,
  addJointProduct,
} = subscriptionsState.actions;

export default subscriptionsState.reducer;
