import s from "./GeneralOnboarding.module.css";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { useCallback, useEffect } from "react";

import cn from "classnames";
import { QuestionnaireProps } from "pages/Dashboard/Spaces/Questionnaire.props";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  addId,
  resetTicketsIds,
} from "store/system_tickets/system_ticket-reducer";
import {
  addResourceId,
  removeResourceId,
  resetResourcesIds,
} from "store/system_resources/system_resource-reducer";

import { add_system_tickets } from "store/system_tickets/system-ticket-thunk";

import { add_system_resources } from "store/system_resources/system_resource-thunk";
import { Accordion, Button, ErrorField, Htag } from "components";
import * as yup from "yup";
import { useFormik } from "formik";
import { saveGOnboarding } from "store/generalOnboarding/generalOnboarding-thunk";
import { sortArray } from "utils/utils";
import { add_case_studies } from "store/caseStudy/caseStudy_thunk";
import { getUserData } from "store/userInfo/user-thunk";
const validationForm13 = yup.object({
  nuptial_agreement: yup
    .string()
    .nullable()
    .required("Please select an option."),
});

const answers = [
  {
    title: "What is a pre-nuptial agreement?",
    content:
      "A pre-nuptial agreement (sometimes called a ‘pre-nup’) is a contract that a couple enters into before they get married (or enter into a civil partnership).  The contract explains how the couple will divide their finances if their relationship ends.",
  },
  {
    title: "What is a post-nuptual agreement?",
    content:
      "A post-nuptial agreement (sometimes called a ‘post-nup’) is the same as a pre-nup except that is entered into by the couple after they are married (or civil partnered).  So, in short, it’s a formal agreement (a contract) to record how the couple will divide their finances if their relationship ends.",
  },
  {
    title: "Why are we asking this?",
    content:
      "If you have a pre-nuptial or post-nuptial agreement it is likely to impact the financial outcome of your divorce.  If this is relevant to you, we’ll explain what factors (such as each taking independent legal advice at the time) may affect the weight a court would give to the agreement. ",
  },
];
export const FormGB13 = ({
  close,
  handleBack,
  handleNextStep,
  setData,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep, loading } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const loadingTickets = useAppSelector(
    (state) => state.specialTicketsState.loading
  );
  const loadingResources = useAppSelector(
    (state) => state.specialResourcesState.loading
  );

  const dispatch = useAppDispatch();
  const system_tickets_id = useAppSelector(
    (state) => state.specialTicketsState.tickets
  );

  const system_resources_id = useAppSelector(
    (state) => state.specialResourcesState.resources
  );

  const has_children = useAppSelector(
    (state) => state.generalOnBoardingState.generalOnboarding.has_children
  );

  useEffect(() => {
    dispatch(
      addId([
        "D12",
        "D13",
        "D14",
        "F3",
        "F3A",
        "F6",
        "F11",
        "F12",
        "F15",
        "F16",
        "F20",
        "F23",
        "NT1",
        "NT2",
        "NT3",
        "NT4",
        "NT5",
        "NT6",
        "NT7",
        "NT8",
        "NT9",
        "NT10",
        "NT11",
		"NT12",

      ])
    );

    dispatch(
      addResourceId([
        "FR4",
        "FR8",
        "FR9",
        "FR21A",
        "FR18A",
        "FR19A",
        "FR21",
        "FR22",
        "DR1",
        "DR15",
        "FR10A",
        "NG7",
        "NG8",
        "NG9",
        "NG10",
        "NG11",
        "NG12",
        "NG13",
        "FR24",
        "FR1",
        "FR2",
        "FR5",
		"NG33"
      ])
    );
  }, [dispatch]);

  const updateInterview = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = ev.target;

      if (value == "yes") {
        dispatch(addResourceId(["FR6"]));
      } else {
        dispatch(removeResourceId(["FR6"]));
      }
      formik.handleChange(ev);
      setData(name, value);
    },
    [dispatch, generalOnboarding]
  );

  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationForm13,
    onSubmit: () => {
      dispatch(saveGOnboarding(generalOnboarding))
        .unwrap()
        .then(() => {
          dispatch(add_system_tickets(sortArray([...system_tickets_id])))
            .unwrap()
            .then(() => {
              dispatch(getUserData());
              dispatch(resetTicketsIds());
            });
          dispatch(add_system_resources(sortArray([...system_resources_id])))
            .unwrap()
            .then(() => {
              dispatch(resetResourcesIds());
            });

          if (generalOnboarding.has_children == "no") {
            if (generalOnboarding.home_property == "rented") {
              dispatch(add_case_studies(sortArray(["F-CS-1", "F-CS-2"])));
            } else if (generalOnboarding.home_property == "owned") {
              dispatch(add_case_studies(sortArray(["F-CS-3", "F-CS-4"])));
            }
          }
        });

      handleNextStep();
    },
  });

  return (
    <form id="form-13" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>

      <>
        <Htag tag="h3">
          Do you and your ex have a pre-nuptial or post-nuptial agreement?
        </Htag>
        <div className={s.form_oneline}>
          <div className={s.formField}>
            <RadioGroup row name="nuptial_agreement" className={s.radioboxes}>
              <FormControlLabel
                value="yes"
                control={<Radio color="default" onChange={updateInterview} />}
                label="Yes"
                checked={formik.values.nuptial_agreement == "yes"}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" onChange={updateInterview} />}
                label="No"
                checked={formik.values.nuptial_agreement == "no"}
              />
            </RadioGroup>
            {formik.touched.nuptial_agreement &&
              formik.errors.nuptial_agreement && (
                <ErrorField error={formik.errors.nuptial_agreement} />
              )}
          </div>
        </div>
      </>

      <Accordion className={s.desc} accordionData={answers} />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button
          type="submit"
          disabled={loading || loadingTickets || loadingResources}
          apearance="secondary"
          className={cn({
            [s.disabled]: loading || loadingTickets || loadingResources,
          })}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};
