export const curve: "smooth" | "straight" | "stepline" | "monotoneCubic" = "smooth";

export const curveXaxisType: "category" | "datetime" | "numeric" = "datetime";

export enum PERIOD {
  ALL = 0,
  DAYS_7 = 7,
//   DAYS_14 = 14,
  MONTH_1 = 30,
  MONTHS_3= 90,
//   YEAR = 365,
}
