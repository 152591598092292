import { useEffect, useState } from "react";
import s from './Blog.module.css';
import { get_recent_posts } from 'store/blog/blog-thunk';
import { Post } from "store/blog/blog.interfaces";
import { RecentPostsProps } from './RecentPosts.props';
import { useAppDispatch } from 'utils/hooks';
import { NavLink } from 'react-router-dom';
import { Htag } from 'components';
import { Chip } from '@mui/material';

export const RecentPostsComponent = ({ id }: RecentPostsProps) => {
  const [recentPosts, setRecentPosts] = useState<Post[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(get_recent_posts(id))
      .unwrap()
      .then((rposts) => {
        setRecentPosts(rposts);
      });
  }, []);

  return (
    <>
      {recentPosts.map((post) => (
        <NavLink key={post.id} to={`/blog/${post.slug}`} className={s.postItem}>
          <div
            className={s.postImg}
            style={{ backgroundImage: `url("${post.image}"` }}
          ></div>
          <div className={s.postData}>
            <Htag className={s.post_title} tag="h4">
              {post.title}
            </Htag>

            {post.categories && (
              <div className={s.categories}>
                {post.categories.map((tag: string, index) => (
                  <Chip key={Math.random()} label={`${tag}`} />
                ))}
              </div>
            )}
          </div>
        </NavLink>
      ))}
    </>
  );
};
