import { CheckIcon, DeleteIcon, PencilIcon } from "components";
import cn from "classnames";
import s from "./OptionsWidget.module.css";

interface Item {
  option: string;

  handleRemove: () => void;
  handleUpdate: () => void;
}

export const OLitItem = ({
  option,

  handleRemove,
  handleUpdate,
}: Item) => {
  return (
    <p>
      <CheckIcon /> {option}
      <span className={cn(s.icons)}>
        <PencilIcon onClick={handleUpdate} />
      </span>
      <span className={cn(s.deleteIcon, s.icons)}>
        <DeleteIcon onClick={handleRemove} />
      </span>
    </p>
  );
};
