import { Stepper } from "../../components";

import { FirstScreen } from "./FirstScreen.component";

import cn from "classnames";
import s from "./GeneralOnboarding.module.css";
import { FormGB1 } from "./FormGB1.component";
import { FormGB2 } from "./FormGB2.component";
import { FormGB3 } from "./FormGB3.component";
import { FormGB4 } from "./FormGB4.component";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { FormGB5 } from "./FormGB5.component";
import { FormGB6 } from "./FormGB6.component";
import { useCallback } from "react";
import {
  setBoardingStep,
  setOnboarding,
  setPrevBoardingStep,
} from "store/generalOnboarding/generalOnboarding-reducer";
import { FormGB7 } from "./FormGB7.component";
import { FormGB8 } from "./FormGB8.component";
import { FormGB9 } from "./FormGB9.component";
import { FormGB10 } from "./FormGB10.component";
import { FormGB11 } from "./FormGB11.component";
import { FormGB12 } from "./FormGB12.component";
import { FormGB13 } from "./FormGB13.component";
import { IDebs } from "store/generalOnboarding/generalOnboarding-interface";
import { LastScreen } from './LastScreen.component';

export const GeneralOnboarding = ({ close }: { close: () => void }) => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );
  const dispatch = useAppDispatch();

  const handleBack = useCallback(
    (value = activeStep) => {
      dispatch(setPrevBoardingStep(value));
    },
    [activeStep, dispatch]
  );

  const handleNextStep = (value = activeStep) => {
    dispatch(setBoardingStep(value));
  };

  const setQuestionnaireData = (name: string, value: string | IDebs[]) => {
    dispatch(
      setOnboarding({
        ...generalOnboarding,
        [`${name}`]: value,
      })
    );
  };

  const stepContext = [
    <FirstScreen
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,

    <FormGB1
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormGB2
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormGB3
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormGB4
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormGB5
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormGB6
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormGB7
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormGB8
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormGB9
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormGB10
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormGB11
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormGB12
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormGB13
      setData={setQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <LastScreen
      close={close}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
      setData={setQuestionnaireData}
    />,
  ];
  return (
    <>
      <div className={cn(s.onboard_item)}>{stepContext[activeStep]}</div>

      {activeStep >= 0 && (
        <Stepper
          className={s.stepper}
          steps={stepContext.length}
          activeStep={activeStep + 1}
        />
      )}
    </>
  );
};
