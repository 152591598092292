import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_EXTRA_RESOURCES,
  DELETE_EXTRA_RESOURCES,
  EDIT_EXTRA_RESOURCES,
  GET_ALL_EXTRA_RESOURCES,
  GET_EXTRA_RESOURCE,
} from "../config";
import { IInitState } from "../root-interface";
import { IExtraResource } from "./extra_resource.interface";
import { API_TYPE, getRequestFabric } from "../thunk-helper";

export const get_all_extra_resources = createAsyncThunk<
  IExtraResource[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@extraresources/getting",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_EXTRA_RESOURCES(),
      rejectWithValue,
      dispatch
    );
  }
);

export const get_single_eresource = createAsyncThunk<
  IExtraResource,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@extraresources/gettingSingleResource",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_EXTRA_RESOURCE(Number(id)),
      rejectWithValue,
      dispatch
    );
  }
);

export const updateExtraResource = createAsyncThunk<
  { success: string; resource: IExtraResource },
  {
    er_id: string;
    er_data: FormData;
  },
  { getState: IInitState; rejectValue: string }
>(
  "@@extraresources/updateResource",
  async function ({ er_id, er_data }, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      EDIT_EXTRA_RESOURCES(er_id),
      rejectWithValue,
      dispatch,
      er_data
    );
  }
);

export const deleteEResource = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@extraresources/deleteResource",
  async function (resource_id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_EXTRA_RESOURCES(resource_id),
      rejectWithValue,
      dispatch
    );
  }
);

export const addExtraResource = createAsyncThunk<
  { success: string; resource: IExtraResource },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@extraresources/addResource",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      ADD_EXTRA_RESOURCES(),
      rejectWithValue,
      dispatch,
      data
    );
  }
);
