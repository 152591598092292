import { useFormik } from "formik";
import s from "../../pages/AdminDashboard/SpecialSources.module.css";
import * as yup from "yup";
import { Button, ErrorField, Htag } from "components";
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  Snackbar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "utils/hooks";
import axios from "axios";
import { getSB, setSB } from "store/widgets/smartbanner-thunk";
const validationSignupSchema = yup.object({
  text: yup.string().required("Title is required"),
  url: yup.string().required("Title is required"),
});

export const BannerWidget = () => {
  const [server_error, setServer_error] = useState("");
  const [server_success, setServer_success] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSB())
      .unwrap()
      .then((resp) => {
        for (const [key, value] of Object.entries(resp)) {
          formik.setFieldValue(key, value);
        }
      })
      .catch((err) => {
        setServer_error("Server error, please try later and check you data");
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      text: "",
      url: "",
      visible: false,
    },
    validationSchema: validationSignupSchema,
    onSubmit: (values) => {
      dispatch(setSB(values))
        .unwrap()
        .then((resp) => {
          setServer_success("Banner is saved successfully");
          setOpenSnackbar(true);
        })
        .catch((err) => {
          setServer_error("Server error, please try later and check you data");
        });
    },
  });

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <>
      <Htag tag="h3">Smart banner</Htag>
      <form id="smartBar" onSubmit={formik.handleSubmit}>
        <div className={s.formField}>
          <Htag tag="h4">Content</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="text"
              name="text"
              onBlur={formik.handleBlur}
              value={formik.values.text}
              type="text"
              onChange={formik.handleChange}
              placeholder="Content"
            />
            {formik.touched.text && formik.errors.text && (
              <ErrorField error={formik.errors.text} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4">Link</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="url"
              name="url"
              onBlur={formik.handleBlur}
              value={formik.values.url}
              type="text"
              onChange={formik.handleChange}
              placeholder="Link"
            />
            {formik.touched.url && formik.errors.url && (
              <ErrorField error={formik.errors.url} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <FormControlLabel
            checked={formik.values.visible}
            control={
              <Checkbox
                color="default"
                onBlur={formik.handleBlur}
                name="visible"
                onChange={formik.handleChange}
              />
            }
            label={`Switch ${!formik.values.visible ? `on` : `off`}`}
          />
        </div>
        <ErrorField error={server_error?.toString()} />
        <div className={s.buttonGroup}>
          <Button apearance="secondary" type="submit">
            Save
          </Button>
        </div>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {server_success}
        </Alert>
      </Snackbar>
    </>
  );
};
