import axios from "axios";

import { ADD_BOOKMARK, REMOVE_BOOKMARK } from "../config";


export const set_api = (
  type: string,
  title: string,
  resource_id: number,
  userToken: string
) => {

  return axios
    .post(
      ADD_BOOKMARK(),
      JSON.stringify({
        type,
        title,
        resource_id,
      }),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken,
        },
        responseType: "json",
      }
    )
    .catch((response) => {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
    });
};

export const remove_api = (
  type: string,
  title: string,
  resource_id: number,
  userToken: string
) => {
  return axios.delete(REMOVE_BOOKMARK(), {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken,
    },
    responseType: "json",
    data: JSON.stringify({ resource_id, type }),
  });
};
