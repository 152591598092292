import styles from "./OnBoarding.module.css";
import cn from "classnames";

import { IsRightForYouForm } from "./forms/IsRight.component";
import { AgreeForm } from "./forms/AgreeForm.component";
import { RegData } from "./forms/RegData.component";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Stepper } from "components";

import { openLoginModal } from "store/login/login-reducer";
import { setRegModalStatus } from "store/onBoarding/onboarding-reducer";
import { ConfirmEmailStep } from "./forms/ConfirmEmail.component";
import { ChoosePlanComponent } from "./forms/ChoosePlan.component";

export const OnBoardingComponent = (): JSX.Element => {
  const activeStep = useAppSelector((state) => state.onRegistration.activeStep);
  const { token } = useAppSelector((state) => state.userState);
  const stepContext = [
    <IsRightForYouForm />,
    <RegData />,
    <AgreeForm />,
    <ConfirmEmailStep />,
    <ChoosePlanComponent />,
  ];
  const dispatch = useAppDispatch();
  const goToLogin = () => {
    dispatch(setRegModalStatus(false));
    dispatch(openLoginModal(true));
  };

  return (
    <>
      <div className={cn(styles.onboard_item)}>{stepContext[activeStep]}</div>
      {!token && (
        <p className={styles.create_new_account}>
          Already have an account?{" "}
          <span
            className={cn(
              styles.create_new_account_link,
              "hover-underline-animation_back"
            )}
            onClick={() => goToLogin()}
          >
            Log in
          </span>
        </p>
      )}
      {activeStep >= 0 && (
        <Stepper steps={stepContext.length} activeStep={activeStep + 1} />
      )}
    </>
  );
};
