import { useEffect, useState } from "react";
import { useAppDispatch } from "./hooks";
import {
  getLastActivitiesData,
} from "store/analytics/analytics-thunk";

export const useAnaliticDataPerPeriod = (initialPeriod: number, callback: any) => {
  const dispatch = useAppDispatch();
  const [period, setPeriod] = useState<number>(initialPeriod);

    useEffect(() => {
      dispatch(callback(period)).unwrap().catch(console.log);
    }, [dispatch, period]);

	const updateData = (timeline: number) => {
     setPeriod(timeline);
     dispatch(callback(timeline)).unwrap().catch(console.log);
   };

  return { period, updateData };
};



export const useLastActivitiesData = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getLastActivitiesData()).unwrap().catch(console.log);
  }, [dispatch]);
};
