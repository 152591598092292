import s from "./GeneralOnboarding.module.css";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { useCallback } from "react";
import { QuestionnaireProps } from "pages/Dashboard/Spaces/Questionnaire.props";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { addId, removeId } from "store/system_tickets/system_ticket-reducer";
import { Accordion, Button, ErrorField, Htag } from "components";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  addResourceId,
  removeResourceId,
} from "store/system_resources/system_resource-reducer";

const validationForm7 = yup.object({
  home_property: yup.string().nullable().required("Please select an option."),
});

const desc = `<p>Your answer to this question is important for two reasons.</p>
    <p>
      A family home is usually one of the most important elements of a couple’s financial arrangements.  If it’s owned, it’s often the most valuable asset.  

    </p>
    <p>
      If you or your ex own your home either individually or together, the things you need to think about (such as its value) are different to the things you need to think about if you rent your home (such as who’s name is on the tenancy agreement). 
    </p>`;

export const FormGB7 = ({
  handleBack,
  setData,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );
  const dispatch = useAppDispatch();
  const living_property = useAppSelector(
    (state) => state.generalOnBoardingState.generalOnboarding.living_property
  );

  const updateInterview = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = ev.target;
      if (value === "rented") {
        dispatch(addId("F5"));
        dispatch(addResourceId("NG5"));
        dispatch(removeId(["F4", "F14"]));
        dispatch(removeResourceId(["NG4", "NG14", "FR23"]));
      } else if (value === "owned") {
        dispatch(addId(["F4", "F14"]));
        dispatch(addResourceId(["NG4", "NG14", "FR23"]));
        dispatch(removeId("F5"));
        dispatch(removeResourceId("NG5"));
      }

      formik.handleChange(ev);
      setData(name, value);
    },
    [dispatch, setData]
  );

  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationForm7,
    onSubmit: () => {
      handleNextStep();
    },
  });

  return (
    <form id="form-7" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>

      <>
        <Htag tag="h3">
          Tell us about the property you{" "}
          {living_property == "different" && `last`} lived in together (we’ll
          call this property your family home)?
        </Htag>
        <div className={s.form_oneline}>
          <div className={s.formField}>
            <RadioGroup row name="home_property" className={s.radioboxes}>
              <FormControlLabel
                control={<Radio color="default" onChange={updateInterview} />}
                checked={formik.values.home_property == "rented"}
                label="It’s rented."
                value="rented"
              />
              <FormControlLabel
                value="owned"
                control={<Radio color="default" onChange={updateInterview} />}
                label="It’s owned (with or without a mortgage)."
                checked={formik.values.home_property == "owned"}
              />
            </RadioGroup>
            {formik.touched.home_property && formik.errors.home_property && (
              <ErrorField error={formik.errors.home_property} />
            )}
          </div>
        </div>
      </>

      <Accordion
        className={s.desc}
        accordionData={[
          {
            title: `Why are we asking?`,
            content: desc,
          },
        ]}
      />

      <div className={s.groupButton}>
        <Button
          onClick={() => {
            if (formik.values.started_process !== "no") {
              handleBack(activeStep - 1);
            } else {
              handleBack();
            }
          }}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
