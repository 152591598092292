import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import {
  CREATE_PAGE_TEMPLATE,
  DELETE_PAGE_TEMPLATE,
  GET_ALL_PAGES,
  GET_PAGE,
  UPDATE_PAGE_TEMPLATE,
} from "../config";
import { Page } from "./simplePages-inderfaces";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const loadSimplePages = createAsyncThunk<
  Page[],
  string | null | undefined,
  { getState: IInitState; rejectValue: string }
>("@@pages/loadPages", async function (_, { rejectWithValue }) {
  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
    },
  };

  const response = await fetch(GET_ALL_PAGES(), header);

  if (!response.ok) {
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()).data as Page[];
  }
});

export const loadSinglePage = createAsyncThunk<
  Page,
  number,
  { getState: IInitState; rejectValue: string }
>("@@pages/loadSinglePage", async function (page_id, { rejectWithValue }) {
  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
    },
  };

  const response = await fetch(GET_PAGE(page_id), header);

  if (!response.ok) {
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as Page;
  }
});

export const createNewPage = createAsyncThunk<
  { success: string; page: Page },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@pages/createPage",
  async function (page_data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      CREATE_PAGE_TEMPLATE(),
      rejectWithValue,
      dispatch,
      page_data
    );
  }
);

export const updatePage = createAsyncThunk<
  { success: string; page: Page },
  { page_id: number; page_data: FormData },
  { getState: IInitState; rejectValue: string }
>(
  "@@pages/updatePage",
  async function (
    { page_id, page_data },
    { getState, rejectWithValue, dispatch }
  ) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      UPDATE_PAGE_TEMPLATE(page_id),
      rejectWithValue,
      dispatch,
      page_data
    );
  }
);

export const deleteSimplePage = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@pages/deletePage",
  async function (page_id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_PAGE_TEMPLATE(page_id),
      rejectWithValue,
      dispatch
    );
  }
);
