import { Checkbox, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Button } from "components";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import cn from "classnames";
import s from "../User.module.css";
import { useState } from "react";
import {
  deleteProfile,
  updateEmailSubscription,
} from "store/userInfo/user-thunk";
import { resetToDefault } from "store/login/login-reducer";
import {  useNavigate } from "react-router-dom";
import {
  pauseSubscription,
  resumeSubscription,
} from "store/stripe/stripe-thunk";
import { setSubscriptionData } from "store/userInfo/account-reducer";

export const SubscriptionSettings = () => {
  const { subscription, products } = useAppSelector(
    (state) => state.accountState
  );
  const { user } = useAppSelector((state) => state.userState);
  const [openDeleteAttention, setOpenDeleteAttention] = useState(false);
  const [openCancelAttention, setOpenCancelAttention] = useState(false);
  const [openResumeAttention, setOpenResumeAttention] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpenDeleteAttention(false);
  };

  const handleCancelClose = () => {
    setOpenCancelAttention(false);
  };

  const handleResumeClose = () => {
    setOpenResumeAttention(false);
  };

  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = !e.target.checked;
    dispatch(updateEmailSubscription({ subscription: value }));
  };

  const cancelSubscr = () => {
    dispatch(pauseSubscription()).then(() => {
      dispatch(
        setSubscriptionData({
          status: subscription.status,
          expired_date: subscription.expired_date,
          sub_status: "paused",
        })
      );
      setOpenCancelAttention(false);
    });
  };

  const resumeSubscr = () => {
    dispatch(resumeSubscription()).then(() => {
      //   setOpenResumeAttention(false);
      setTimeout(() => {
        setOpenResumeAttention(false);
      }, 2000);
      dispatch(
        setSubscriptionData({
          status: subscription.status,
          expired_date: subscription.expired_date,
          sub_status: "complete",
        })
      );
    });
  };

  const confirmDelete = () => {
    dispatch(deleteProfile())
      .unwrap()
      .then(() => {
        setOpenDeleteAttention(false);
        dispatch(resetToDefault());
        navigate("/");
      });
  };

  return (
    <div className={s.form}>
      <div className={s.accauntWrap}>
        <div className={s.formControl}>
          <label className={s.label}>Current plan: </label>
          <p> {subscription.status ? products[0].label : "Not active"}</p>
        </div>
        {subscription.status == "active" && (
          <div className={s.formControl}>
            <label className={s.label}>Next billing date: </label>
            <p>{subscription.expired_date}</p>
          </div>
        )}
   
        {subscription.status == "active" && (
          <div className={s.formControl}>
            <label className={s.label}>Subscription settings:</label>

            <p>
              When you cancel your membership, you’ll still be able to use the
              SeparateSpace platform for the time that you’ve already paid.{" "}
            </p>
            <p>
              For example, if you buy a 6 month membership on 1 January and
              decide to cancel your membership on 1 May:
              <br />
              - You’ll have access to the SeparateSpace platform until 30 June.
              <br />- You won’t be charged another 6 month membership on 1 July.
            </p>
            <br />
            <p>
              If you decide you’d like to resume your membership, you can do
              that any time before your final day of membership using the button
              below
            </p>
            <br />
            {subscription.sub_status == "complete" && (
              <Button
                apearance="secondary"
                onClick={(e) => {
                  e.stopPropagation();

                  setOpenCancelAttention(true);
                }}
                className={s.deleteProfileBtn}
              >
                Cancel
              </Button>
            )}
            {subscription.sub_status == "paused" && (
              <Button
                apearance="secondary"
                onClick={(e) => {
                  e.stopPropagation();

                  setOpenResumeAttention(true);
                }}
                className={s.deleteProfileBtn}
              >
                Resume membership
              </Button>
            )}
          </div>
        )}
   
        {subscription.status == "active" && (
          <div className={cn(s.formControl, s.refundBlock)}>
            <p>
              We're confident that our Membership gives you a calm and simple
              way to navigate your divorce and make informed decisions about
              moving forward. If you don't find the tools and support you need,
              reach out to Amanda, one of our co-founders, at{" "}
              <a
                href="mailto:amanda@separatespace.co.uk"
                className="hover-underline-animation_back"
              >
                amanda@separatespace.co.uk.
              </a>{" "}
              We’re learning all of the time and would love your feedback. If
              you email us within your first 7 days of Membership, we'll offer
              you a full refund.
            </p>
            {/* <Button apearance="secondary" onClick={getRefund}>
              Refund
            </Button> */}
          </div>
        )}
        <div className={cn(s.formControl, s.optOut)}>
          <p>
            From time to time, we may wish to contact you with marketing
            information and updates about our services by email. If you do not
            want to hear from us in this way, you can opt-out below.
          </p>
          <hr />
          <p>
            I do not wish to hear from you by email in relation to marketing.{" "}
            <Checkbox
              color="default"
              checked={!user.subscription}
              onChange={handleChangeStatus}
            />
          </p>
        </div>
        {/* <div className={s.formControl}>
          <label className={s.label}>Delete account: </label>
          <Button
            apearance="secondary"
            onClick={(e) => {
              e.stopPropagation();
              setOpenDeleteAttention(true);
            }}
            className={s.deleteProfileBtn}
          >
            Delete
          </Button>
        </div> */}
      </div>
      <Dialog
        open={openDeleteAttention}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={s.deleteConfirmDialog}
      >
        <DialogContent className={s.deleteConfirmText}>
          Please, confirm deleting - we will delete all your data!
        </DialogContent>
        <DialogActions className={s.deleteConfirmBtn}>
          <Button apearance="secondaryDark" onClick={handleClose}>
            NO
          </Button>
          <Button apearance="secondaryDark" onClick={confirmDelete}>
            AGREE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCancelAttention}
        onClose={handleCancelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={s.deleteConfirmDialog}
      >
        <DialogContent className={s.deleteConfirmText}>
          Please, confirm you'd like to cancel your membership!
        </DialogContent>
        <DialogActions className={s.deleteConfirmBtn}>
          <Button apearance="secondaryDark" onClick={handleCancelClose}>
            NO
          </Button>
          <Button apearance="secondaryDark" onClick={cancelSubscr}>
            AGREE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openResumeAttention}
        onClose={handleResumeClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={s.deleteConfirmDialog}
      >
        <DialogContent className={s.deleteConfirmText}>
          {subscription.sub_status == "paused"
            ? `Please confirm you'd like to resume your membership. Your membership will renew automatically at the end of the time you've paid for.`
            : `You have resumed your membership`}
        </DialogContent>
        {subscription.sub_status == "paused" && (
          <DialogActions className={s.deleteConfirmBtn}>
            <Button apearance="secondaryDark" onClick={handleResumeClose}>
              NO
            </Button>
            <Button apearance="secondaryDark" onClick={resumeSubscr}>
              AGREE
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
