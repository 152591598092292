import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  Snackbar,
} from "@mui/material";
import { useEffect, useState } from "react";

import s from "./AddSources.module.css";

import {
  Button,
  ErrorField,
  Htag,
  InputImageComponent,
} from "../../../../components";
import {
  ResourceIcon,
  SettingsIcon,
} from "../../../../components/Icons/Icons.component";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import {
  Challenge,
  Feelling,
} from "../../../../store/challenges/challenges.interface";
import {
  addResource,
  addTool,
  loadChallenges,
  loadFeellings,
} from "../../../../store/challenges/challenges-thunk";

import { validationCreateSchema } from "../../validation";
import { useFormik } from "formik";
import { EditorComponent } from "../../../../components/Editor/Editor.component";
import {
  FileProps,
  IAttach,
} from "../../../../store/system_resources/system_resource.interface";
import { InputFileComponent } from "../../../../components/InputFile/InputFile.component";
import { Multyselect } from "../../../../components/MultySelect/Multyselect.component";
import { CWellbeing } from "models/Wellbeing.model";

export const AddSources = () => {
  const dispatch = useAppDispatch();
  const challenges: Challenge[] = useAppSelector(
    (state) => state.wellbeing.challenges.challenges
  );
  const feelings: Feelling[] = useAppSelector(
    (state) => state.wellbeing.feellings.feelings
  );
  const [currentResourceAttachments, setResourceAttachments] = useState<
    Partial<FileProps | IAttach>[]
  >([]);
  const [currentToolAttachments, setToolAttachments] = useState<
    Partial<FileProps | IAttach>[]
  >([]);
  const [open, setOpen] = useState(false);
  // Add Resource

  const formik_resource = useFormik({
    initialValues: new CWellbeing(),
    validationSchema: validationCreateSchema,
    onSubmit: (values) => {
      const updatedValue = formik_tool.initialValues.formData(values);
      dispatch(addResource(updatedValue))
        .unwrap()
        .then(() => {
          formik_resource.resetForm();
          setResourceAttachments([]);
          setOpen(true);
        });
    },
  });

  const formik_tool = useFormik({
    initialValues: new CWellbeing(),
    validationSchema: validationCreateSchema,
    onSubmit: (values) => {
      const updatedValue = formik_tool.initialValues.formData(values);
      dispatch(addTool(updatedValue))
        .unwrap()
        .then(() => {
          setToolAttachments([]);
          formik_tool.resetForm();
          setOpen(true);
        });
    },
  });

  useEffect(() => {
    dispatch(loadChallenges());
    dispatch(loadFeellings());
  }, [dispatch]);

  const handleAtachmentsResource = (attachments: FileProps | IAttach) => {
    setResourceAttachments((old) => [...old, attachments]);
    formik_resource.setFieldValue("attachments", [
      ...currentResourceAttachments,
      attachments,
    ]);
  };

  const handleAtachmentsTool = (attachments: FileProps | IAttach) => {
    setToolAttachments((old) => [...old, attachments]);
    formik_tool.setFieldValue("attachments", [
      ...currentToolAttachments,
      attachments,
    ]);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleResourceChalenges = (value: any) => {
    formik_resource.setFieldValue("challenges", value);
  };
  const handleResourceFeelings = (value: any) => {
    formik_resource.setFieldValue("feelings", value);
  };

  const handleToolChalenges = (value: any) => {
    formik_tool.setFieldValue("challenges", value);
  };
  const handleToolFeelings = (value: any) => {
    formik_tool.setFieldValue("feelings", value);
  };
  return (
    <>
      <div className={s.formWrap}>
        <Htag tag="h3">
          <ResourceIcon /> Add resource
        </Htag>
        <form onSubmit={formik_resource.handleSubmit}>
          <FormControl fullWidth className={s.title}>
            <input
              id="titleResource"
              name="title"
              onBlur={formik_resource.handleBlur}
              value={formik_resource.values.title}
              type="text"
              onChange={formik_resource.handleChange}
              placeholder="Title"
            />
            {formik_resource.touched.title && formik_resource.errors.title && (
              <ErrorField error={formik_resource.errors.title} />
            )}
          </FormControl>
          <FormControl className={s.reading_time}>
            <input
              id="reading_timeResource"
              name="reading_time"
              onBlur={formik_resource.handleBlur}
              value={formik_resource.values.reading_time}
              type="text"
              onChange={formik_resource.handleChange}
              placeholder="Reading time"
            />
            {formik_resource.touched.reading_time &&
              formik_resource.errors.reading_time && (
                <ErrorField error={formik_resource.errors.reading_time} />
              )}
          </FormControl>
          <FormControlLabel
            label="Need a journal"
            className={s.need_journal}
            id="need_jornal"
            control={
              <Checkbox
                name="need_journal"
                id="need_journal"
                checked={formik_resource.values.need_journal}
                onChange={formik_resource.handleChange}
              />
            }
          />
          <div className={s.formField}>
            <Htag tag="h4" className={s.headline}>
              Upload preview image
            </Htag>
            <InputImageComponent
              handleFiles={formik_resource.setFieldValue}
              handleOnBlur={formik_resource.setFieldValue}
              file={formik_resource.values.image}
              id="imageResource"
              name="image"
            />
          </div>

          <div className={s.formField}>
            <Htag tag="h4" className={s.headline}>
              Upload attachments
            </Htag>
            <InputFileComponent
              handleFiles={handleAtachmentsResource}
              id="resourceFile"
            />
          </div>
          <div className={s.wyswyg_editorWrap}>
            <EditorComponent
              value={formik_resource.values.content}
              handleChange={(e) => formik_resource.setFieldValue("content", e)}
            />

            {formik_resource.touched.content &&
              formik_resource.errors.content && (
                <ErrorField error={formik_resource.errors.content} />
              )}
          </div>
          <div className={s.selects}>
            <Multyselect
              label="Challenge"
              selectData={formik_resource.values.challenges}
              selectArray={challenges}
              handleData={handleResourceChalenges}
            />

            <Multyselect
              label="Feeling"
              selectData={formik_resource.values.feelings}
              selectArray={feelings}
              handleData={handleResourceFeelings}
            />
          </div>
          <Button apearance="secondary" type="submit">
            Save
          </Button>
        </form>
      </div>

      <div className={s.formWrap}>
        <Htag tag="h3">
          <SettingsIcon /> Add tool
        </Htag>
        <form onSubmit={formik_tool.handleSubmit}>
          <FormControl fullWidth className={s.title}>
            <input
              id="titleTool"
              name="title"
              type="text"
              onChange={formik_tool.handleChange}
              onBlur={formik_tool.handleBlur}
              value={formik_tool.values.title}
              placeholder="Title"
            />
            {formik_tool.touched.title && formik_tool.errors.title && (
              <ErrorField error={formik_tool.errors.title} />
            )}
          </FormControl>
          <FormControl className={s.reading_time}>
            <input
              id="reading_timeTool"
              name="reading_time"
              onBlur={formik_tool.handleBlur}
              value={formik_tool.values.reading_time}
              type="text"
              onChange={formik_tool.handleChange}
              placeholder="Reading time"
            />
            {formik_tool.touched.reading_time &&
              formik_tool.errors.reading_time && (
                <ErrorField error={formik_tool.errors.reading_time} />
              )}
          </FormControl>
          <FormControlLabel
            label="Need a journal"
            className={s.need_journal}
            id="need_jornal"
            control={
              <Checkbox
                name="need_journal"
                id="need_journal"
                checked={formik_tool.values.need_journal}
                onChange={formik_tool.handleChange}
              />
            }
          />
          <div className={s.formField}>
            <Htag tag="h4" className={s.headline}>
              Upload preview image
            </Htag>
            <InputImageComponent
              handleFiles={formik_tool.setFieldValue}
              handleOnBlur={formik_tool.setFieldValue}
              file={formik_tool.values.image}
              id="imageTools"
              name="image"
            />
          </div>

          <div className={s.formField}>
            <Htag tag="h4" className={s.headline}>
              Upload attachments
            </Htag>
            <InputFileComponent
              id="toolFile"
              handleFiles={handleAtachmentsTool}
            />
          </div>
          <div className={s.wyswyg_editorWrap}>
            <EditorComponent
              value={formik_tool.values.content}
              handleChange={(e) => formik_tool.setFieldValue("content", e)}
            />

            {formik_tool.touched.content && formik_tool.errors.content && (
              <ErrorField error={formik_tool.errors.content} />
            )}
          </div>
          <div className={s.selects}>
            <Multyselect
              label="Challenge"
              selectData={formik_tool.values.challenges}
              selectArray={challenges}
              handleData={handleToolChalenges}
            />

            <Multyselect
              label="Feeling"
              selectData={formik_tool.values.feelings}
              selectArray={feelings}
              handleData={handleToolFeelings}
            />
          </div>

          <Button apearance="secondary" type="submit">
            Save
          </Button>
        </form>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Source is added successfully!
        </Alert>
      </Snackbar>
    </>
  );
};
