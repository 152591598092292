import cn from "classnames";
import { NotificationProps } from "./Notification.props";
import s from "./Notification.module.css";
import { CloseIcon } from "../Icons/Icons.component";
import { photo } from "./Notification.images";
import { useState } from "react";
import { createMemory } from "store/memory/memory-thunk";
import { useAppDispatch } from "utils/hooks";
import { addMemory } from "store/userInfo/userInfo-reducer";

export const Notification = ({
  children,
  name,
  className,
  ...props
}: NotificationProps): JSX.Element => {
  const [showBlock, setShowBlock] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const handleCloseNot = () => {
    setShowBlock(false);
    dispatch(
      createMemory({
        name: `Notification_${name}`,
        value: "true",
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          addMemory({
            name: `Notification_${name}`,
            value: "true",
          })
        );
      });
  };
  return (
    <>
      {showBlock && (
        <div className={cn(s.notification_wrap, className)} {...props}>
          <CloseIcon
            onClick={() => {
              handleCloseNot();
            }}
            className={s.close}
          />
          <span className={cn(s.photo, "mobile_hide")}>
            <img src={photo} alt="photo" />
          </span>{" "}
          <p>{children}</p>
        </div>
      )}
    </>
  );
};
