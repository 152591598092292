import { Blog } from "../pages/Blog/Blog.component";
import { PostTemplate } from "../pages/Blog/PostTemplate.component";
import { Contact } from "../pages/Contact/Contact.component";
import FAQ from "../pages/FAQ/FQA.component";
import Features from "../pages/Features/Features.component";
import Home from "../pages/Home/Home.component";
import HowItWorks from "../pages/HowItWorks/HowItWorks.component";
import Pricing from "../pages/Pricing/Pricing.component";
import Team from "../pages/Team/Team.component";
import { RootRouteType } from "./root-config";

export const rootRoutes: RootRouteType[] = [
  {
    path: "/",
    element: <Home />,
    headerData: {
      isHome: true,
      title: `The calm, affordable way to navigate your divorce`,
      img: "assets/images/Laptop_Mob_4 1.svg",
      variant: "",
    },
  },
  {
    path: "/onboarding",
    element: <Home />,
    headerData: {
      isHome: true,
      title: `The calm, simple and affordable way to navigate your divorce.`,
      img: "assets/images/hero.png",
      variant: "",
    },
  },

  {
    path: "/how-it-works",
    element: <HowItWorks />,
    headerData: {
      title: "How it works",
      text: "SeparateSpace is a completely online service, available 24/7.",
      img: "assets/images/hiw_hero.svg",
      variant: "frontOffice",
    },
  },
  {
    path: "/features",
    element: <Features />,
    headerData: {
      title: "Features",
      text: "Support through your separation and divorce, beginning to end, step-by-step.",
      img: "assets/images/pricing_hero.svg",
      variant: "frontOffice",
    },
  },

  {
    path: "/team",
    element: <Team />,
    headerData: {
      title: "Our team",
      text: "Our team of experts have created a calm, simple, affordable way to navigate divorce.",
      img: "assets/images/team_hero.svg",
      variant: "frontOffice",
    },
  },
  {
    path: "/pricing",
    element: <Pricing />,
    headerData: {
      title: "Pricing",
      text: "You are one step closer to creating your space.",
      img: "assets/images/pricing_hero.svg",
      variant: "frontOffice",
    },
  },
  {
    path: "/contact",
    element: <Contact />,
    headerData: {
      title: "",
      text: "Contact Us",
      img: "assets/images/contact.svg",
      variant: "middleOffice",
    },
  },
  {
    path: "/faq",
    element: <FAQ />,
    headerData: {
      title: "",
      text: "FAQ",
      img: "assets/images/faq.svg",
      variant: "middleOffice",
    },
  },
  {
    path: "/blog/:slug",
    element: <PostTemplate />,
    headerData: {
      title: "",
      text: "",
      img: "",
      variant: "blog",
    },
  },
  {
    path: "/blog/",
    element: <Blog />,
    headerData: {
      title: "Blog",
      text: "Read about our latest news and updates",
      img: "assets/images/blog.svg",
      variant: "frontOffice",
    },
  },
];
