import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import { ICoachingState } from "./coach.interface";

const initialState: ICoachingState = {
  showCalendy: false,
};

const onCoachingState = createSlice({
  name: "@@coaching",
  initialState,
  reducers: {
    setShowCalendy: (state, action: PayloadAction<boolean>) => {
      state.showCalendy = action.payload;
    },

    setCoachingInit: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetToDefault, () => {
      return initialState;
    });
  },
});

export const {
  setShowCalendy,
  setCoachingInit,
} = onCoachingState.actions;

export default onCoachingState.reducer;
