import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type IRouteState = {
  routeState: string;
};

const initialState: IRouteState = {
  routeState: "",
};

export const routeState = createSlice({
  name: "routeState",
  initialState,
  reducers: {
    setRouteState: (state, action: PayloadAction<string>) => {
	
      state.routeState = action.payload;
    },
  },
  
});

export const { setRouteState } = routeState.actions;

export default routeState.reducer;
