import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import s from "./Resource.module.css";
import { get_single_sresource } from "store/system_resources/system_resource-thunk";
import { ISystemResource } from "store/system_resources/system_resource.interface";

import { ThreeDots } from "react-loader-spinner";
import { ArticleContent } from "components";
import { Page404 } from "pages/404/404.component";

export const ResourceArticleComponent = () => {
  const { resourceId } = useParams();

  const dispatch = useAppDispatch();

  const item: ISystemResource = useAppSelector((state) => {
    return state.specialResourcesState.resources_db.filter(
      (resource: ISystemResource) => resource.id == Number(resourceId)
    )[0];
  });

  const loading = useAppSelector(
    (state) => state.specialResourcesState.loading
  );

  useEffect(() => {
    if (!resourceId) return;
    if (!item || !Object.prototype.hasOwnProperty.call(item, "content")) {
      dispatch(get_single_sresource(resourceId));
    }
  }, [dispatch]);

  return (
    <div className={s.article}>
      {loading ? (
        <ThreeDots wrapperClass={s.loader} />
      ) : (
        <>
          {item ? (
            <ArticleContent type="sresource" data={item}></ArticleContent>
          ) : (
            <Page404 className={s.componentNotFound} />
          )}
        </>
      )}
    </div>
  );
};
