import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  Snackbar,
} from "@mui/material";

import { useEffect, useState } from "react";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import s from "./AddSources.module.css";

import { NavLink, useParams } from "react-router-dom";
import {
  Button,
  ErrorField,
  Htag,
  InputImageComponent,
} from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import {
  Challenge,
  Feelling,
  Wellbeing,
} from "../../../../store/challenges/challenges.interface";
import {
  loadChallenges,
  loadFeellings,
  loadSingleResource,
  loadSingleTool,
  updateResource,
  updateTool,
} from "../../../../store/challenges/challenges-thunk";

import { EditorComponent } from "../../../../components/Editor/Editor.component";
import {
  FileProps,
  IAttach,
} from "../../../../store/system_resources/system_resource.interface";
import { InputFileComponent } from "../../../../components/InputFile/InputFile.component";
import { Multyselect } from "../../../../components/MultySelect/Multyselect.component";
import { useFormik } from "formik";
import { CWellbeing } from "models/Wellbeing.model";
import { validationCreateSchema } from "pages/AdminDashboard/validation";

export const EditSourcePage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const href = window.location.pathname;
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");

  const [currentAttachments, setAttachments] = useState<Partial<FileProps | IAttach>[]>(
    []
  );
  const [attachmentsOld, setOldAttachments] = useState<Partial<FileProps | IAttach>[]>(
    []
  );
  const source: Wellbeing = useAppSelector((state) => {
    if (href.includes("resource")) {
      return state.wellbeing.resources.filter(
        (resource: Wellbeing) => resource.id == id
      );
    } else {
      return state.wellbeing.tools.filter(
        (resource: Wellbeing) => resource.id == id
      );
    }
  })[0];

  useEffect(() => {
    if (href.includes("resource")) {
      setType("resource");
    }
    if (href.includes("tool")) {
      setType("tool");
    }
  }, []);

  const challenges: Challenge[] = useAppSelector(
    (state) => state.wellbeing.challenges.challenges
  );

  const feelings: Feelling[] = useAppSelector(
    (state) => state.wellbeing.feellings.feelings
  );

  useEffect(() => {
    if (!challenges.length && !feelings.length) {
      dispatch(loadChallenges());
      dispatch(loadFeellings());
    }
  }, []);

  const formik = useFormik({
    initialValues: new CWellbeing(),
    validationSchema: validationCreateSchema,
    onSubmit: (values) => {
      const updatedValue = formik.initialValues.formData(values);
      if (href.includes("resource")) {
        dispatch(
          updateResource({
            resource_id: Number(id),
            data: updatedValue,
          })
        )
          .unwrap()
          .then(() => {
            setOpen(true);
            setAttachments([]);
          });
      } else {
        dispatch(
          updateTool({
            tool_id: Number(id),
            data: updatedValue,
          })
        )
          .unwrap()
          .then(() => {
            setOpen(true);
            setAttachments([]);
          });
      }
    },
  });

  const updateFormik = (value: Wellbeing) => {
    setOldAttachments(value.attachments);
    formik.setFieldValue("content", value.content);
    formik.setFieldValue("need_journal", value.need_journal);

    formik.setFieldValue("title", value.title);
    formik.setFieldValue("unique_number", value.unique_number);
    formik.setFieldValue("reading_time", value.reading_time);
    formik.setFieldValue("image", value.image);

    formik.setFieldValue("challenges", value.challenges);

    formik.setFieldValue("feelings", value.feelings);
  };
  useEffect(() => {
    if (!id) return;
    if (href.includes("resource")) {
      dispatch(loadSingleResource(Number(id)))
        .unwrap()
        .then((resp: Wellbeing) => {
          updateFormik(resp);
        });
    }
    if (href.includes("tool")) {
      dispatch(loadSingleTool(Number(id)))
        .unwrap()
        .then((resp: Wellbeing) => {
          updateFormik(resp);
        });
    }
  }, []);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleAtachments = (attachments: FileProps | IAttach) => {
    setAttachments((old) => [...old, attachments]);

    formik.setFieldValue("attachments", [...currentAttachments, attachments]);
  };

  const handleChalenges = (value: any) => {
    formik.setFieldValue("challenges", value);
  };
  const handleFeelings = (value: any) => {
    formik.setFieldValue("feelings", value);
  };

  return (
     <div className={s.formWrap}>
      <Htag tag="h3">Edit source: "{source.title}"</Htag>
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth className={s.title}>
          <input
            id="title"
            name="title"
            onBlur={formik.handleBlur}
            value={formik.values.title}
            type="text"
            onChange={formik.handleChange}
            placeholder="Title"
          />
          {formik.touched.title && formik.errors.title && (
            <ErrorField error={formik.errors.title} />
          )}
        </FormControl>

        <FormControl className={s.reading_time}>
          <input
            id="reading_time"
            name="reading_time"
            value={formik.values.reading_time}
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Reading time"
          />
          {formik.touched.reading_time && formik.errors.reading_time && (
            <ErrorField error={formik.errors.reading_time} />
          )}
        </FormControl>
        <FormControlLabel
          label="Need a journal"
          className={s.need_journal}
          id="need_jornal"
          control={
            <Checkbox
              name="need_journal"
              id="need_journal"
              checked={formik.values.need_journal}
              onChange={formik.handleChange}
            />
          }
        />
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload preview image
          </Htag>
          <InputImageComponent
            handleFiles={formik.setFieldValue}
            handleOnBlur={formik.setFieldValue}
            file={formik.values.image}
            id="image"
            name="image"
          />
        </div>

        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload attachments
          </Htag>
          <InputFileComponent
            files={attachmentsOld}
            handleFiles={handleAtachments}
            id="editResource"
          />
        </div>

        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            handleChange={(e) => formik.setFieldValue("content", e)}
          />
        </div>

        <div className={s.selects}>
          <Multyselect
            label="Challenge"
            selectData={formik.values.challenges}
            selectArray={challenges}
            handleData={handleChalenges}
          />

          <Multyselect
            label="Feeling"
            selectData={formik.values.feelings}
            selectArray={feelings}
            handleData={handleFeelings}
          />
        </div>
        <p className={s.previewLink}>
          <NavLink
            target="_blank"
            to={`/admin-dashboard/wellbeingSources/${type}/edit-page/${id}/preview/`}
          >
            Preview
          </NavLink>
        </p>
        <Button apearance="secondary">Save</Button>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {source.title} is updated!
        </Alert>
      </Snackbar>
    </div>
  );
};
