import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CHANGE_PASS,
  DELETE_PROFILE,
  EMAIL_SUBSCRIPTION,
  GET_USER_DATA,
  LOGIN,
  UPDATE_PROFILE,
} from "../config";

import { IInitState } from "../root-interface";
import { IOUserEntity, IUser, ProfileUpdate } from "./user-interface";
import { ILoginForm } from "../login/login-interface";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const setUser = createAsyncThunk<
  IOUserEntity,
  ILoginForm,
  { getState: IInitState; rejectValue: string }
>("@@login/loginUser", async function (initLogin, { rejectWithValue }) {
  const header = {
    method: "POST",
    headers: {
      Accept: "application/json",
      responseType: "json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(initLogin),
  };

  const response = await fetch(LOGIN(), header);

  if (!response.ok) {
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as IOUserEntity;
  }
});

export const changePassThunk = createAsyncThunk<
  { message: string },
  {
    old_password: string;
    password: string;
    password_confirmation: string;
  },
  { getState: IInitState; rejectValue: string }
>(
  "@@user/changePass",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.PUT,
      getState,
      CHANGE_PASS(),
      rejectWithValue,
      dispatch,
      JSON.stringify(data)
    );
  }
);

export const getUserData = createAsyncThunk<
  { user: IUser },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@user/getUserData",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      GET_USER_DATA(),
      rejectWithValue,
      dispatch,
      JSON.stringify({})
    );
  }
);

export const updateProfile = createAsyncThunk<
  IUser,
  Partial<ProfileUpdate>,
  { getState: IInitState; rejectValue: string }
>(
  "@@user/updateProfile",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.PUT,
      getState,
      UPDATE_PROFILE(),
      rejectWithValue,
      dispatch,
      JSON.stringify(data)
    );
  }
);

export const deleteProfile = createAsyncThunk<
  { success: string },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@user/deleteProfile",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_PROFILE(),
      rejectWithValue,
      dispatch
    );
  }
);

export const updateEmailSubscription = createAsyncThunk<
  { subscription: boolean; success: string },
  { subscription: boolean },
  { getState: IInitState; rejectValue: string }
>(
  "@@user/updateEmailSubscription",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.PUT,
      getState,
      EMAIL_SUBSCRIPTION(),
      rejectWithValue,
      dispatch,
      JSON.stringify(data)
    );
  }
);
