export interface IArticle {
  id?: number;
  reading_time: string;
  title: string;
  content: string;
}


export interface Post extends IArticle {
  slug: string;
  tags: string[];
  categories: string[];
  created_at: string;
  updated_at: string;
  author: string;
  image: string;
}

export interface Blog {
  posts: Post[];
  userPosts: Post[];
  currentPage: number;
  loading: boolean;
  error: string;
}

export interface IGetPostsData {
  data: Post[];
  meta: {
    total: number;
    last_page: number;
    current_page: number;
  };
}

export const categories = [
  "Divorce",
  "Finances",
  "Wellbeing",
  "Children",
  "News",
];
