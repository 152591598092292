
import styles from './StepCircle.module.css';
import cn from 'classnames';
import { StepCircleProps } from './StepCircle.props';

export const StepCircle = ({children, backgroundColor, size , className, ...props}: StepCircleProps): JSX.Element => {
	return (
		<div 
		className={cn(styles.circleStep, className, {
			[styles.big]: size === 'L',
			[styles.middle]: size === 'M',
			[styles.small]: size === 'S'
		} )} style={{ backgroundColor: backgroundColor }}
		{...props}
		>{children}</div>
	);
};