import {  useState } from "react";
import "../../styles/global-style.css";
import cn from "classnames";
import styles from "./HowItWork.module.css";
import { Button, HiwTitle, HowIcon, Htag } from "components";
import { NavLink } from "react-router-dom";
import { check_default } from "../../styles/images/global.images";
import { GetInTouch } from "layout/GetInTouch/GetInTouch.component";
import {
  setRegModalStatus,
} from "store/onBoarding/onboarding-reducer";
import { IsItRight } from "layout/IsItRight/IsItRight.component";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Helmet } from 'react-helmet';

function HowItWorks(): JSX.Element {
  const dispatch = useAppDispatch();
  const [openIRM, setOpenIRM] = useState(false);
  const userToken = useAppSelector((state) => state.userState.token);
  const handleOpenReg = () => dispatch(setRegModalStatus(true));

  return (
    <>
      <Helmet>
        <title>How it Works | SeparateSpace</title>
        <meta
          name="description"
          content="Learn how Separate Space works. Understand our step-by-step process to support you through your separation journey with effective tools and resources."
        />
      </Helmet>
      <section className={cn("section", "innerPageBlock")}>
        <div className="imgBlock">
          <HowIcon />
        </div>
        <div className="contentBlock">
          <p>
            SeparateSpace will support you through the legal, practical and
            emotional aspects of separation and divorce. It is a completely
            online service, designed for both desktop and mobile. This means you
            can access support on demand, 24/7 and take control of the process.
          </p>
        </div>
      </section>
      <section className={cn(styles.hiw_block_steps)}>
        <div className={styles.hiw_block_steps_image}>
          <Htag tag="h1" color="var(--third-logo-color)">
            SeparateSpace, step-by-step
          </Htag>
          <HiwTitle className="mobile_hide" />
        </div>
        <div className={styles.hiw_block_steps_steps}>
          <ul>
            <li>
              <span>1</span>
              <div>
                <p>
                  Check that SeparateSpace is right for you. At the moment,
                  we're supporting married couples separating in England &
                  Wales.
                </p>
                {!userToken && (
                  <p>
                    <a
                      className="hover-underline-animation"
                      onClick={() => setOpenIRM(!openIRM)}
                    >
                      Is this right for me?
                    </a>
                  </p>
                )}
              </div>
            </li>
            <li>
              <span>2</span>
              <div>
                <p>
                  <a
                    className={cn(
                      "hover-underline-animation",
                      styles.black_link
                    )}
                    onClick={handleOpenReg}
                  >
                    Sign up
                  </a>{" "}
                  to SeparateSpace and select your membership plan.
                </p>
              </div>
            </li>
            <li>
              <span>3</span>
              <div>
                <p>
                  Create your unique SeparateSpace dashboard and gain access to
                  your personalised, interactive pathway and your tailored
                  package of guides, templates and real life examples for each
                  stage of the process.
                </p>
              </div>
            </li>
            <li>
              <span>4</span>
              <div>
                <p>
                  Start your SeparateSpace journey through divorce. Let us guide
                  you through your divorce and financial separation, so you feel
                  confident about your choices and know how best to support
                  yourself and your children along the way.
                </p>
              </div>
            </li>
            <li>
              <span>5</span>
              <div>
                <p>
                  Step into the next chapter of your life, having made informed,
                  calm decisions about your parenting, money and property.
                </p>
              </div>
            </li>

            <li>
              <span>
                <img src={check_default} alt="" />
              </span>
              <div>
                {!userToken ? (
                  <>
                    {" "}
                    <Htag tag="h5">Ready to join?</Htag>
                    <Button apearance="secondary" onClick={handleOpenReg}>
                      Sign up
                    </Button>
                  </>
                ) : (
                  <>
                    {" "}
                    <Htag tag="h5">Ready to start?</Htag>
                    <NavLink to="/dashboard">
                      <Button apearance="secondary">Go to dashboard</Button>
                    </NavLink>
                  </>
                )}
              </div>
            </li>
          </ul>
        </div>
      </section>
      {!userToken && <GetInTouch isHome={false} />}

      <IsItRight
        open={openIRM}
        close={() => {
          setOpenIRM(!openIRM);
        }}
      />
    </>
  );
}

export default HowItWorks;
