import { Alert, MenuItem, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import cn from "classnames";
import styles from "./Contact.module.css";

import { Button, DropDownArrowIcon, TextArea } from "components";
import { GetInTouch } from "layout/GetInTouch/GetInTouch.component";

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "utils/hooks";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Helmet } from "react-helmet";

export const validationEmail = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),

  name: yup
    .string()
    .min(2, "Name should be at least 2 characters length")
    .required("Name is required"),
  isInProcess: yup.string().required("This is required field"),

  message: yup
    .string()
    .required("This is required field")
    .max(500, "Max 500 characters"),
});

const isInProcess = [
  {
    value: 0,
    label: "Thinking about separation and divorce",
  },
  {
    value: 1,
    label: "In the process of separation and divorce",
  },
  {
    value: 2,
    label: "Other",
  },
];

export const Contact = (): JSX.Element => {
  const [apiError, setApiError] = useState("");
  const [openSuccessA, setOpenSuccessA] = useState(false);
  const userToken = useAppSelector((state) => state.userState.token);

  const [token, setToken] = useState("");
  const captchaRef = useRef<HCaptcha>(null);

  useEffect(() => {
    if (token) console.log(`hCaptcha Token: ${token}`);
  }, [token]);

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      isInProcess: "",

      message: "",
    },
    validationSchema: validationEmail,
    onSubmit: (values) => {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/contact/send`,
          JSON.stringify(values),
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            responseType: "json",
          }
        )
        .then(() => {
          setApiError("");
          setOpenSuccessA(true);
          formik.resetForm();
          window.datahappy
            .trackLeadCreation({
              eventProperties: {
                value: 10, // common to track a value for lead events
                currency: "GBP",
                lead_source: "contact_us",
              },
              userTraits: values,
            })
            .catch((err: Error) => {
              console.log(err);
            });
        })
        .catch((error) => {
          setOpenSuccessA(false);
          if (error.message) {
            setApiError(error.message);
          }
          setApiError(error.response.data.message);
        });
    },
  });

  return (
    <>
      <Helmet defaultTitle="Online Divorce Platform | SeparateSpace">
        <title>Contact Us | SeparateSpace</title>
        <meta
          name="description"
          content="Get in touch with SeparateSpace. Contact us for support, enquiries, or feedback. We're here to help."
        />
      </Helmet>
      <section className={cn(styles.contactBlock)}>
        {openSuccessA ? (
          <div className={styles.contact_textBlock}>
            <p>
              Thank you for your message.
              <br />
              We aim to reply to all questions within 48 hours.
            </p>
            <NavLink to="/">
              <Button apearance="secondary">Take me home</Button>
            </NavLink>
          </div>
        ) : (
          <>
            <div className={styles.contact_textBlock}>
              <p>
                SeparateSpace is not a law firm. We aren't able to provide you
                with legal advice but as a member you'll find tailored
                information, plus access to templates, guides and additional
                features to support you through your divorce and financial
                separation.
              </p>
              <p>
                Got a question? Tell us how we can help using the form below and
                we will be in touch soon. And, if you'd like a call back, please
                let us know the best number to reach you on.
              </p>
            </div>

            <form onSubmit={formik.handleSubmit} className={styles.form}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label={formik.values.name === "" ? "Your name" : ""}
                InputLabelProps={{ shrink: false, disableAnimation: true }}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                fullWidth
                id="email"
                name="email"
                label={formik.values.email === "" ? "Your email address" : ""}
                InputLabelProps={{ shrink: false, disableAnimation: true }}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />

              <TextField
                variant="outlined"
                name="isInProcess"
                id="isInProcess"
                fullWidth
                select
                onBlur={formik.handleBlur}
                label={formik.values.isInProcess === "" ? "You are…" : ""}
                InputLabelProps={{ shrink: false, disableAnimation: true }}
                value={formik.values.isInProcess}
                onChange={formik.handleChange}
                sx={{
                  "& .MuiSelect-iconOpen": { transform: "rotate(90deg)" },
                  "& .MuiSelect-icon": { top: "16px" },
                  "& .Mui-focused": {
                    color: "#102D3E",
                  },
                  "& .Mui-focused.MuiOutlinedInput-root": {
                    border: "2px solid #91CCD1;",
                  },
                }}
                SelectProps={{
                  IconComponent: (props) => <DropDownArrowIcon {...props} />,
                }}
                error={
                  formik.touched.isInProcess &&
                  Boolean(formik.errors.isInProcess)
                }
                helperText={
                  formik.touched.isInProcess && formik.errors.isInProcess
                }
              >
                {isInProcess.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextArea
                error={
                  formik.touched.message && formik.errors.message
                    ? formik.errors.message
                    : ""
                }
                name="message"
                id="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Type your text here, max 500 characters"
              />

              {apiError && <Alert severity="error">{apiError}</Alert>}

              {process.env.REACT_APP_SITE_KEY && (
                <>
                  {/* <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={handleRecaptcha}
                  /> */}

                  <HCaptcha
                    sitekey="db4ec1a6-c678-45fa-987c-5712b3203594"
                    onVerify={setToken}
                    ref={captchaRef}
                    reCaptchaCompat={false}
                  />
                </>
              )}

              <Button
                align="center"
                type="submit"
                apearance="secondary"
                disabled={!token}
              >
                Submit
              </Button>
            </form>
          </>
        )}
      </section>
      {!userToken && <GetInTouch isHome={false} />}
    </>
  );
};
