import { createAsyncThunk } from "@reduxjs/toolkit";
import { GENERAL_ONBG } from "../config";
import { IInitState } from "../root-interface";

import { IUser } from "../userInfo/user-interface";
import { IGeneralQuestionnary } from "./generalOnboarding-interface";

import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const saveGOnboarding = createAsyncThunk<
  { user: IUser },
  IGeneralQuestionnary,
  { getState: IInitState; rejectValue: string }
>(
  "@@userEntity/saveGOnboarding",
  async function (generalOnBoarding, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      GENERAL_ONBG(),
      rejectWithValue,
      dispatch,
      JSON.stringify(generalOnBoarding)
    );
  }
);
