import { ReactNode, useEffect } from "react";

import { setRouteState } from "store/routeState/routeState";
import { useAppDispatch } from "utils/hooks";

type Props = {
  state?: string;
  children: ReactNode;
  name?: string;
};

const PageWrapper = (props: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.state) {
      dispatch(setRouteState(props.state));
    }
  }, [dispatch, props]);

  return <>{props.children}</>;
};

export default PageWrapper;
