import { NavLink, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useEffect, useState } from "react";
import { BoardsComponent } from "pages/Dashboard/Spaces/Boards/Boards.component";
import { loadMilestoneTickets } from "store/tickets/tickets-thunk";
import { TemplatesComponent } from "pages/Dashboard/Spaces/Templates/Templates.component";
import { ArrowLeftIcon, Htag, Notification } from "components";
import s from "./Milestone.module.css";
import { ResourcesListComponent } from "pages/Dashboard/Spaces/SResources/ResourcesList.component";
import { loadMilestones } from "store/milestones/milestones-thunk";
import { Milestone } from "store/milestones/milestones-interface";
import { CaseStudyList } from "pages/Dashboard/CaseStudies/CaseStudyList.component";
import { get_user_all_cs } from "store/caseStudy/caseStudy_thunk";

import { ISystemResource } from "store/system_resources/system_resource.interface";
import TimeLineItem from "components/TimelineItem/TimeLineItem.component";

const NOTIFICATION_TEXT = [
  "This is the first stage of your divorce pathway. You can click on a ‘Tasks’ to get the information you need, and move it to ‘Completed tasks’ when it’s complete. We’ve also put together a personalised package of guides, templates and case studies to help you through this stage.",
  "This is the second stage of your divorce pathway. It’s all about getting the ball rolling on the legal paperwork. As before, you can click on a ‘Tasks’ to get more details, and move it to ‘Completed tasks’ when it’s complete. Your personalised package of guides and templates has been updated for this stage.",
  "This is the third stage of your divorce pathway. Untangling the finances can be the trickiest part of the divorce process and it usually is the longest. Expect to be in this stage for several weeks. Dealing with this stage can take its toll emotionally. Dip into your Wellbeing Space located on your main dashboard when you need to.",
  "This is the fourth stage of your divorce pathway. Formalising the agreements you’ve reached can feel like a lot of admin but it’s important to do it and to get it right. As ever, your personalised ‘Tasks’ list below, together with the guides and templates, will take you through this stage step-by-step. Hang on in there, you’re getting close to the finishing line.",
  "Welcome to the final stage of your divorce pathway. Now you’re divorced, you’ll need to implement the agreements you’ve reached and deal with the final bits of admin.  Dealing with this stage properly is important to ensure you’re in the best possible position to thrive in the next chapter of your life.",
  "During Step 6, you'll carry out your financial agreement with your ex, and do a few final tasks like updating your will. We know how tiring this can be, but completing this final step will help you enter a new chapter with everything sorted.",
];

export const MilestoneLayout = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [resources, setResources] = useState<ISystemResource[]>([]);
  const milestoneMemory = useAppSelector((state) =>
    state.userState.user.memory.filter(
      (item: any) => item.name == `Notification_Milestone_${id}`
    )
  );

  const { milestones } = useAppSelector((state) => state.milestonesState);
  const [currentMilestone, setCurrentMilestone] = useState<Milestone>();
  const caseStudies = useAppSelector(
    (state) => state.caseStudiesState.caseStudiesArray
  );

  useEffect(() => {
    dispatch(loadMilestones())
      .unwrap()
      .then((res: Milestone[]) => {
        const currentmilestone = res.filter(
          (item) => item.id.toString() == id
        )[0];

        dispatch(loadMilestoneTickets(Number(id)));
        if (currentmilestone) {
          setCurrentMilestone(currentmilestone);
          setResources(currentmilestone.resources);
        }
      });
    dispatch(get_user_all_cs());
  }, [id]);

  return (
    <>
      <Htag tag="h1" className="mb32">
        Step {currentMilestone?.id}: {currentMilestone?.title}
      </Htag>

      <section className={s.milestones}>
        {milestones.map((milestone, index) => (
          <TimeLineItem
            index={index + 1}
            id={milestone.id.toString()}
            title={milestone.title}
            key={index}
            completed_percent={milestone.complete_percent}
          />
        ))}
      </section>
      {id && milestoneMemory[0]?.value !== "true" && (
        <Notification name={`Milestone_${id}`}>
          {id && NOTIFICATION_TEXT[Number(id) - 1]}
        </Notification>
      )}
      <BoardsComponent />
      {id && caseStudies.length > 0 && (Number(id) == 3 || Number(id) == 4) && (
        <section className={s.guidesWrap}>
          <div className={s.resources_header}>
            <Htag tag="h2" className="mb10">
              Case studies
            </Htag>
            <p>
              Each case study highlights multiple options for how these families
              approached their separation.
            </p>
          </div>

          <CaseStudyList data={caseStudies} />
        </section>
      )}
      <section className={s.guidesWrap}>
        <header className={s.resources_header}>
          <Htag tag="h2">Guides</Htag>
        </header>
        {resources && (
          <ResourcesListComponent
            show_in_row={4}
            resources={resources}
            showTgas={true}
          />
        )}
      </section>
      <section className={s.guidesWrap}>
        <TemplatesComponent />
      </section>

      {id && Number(id) < 5 && (
        <div className={s.nextStep}>
          <NavLink to={`/dashboard/milestone/${Number(+id + 1)}`}>
            Next step <ArrowLeftIcon />
          </NavLink>

          <p>{milestones[Number(id)]?.title}</p>
        </div>
      )}
    </>
  );
};
