import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHILDREN_ONBG } from "../config";
import { IInitState } from "../root-interface";
import { cQuestionnaireForm } from "./questionnaires-interface";
import { resetToDefault } from "store/login/login-reducer";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const saveCOnboarding = createAsyncThunk<
  {
    children_data: cQuestionnaireForm;
    success: string;
  },
  cQuestionnaireForm,
  { getState: IInitState; rejectValue: string }
>(
  "@@userEntity/saveCOnBoarding",
  async function (childrenOnBoarding, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      CHILDREN_ONBG(),
      rejectWithValue,
      dispatch,
      JSON.stringify(childrenOnBoarding)
    );
  }
);
