import { ISystemResource } from '../system_resources/system_resource.interface';
import { ITicket } from '../tickets/tickets-interface';

export const milestones: readonly { value: number; label: string }[] = [
  {
    value: 1,
    label: "Get organised",
  },
  {
    value: 2,
    label: "Start legal process",
  },
  {
    value: 3,
    label: "Assess finances",
  },
  {
    value: 4,
    label: "Separate finances",
  },
  {
    value: 5,
    label: "Finish legal process",
  },
  {
    value: 6,
    label: "Post-divorce admin",
  },
];

export interface Milestone {
  id: number;
  title: string;
  complete_percent: number;
  resources: ISystemResource[];
  sort: number;
  tickets: ITicket[];
}
