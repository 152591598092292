export const getArticleLink = (
  type?: string,
  id?: number,
  space_id?: number
) => {

  switch (type) {
    case "ticket":
      return `/dashboard/milestone/${space_id}/id=${id}`;
    case "special_ticket":
      return `/dashboard/milestone/${space_id}/special_ticket_id=${id}`;
    case "space_resource":
    case "sresource":
      return `/dashboard/resources/${id}`;
    case "article":
      return `/dashboard/articles/${id}`;
    case "wellbeing_resource":
    case "wresources":
      return `/dashboard/my-wellbeing/challenges/resource/${id}`;
    case "wellbeing_tool":
    case "wtools":
      return `/dashboard/my-wellbeing/challenges/tool/${id}`;
    case "case_studies":
      return `/dashboard/case_studies/${id}`;
    default:
      return "";
  }
};
