import { createSlice } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import { loadMilestone, loadMilestones } from "./milestones-thunk";

import { ITemplates, ITicket } from "../tickets/tickets-interface";
import { Milestone } from "./milestones-interface";

export interface MilestoneState {
  loading: boolean;
  error: string;
  templates: ITemplates[];
  tickets: ITicket[];
  complete_percent: number;
}

export interface MilestonesState {
  loading: boolean;
  error: string;
  currentMilestone: MilestoneState;
  milestones: Milestone[];
}

const initialState: MilestonesState = {
  milestones: [],
  currentMilestone: {
    loading: false,
    error: "",
    templates: [],
    tickets: [],
    complete_percent: 0,
  },
  loading: false,
  error: "",
};

const currentMilestone = createSlice({
  name: "currentMilestone",
  initialState,
  reducers: {
    updateMilestoneCompleting: (state, action) => {
   
      state.milestones = state.milestones.map((milestone) => {
        if (milestone.id == action.payload.id) {
          milestone.complete_percent = action.payload.complete_percent;
        }
        return milestone;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadMilestones.fulfilled, (state, action) => {
        state.milestones = action.payload;
      })
      .addCase(loadMilestone.fulfilled, (state, action) => {
        state.currentMilestone.tickets = action.payload;
      })
      .addCase(resetToDefault, () => {
        return initialState;
      });
  },
});
export const { updateMilestoneCompleting } = currentMilestone.actions;

export default currentMilestone.reducer;
