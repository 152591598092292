import { useFormik } from "formik";
import { useRef, useState } from "react";

import ReactHtmlParser from "html-react-parser";
import s from "./IsItRight.module.css";
import { Button, ErrorField, Htag, Icon1, ModalStyled } from "components";
import { IsItRightProps } from "./IsItRight.props";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import cn from "classnames";

import * as yup from "yup";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { ISITRIGHT } from "store/config";
import {
  CustomSnackbar,
  CustomSnackbarRef,
} from "components/CustomSnackbar/CustomSnackbar.component";
const validationSubmitSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const IsItRight = ({ open, close }: IsItRightProps) => {
  const [thankMsg, setThankMsg] = useState(false);
  const ref = useRef<CustomSnackbarRef>(null);
  const [isItRightMessage, setIsItRightMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      marriage_ending: "",
      local: "",
    },

    onSubmit: (values) => {
      if (values.local[0] !== "on" && values.marriage_ending[0] !== "on") {
        setIsItRightMessage(
          "<p>If you or your ex have a connection to another country, you should consult a legal expert in that country to find out about any rights you may have there. You can still use SeparateSpace but keep in mind it is designed to support people through divorce in England and Wales only.</p><p>We're working to support unmarried couples going through family separation and we'll be launching this soon. Leave your details below if you'd like early access.</p>"
        );
      } else if (values.local[0] !== "on") {
        setIsItRightMessage(
          ` <p>If you or your ex have a connection to another country, you should consult a legal expert in that country to find out about any rights you may have there. You can still use SeparateSpace but keep in mind it is designed to support people through divorce in England and Wales only.</p>`
        );
      } else if (values.marriage_ending[0] !== "on") {
        setIsItRightMessage(
          `<p>We're working to support unmarried couples going through family separation and we'll be launching this soon. Leave your details below if you'd like early access.</p>`
        );
      } else {
        setThankMsg(true);
      }
    },
  });

  const closeForm = () => {
    formik.resetForm();
    askForSupport.resetForm();
    setIsItRightMessage("");
    setThankMsg(false);
    close();
  };

  const askForSupport = useFormik({
    initialValues: {
      name: "",
      email: "",
      country: "",
      supportNotMarried: false,
      supportNotForeigner: false,
    },
    validationSchema: validationSubmitSchema,
    onSubmit: (values) => {
      axios
        .post(ISITRIGHT(), JSON.stringify(values), {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          responseType: "json",
        })
        .then(() => {
          ref.current?.refresh();
          formik.resetForm();

          setThankMsg(false);
          close();
          askForSupport.resetForm();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  return (
    <ModalStyled open={open} size="m" close={closeForm}>
      {!isItRightMessage ? (
        <div className={s.modalWrap}>
          <Icon1 />

          {!thankMsg ? (
            <>
              <Htag tag="h4">Is SeparateSpace right for you?</Htag>

              <p>
                <small>
                  To make sure we can help you, we need to check a few things
                  about your personal situation.
                </small>
              </p>

              <form
                id="form-0"
                className={s.form}
                onSubmit={formik.handleSubmit}
              >
                <FormControlLabel
                  className={s.formFieldCheckbox}
                  control={
                    <Checkbox
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      color="default"
                      name="marriage_ending"
                    />
                  }
                  label="Your marriage or civil partnership is ending, or has ended."
                />

                <FormControlLabel
                  className={s.formFieldCheckbox}
                  control={
                    <Checkbox
                      name="local"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      color="default"
                    />
                  }
                  label="You live in England or Wales"
                />

                <div className={s.submit}>
                  <Button type="submit" apearance="secondary">
                    Submit
                  </Button>
                </div>
              </form>
            </>
          ) : (
            <div className={s.isItRightSuccessMsg}>
              <p>
                Thanks for answering those questions. SeparateSpace will be able
                to support you through your separation from the practical and
                legal stuff to taking care of your wellbeing.
              </p>
              <Htag tag="h4">What's next?</Htag>
              <p>
                You'll need to join us to benefit from our expert support and
                resources. Check out our membership plan{" "}
                <NavLink to="/pricing" onClick={closeForm}>
                  here
                </NavLink>
                .
              </p>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className={cn(s.askForSupport, s.isItRightSuccessMsg)}>
            {ReactHtmlParser(isItRightMessage)}

            {formik.values.marriage_ending[0] !== "on" ? (
              <>
                <form
                  id="form-0"
                  className={s.form_askForSupport}
                  onSubmit={askForSupport.handleSubmit}
                >
                  <FormControl fullWidth className={s.formField}>
                    <input
                      type="text"
                      value={askForSupport.values.name}
                      onChange={askForSupport.handleChange}
                      placeholder="Name"
                      onBlur={askForSupport.handleBlur}
                      name="name"
                    />
                    {askForSupport.touched.name &&
                      askForSupport.errors.name && (
                        <ErrorField error={askForSupport.errors.name} />
                      )}
                  </FormControl>
                  <FormControl fullWidth className={s.formField}>
                    <input
                      type="text"
                      onChange={askForSupport.handleChange}
                      onBlur={askForSupport.handleBlur}
                      value={askForSupport.values.email}
                      placeholder="Email"
                      name="email"
                    />
                    {askForSupport.touched.email &&
                      askForSupport.errors.email && (
                        <ErrorField error={askForSupport.errors.email} />
                      )}
                  </FormControl>
                  <div className={s.submit}>
                    <Button apearance="secondary">Send</Button>
                  </div>
                </form>
              </>
            ) : (
              <>
                {" "}
                <Htag tag="h4">What's next?</Htag>
                <p>
                  You'll need to join us to benefit from our expert support and
                  resources. Check out our membership plan{" "}
                  <NavLink to="/pricing" onClick={closeForm}>
                    here
                  </NavLink>
                  .
                </p>
              </>
            )}
          </div>
        </>
      )}

      <CustomSnackbar message={"Your details have been submitted."} ref={ref} />
    </ModalStyled>
  );
};
