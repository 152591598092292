import { RecentPostsComponent } from "pages/Blog/RecentPosts.component";
import s from "../PageTemplate.module.css";
import cn from "classnames";
import { Htag } from "components";
import { team } from "pages/Home/Home.images";
import { Helmet } from 'react-helmet';

export const ThankYComponent = () => {
  return (
    <>
      <Helmet>
        <title>Free Download Thank you | SeparateSpace</title>
        <meta
          name="description"
          content="Thank you for downloading the Smarter Start to Separation Essential Checklist. Start your separation journey with the right tools and support."
        />
      </Helmet>
      <div className={s.templateContent}>
        <div>
          <div className={cn(s.templatePage, "section")}>
            <Htag tag="h4">Thank you!</Htag>
            <p>
              Keep an eye on your inbox, your free content will be arriving
              soon.
            </p>
            <p>
              If you don't receive an email from us in the next 15 minutes,
              check your Spam folder.
            </p>
          </div>
        </div>
        <div className={s.teamWrap}>
          <div className={s.circle}></div>
          <img className={s.team_photo} src={team} alt="" />
        </div>
      </div>
      <div className={s.meantime}>
        <p>
          In the meantime, here are our latest blogs for you to take a look at.
        </p>
      </div>
      {
        <div className={s.postsList}>
          <RecentPostsComponent id={0} />
        </div>
      }
 
    </>
  );
};
