import { useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";

import s from "../SpecialSources.module.css";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Htag } from "components";

import { get_waiting_list } from "store/waitingList/waitingList-thunk";
import dayjs from "dayjs";

export const WaitingList = () => {
  const dispatch = useAppDispatch();

  const waitingList = useAppSelector((state) => state.waitingList.waitingList);

  useEffect(() => {
    dispatch(get_waiting_list());
  }, [dispatch]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerClassName: s.headerClassName,
      headerName: "Id",
      sortable: false,
      width: 70,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerClassName: s.headerClassName,
      headerName: "Name",
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerClassName: s.headerClassName,
      headerName: "Email",
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "created_at",
      headerClassName: s.headerClassName,
      headerName: "Created at...",
      sortable: false,
      width: 170,
      type: "date",
      disableColumnMenu: true,
      valueFormatter: (value) => {
        return dayjs(value).format("DD/MM/YYYY");
      },
     
    },
  ];

  return (
    <>
      <Htag tag="h3">List of users in the waiting list</Htag>
      <Box sx={{ height: 875, width: "100%" }}>
        <DataGrid
          rows={waitingList}
          columns={columns}
          className={s.tableWrap}
          pageSizeOptions={[15, 30, 50, 70, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
        />
      </Box>
    </>
  );
};
