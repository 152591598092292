import { Checkbox, FormControlLabel } from "@mui/material";
import { useFormik } from "formik";

import * as yup from "yup";
import styles from "../OnBoarding.module.css";
import cn from "classnames";
import {
  setBoardingStep,
  setInterview,
} from "store/onBoarding/onboarding-reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Button, ErrorField, Htag,  StepCircle } from "components";
export const validationSignupSchema = yup.object({
  has_partner: yup.bool().oneOf([true], "This field is required"),
  local: yup.bool().oneOf([true], "This field is required"),
});

export const IsRightForYouForm = (): JSX.Element => {
  const initInterview = useAppSelector(
    (state) => state.onRegistration.interview
  );
  const activeStep = useAppSelector((state) => state.onRegistration.activeStep);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: initInterview,
    validationSchema: validationSignupSchema,
    onSubmit: () => {
      dispatch(setBoardingStep(activeStep));
    },
  });

  const updateInterviewChecked = (ev: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(ev);
    dispatch(
      setInterview({
        ...initInterview,
        [`${ev.target.name}`]: ev.target.checked,
      })
    );
  };

  return (
    <form
      id="form-0"
      onSubmit={formik.handleSubmit}
      className={styles.form_group}
    >
      <header className={styles.onboarding_header}>
        <StepCircle size="S" backgroundColor={"var(--main-dark)"}>
          <span className={styles.step_color}>{activeStep + 1}</span>
        </StepCircle>
        <Htag tag="h3">SeparateSpace can help if</Htag>
      </header>

      <div className={cn(styles.left, styles.checkboxGroup)}>
        <FormControlLabel
          control={
            <Checkbox
              name="has_partner"
              color="default"
              onChange={updateInterviewChecked}
              onBlur={formik.handleBlur}
            />
          }
          label="Your marriage or civil partnership is ending, or has ended."
        />
        {formik.touched.has_partner && formik.errors.has_partner && (
          <ErrorField error={formik.errors.has_partner} />
        )}
        <FormControlLabel
          control={
            <Checkbox
              name="local"
              onChange={updateInterviewChecked}
              onBlur={formik.handleBlur}
              color="default"
            />
          }
          label="You live in England or Wales."
        />
        {formik.touched.local && formik.errors.local && (
          <ErrorField error={formik.errors.local} />
        )}
      </div>
      <div className={styles.buttonGroup}>
        <Button apearance="secondary" type="submit">
          Next
        </Button>
      </div>
  
    </form>
  );
};
