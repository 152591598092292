import { Card } from 'components';
import { BannerWidget } from '../bannerWidget/bannerWidget.component';
import s from './widgets.module.css'

export const TotalWidgetsView = () => {
  return <div className={s.widgetsContainer}>
	<Card>
		<BannerWidget />
	</Card>
  </div>;
};
