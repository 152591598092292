import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  Button,
  ClockIcon,
  Htag,
  ResourceIcon,
  TemplateIcon,
} from "components";
import ReactHtmlParser from "html-react-parser";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { updateTicketInfo } from "store/tickets/tickets-reducer";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  updateTicket,
  loadSingleTicket,
} from "store/tickets/tickets-thunk";
import s from "./SingleTicket.module.css";
import cn from "classnames";
import { TextareaAutosize } from "@mui/material";

import { ThreeDots } from "react-loader-spinner";
import r from "components/ArticleContent/ArticleContent.module.css";
import { ITemplates, ITicket } from "store/tickets/tickets-interface";
import { FileProps } from "store/system_resources/system_resource.interface";

import { downloadAttach } from "controlls/tickets-controll";
import { Page404 } from "../../../404/404.component";

export const SingleTicket = () => {
  const { ticketId } = useParams();

  const { loading } = useAppSelector((state) => state.tickets);
  const [comment, setComment] = useState<string>("");
  const [saveBtn, setSaveBtn] = useState<boolean>(false);
  const [descValue, setDescValue] = useState("");

  const dispatch = useAppDispatch();
  const id_link = ticketId?.slice(ticketId.indexOf("/") + 1) || "";
  const id = ticketId?.slice(ticketId.indexOf("=") + 1);

  const ticket: ITicket = useAppSelector((state) => {
    if (id_link.includes("special_ticket_id")) {
      return state.tickets.tickets.filter(
        (item) => item.special_ticket_id == id
      )[0];
    } else {
      return state.tickets.tickets.filter((item) => item.id == id)[0];
    }
  });

  useEffect(() => {
    if (ticket) setDescValue(ticket.description);
  }, [ticket]);

  useEffect(() => {
    dispatch(loadSingleTicket(`${id_link}`));
  }, []);

  const updateDescValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescValue(e.target.value);
  };

  const saveTicket = () => {
    if (ticket.id) {
      dispatch(
        updateTicket({
          ticket_id: ticket.id,
          ticket: { ...ticket, description: descValue },
        })
      )
        .unwrap()
        .then(() => {
          setSaveBtn(false);
          dispatch(updateTicketInfo({ ...ticket, description: descValue }));
        });
    }
  };

  return (
    <div className={r.articleContent}>
      {loading ? (
        <ThreeDots wrapperClass={s.loader} />
      ) : ticket ? (
        <div className={cn(s.ticketViewWrap)}>
          <header className={s.ticketViewHeader}>
            <Htag tag="h2" className={r.title}>
              {ticket.title}
            </Htag>

            <div className={s.extraInfo}>
              <span>
                <ClockIcon /> Updated {dayjs(ticket.updated_at).fromNow()}
              </span>
              {ticket?.reading_time && (
                <span>
                  <>
                    <ClockIcon /> {ticket?.reading_time}
                  </>
                </span>
              )}
              {ticket.attachments && ticket.attachments.length > 0 && (
                <span className={r.templatesMark}>
                  <>
                    <TemplateIcon /> Templates
                  </>
                </span>
              )}
            </div>
          </header>
          <div className={cn(s.ticket_desc, r.contentWrap)}>
            <Htag tag="h4">
              <ResourceIcon /> Description
            </Htag>
            <div className={cn(s.ticket_desc_content)}>
              {!ticket.special_ticket_id ? (
                <TextareaAutosize
                  onFocus={() => setSaveBtn(true)}
                  className={cn(s.descField, {
                    [s.unedit]: ticket.special_ticket_id,
                  })}
                  readOnly={Boolean(ticket.special_ticket_id)}
                  value={descValue}
                  onChange={updateDescValue}
                />
              ) : (
                typeof descValue == "string" && ReactHtmlParser(descValue)
              )}
            </div>

            {saveBtn && !ticket.special_ticket_id && (
              <Button
                onClick={saveTicket}
                className={s.btnSave}
                apearance="secondary"
              >
                Save
              </Button>
            )}
            {ticket.attachments && ticket?.attachments.length > 0 && (
              <div className={r.templates}>
                <h3>
                  <TemplateIcon /> Templates to help you
                </h3>
                {ticket.attachments.map(
                  (template: ITemplates, index: number) => (
                    <div
                      onClick={() => {
                        if (ticket.special_ticket_id) {
                          if (template.name)
                            downloadAttach(
                              template.id,
                              ticket.special_ticket_id,
                              template.name
                            );
                        }
                      }}
                      className={r.templateItem}
                      key={index}
                    >
                      <FileDownloadOutlinedIcon />
                      {template.name}
                    </div>
                  )
                )}
              </div>
            )}
          </div>

          <NavLink
            to=".."
            relative="path"
            className={cn(s.backBtn, "hover-underline-animation_back")}
          >
            {"<"} Back
          </NavLink>
        </div>
      ) : (
        <Page404 className={s.componentNotFound} />
      )}
    </div>
  );
};
