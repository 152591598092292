import { Card, FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import { Button, EditorComponent, Htag } from "components";
import { OptionsWidgetProps } from "./OptionsWidget.props";
import s from "./OptionsWidget.module.css";

import { OptionList } from "./OptionList.component";
import { FullModalStyled } from "../../../../components/FullWidthModal/FullModal.component";

export const OptionsWidget = ({
  id,
  handleOptions,
  data,
}: OptionsWidgetProps) => {
  const [pros, setPros] = useState<string[]>([]);
  const [cons, setCons] = useState<string[]>([]);


  const [title, setTitle] = useState<string>("");
  const [openOptionDialog, setopenOptionDialog] = useState(false);
  const [content, setContent] = useState("");

  const handleOpenECS = () => {
    setopenOptionDialog(true);
  };

  const handleCloseDialog = () => {
    setopenOptionDialog(false);
  };

  const handleChangeContent = (content: string) => {
    setContent(content);
  };

  const setOptions = () => {
    handleOptions({
      id: id,
      title: title,
      pros: pros,
      cons: cons,
      content: content,
    });

    handleCloseDialog();
  };
   const removeOptions = () => {
     handleOptions(Number(id));

     
   };

  const handleTitle = (e: any) => {
    setTitle(e.target.value);
  };

  useEffect(() => {
    if (data) {
      if (data) {
        setTitle(data.title);
        setContent(data.content);
        setPros(data.pros);
        setCons(data.cons);
      }
    } else {
      setTitle("");
      setContent("");
      setPros([]);
      setCons([]);
    }
  }, [data]);

  return (
    <>
      <Card className={s.card} key={id}>
        Option {Number(id) + 1}
        {data ? (
          <Button apearance="broun" type="button" onClick={handleOpenECS}>
            Edit
          </Button>
        ) : (
          <Button
            apearance="primary-light"
            type="button"
            onClick={handleOpenECS}
          >
            Add
          </Button>
        )}
      </Card>

      <FullModalStyled
        color={"light"}
        open={openOptionDialog}
        close={handleCloseDialog}
      >
        <div className={s.extraCSWrap}>
          <Htag tag="h4" className={s.title}>
            Option's title
          </Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              value={title}
              type="text"
              onChange={handleTitle}
              placeholder="Title"
            />
          </FormControl>
          <Htag tag="h4" className={s.title}>
            Description
          </Htag>

          <div className={s.wyswyg_editorWrap}>
            <EditorComponent
              value={content}
              height="200px"
              handleChange={handleChangeContent}
            />
          </div>

          <Htag tag="h4" className={s.title}>
            Pros
          </Htag>
          <OptionList list={pros} setList={setPros} />
          <Htag className={s.title} tag="h4">
            Cons
          </Htag>
          <OptionList list={cons} setList={setCons} />
          <Button onClick={setOptions} apearance="primary" type="button">
            Set option
          </Button>
          <Button onClick={removeOptions} apearance="primary" type="button">
            Remove option
          </Button>
        </div>
      </FullModalStyled>
    </>
  );
};
