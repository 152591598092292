import { Alert, Snackbar } from "@mui/material";


import s from "./Unsubscribe.module.css";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../store/config";
import { useParams } from "react-router-dom";
import { Button, Htag } from 'components';

export const Unsubscribe = (): JSX.Element => {
  const [nlError, setNlError] = useState<string>();
  const [open, setOpen] = useState(false);
  const { sh } = useParams();
  const unsubscribe = () => {
    axios
      .post(
        `${BASE_URL}/newsletter/unsubscribe`,
        { hash: sh },
        {
          headers: {
            Accept: "application/json",
          },
          responseType: "json",
        }
      )
      .then(() => {
        setOpen(true);
        setNlError("");
      })
      .catch((err) => {
		
		err.response.data.error ? setNlError(err.response.data.error) : setNlError('Server error');
        setOpen(true);
      });
  };

  return (
    <div className={s.unsubscribeWrap}>
      <div className={s.unsubscribe_form}>
        <Htag tag="h2">Unsubscription</Htag>
        <p>
          If you unsubscribe now, you might miss useful materials from
          SeparateSpace. Click on 'Unsubscribe' to stop receiving emails from us
          at this email address.
        </p>
        <Button apearance="secondary" onClick={unsubscribe}>
          Unsubscribe
        </Button>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={!nlError ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {!nlError ? "Success" : nlError}
        </Alert>
      </Snackbar>
    </div>
  );
};
