import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_ACTIVITIES_DATA,
  GET_ANALITIC_DATA,
  GET_BOOKED_SESSION_PROGRESS,
  GET_USERS_DATA_ALL_STATISTIC,
  GET_USERS_DATA_STATISTIC,
} from "../config";
import { IInitState } from "../root-interface";
import {
  IChart,
  UserAnaliticData,
  UsersActivitiesData,
} from "./analytics.interface";
import { API_TYPE, getRequestFabric } from "store/thunk-helper";

export const getChartsData = createAsyncThunk<
  { chartData: IChart[]; userData: UserAnaliticData },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@analytics/getChartsData",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ANALITIC_DATA(),
      rejectWithValue,
      dispatch
    );
  }
);

export const getUsersDataStatistic = createAsyncThunk<
  { userData: UserAnaliticData },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@analytics/getUsersDataStatistic",
  async function (time, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_USERS_DATA_STATISTIC(time),
      rejectWithValue,
      dispatch
    );
  }
);

export const getUsersDataAllDaysStatistic = createAsyncThunk<
  { userData: UserAnaliticData },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@analytics/getUsersDataAllDaysStatistic",
  async function (time, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_USERS_DATA_ALL_STATISTIC(time),
      rejectWithValue,
      dispatch
    );
  }
);

export const getLastActivitiesData = createAsyncThunk<
  UsersActivitiesData,
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@analytics/getActivitiesData",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ACTIVITIES_DATA(),
      rejectWithValue,
      dispatch
    );
  }
);

export const getBookedSessionProgress = createAsyncThunk<
  { bookedData: UserAnaliticData },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@analytics/getBookedSessionProgress",
  async function (time, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_BOOKED_SESSION_PROGRESS(time),
      rejectWithValue,
      dispatch
    );
  }
);
