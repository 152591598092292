import axios from "axios";
import { DOWNLOAD_TICKET_ATTACH } from "../store/config";

export const downloadAttach = (id: number, ticket_id: number, name: string) => {
  const token = localStorage.getItem("userToken");
  axios
    .get(DOWNLOAD_TICKET_ATTACH(id, ticket_id), {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
    })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", name); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};
