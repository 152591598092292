import { ListItem } from "@mui/material";
import { NavLink } from "react-router-dom";
import { RouteType } from "../../routes/config";

import s from "./Sidebar.module.css";
import cn from "classnames";
type Props = {
  item: RouteType;
  className?: string;
};

const SidebarItem = ({ item, className }: Props) => {
  return item.menuProps && item.path ? (
    <ListItem className={cn(s.sidebarItem, className)}>
      <NavLink
        className={({ isActive }) => (isActive ? s.active : s.inactive)}
        to={item.url || item.path}
      >
        {item.menuProps.displayText}
      </NavLink>
    </ListItem>
  ) : null;
};

export default SidebarItem;
