import { ITextareaProps } from "./Textarea.props";
import styles from "./Textarea.module.css";
import cn from "classnames";

export const TextArea = ({
  error,
  value,
  className,
  placeholder,
  ...props
}: ITextareaProps): JSX.Element => {
  return (
    <div>
      <textarea
        value={value}
        className={cn(styles.textarea, className, {
          [styles.has_error]: error,
        })}
        placeholder={placeholder}
        {...props}
      ></textarea>
      {error ? <span className={styles.errorText}>{error}</span> : null}
    </div>
  );
};
