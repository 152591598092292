import ReactDOM from "react-dom/client";
import { Root } from "./Root";

import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor } from './store';
import { Provider } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={<ThreeDots />} persistor={persistor}>
      <Root  />
    </PersistGate>
  </Provider>
);
