import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import { Button, Htag } from "../../components";

import { EditorComponent } from "../../components/Editor/Editor.component";
import s from "./ExtraCS.module.css";
import { FullModalStyled } from "../../components/FullWidthModal/FullModal.component";
import { ECSWidgetProps } from "./ExtraCaseStudy.props";
import { IIssue } from "../../store/caseStudy/caseStudy.interface";

export const ExtraCaseStudy = ({
  title,
  index,
  data,
  handleOptions,
}: ECSWidgetProps): JSX.Element => {
  const [openExtraCS, setOpenExtraCS] = useState<boolean>(false);
  const [content, setContent] = useState("");

  const setIssue = () => {
    let updatedArr: IIssue[] = [];

    if (data.length) {
      updatedArr = data.filter((item) => index + 1 !== item.problem_id);
    }

    handleOptions([
      ...updatedArr,
      {
        content: content,
        problem_id: index + 1,
        title: title,
      },
    ]);

    setOpenExtraCS(false);
  };

  const handleChangeContent = (content: string) => {
    setContent(content);
  };

  const isProblemAdded = () => {
    return data.find((x) => x.problem_id == Number(index + 1));
  };

  useEffect(() => {
    if (data.length > 0) {
      const item = isProblemAdded();
      if (item) {
        setContent(item.content);
      }
    }
  }, [data]);

  return (
    <>
      <Card className={s.card} key={index}>
        {title}

        {isProblemAdded() ? (
          <Button
            onClick={() => setOpenExtraCS(true)}
            apearance="broun"
            type="button"
          >
            Edit
          </Button>
        ) : (
          <Button
            onClick={() => setOpenExtraCS(true)}
            apearance="primary-light"
            type="button"
          >
            Add
          </Button>
        )}
      </Card>

      <FullModalStyled
        color={"light"}
        open={openExtraCS}
        close={() => setOpenExtraCS(false)}
      >
        <div className={s.extraCSWrap}>
          <Htag tag="h3">{title}</Htag>

          <div className={s.wyswyg_editorWrap}>
            <EditorComponent
              value={content}
              height="200px"
              name="content"
              handleChange={handleChangeContent}
            />
          </div>

          <Button onClick={setIssue} apearance="primary" type="button">
            Set
          </Button>
        </div>
      </FullModalStyled>
    </>
  );
};
