import cn from "classnames";

import "../../styles/global-style.css";

import styles from "./Features.module.css";
import { GetInTouchTeam } from "../Team/GetInTouchTeam/GetInTouchTeam.component";
import {
  CheckIcon,
  Finances,
  HowIcon,
  Htag,
  Information,
  WellbeingImg,
} from "components";
import { phone } from "pages/Home/Home.images";
import { Helmet } from "react-helmet";

const FeatureData = [
  {
    image: <img src={phone} />,
    title: "Interactive timelines and action lists",
    options: [
      "A clear view of the path ahead so you can see what’s next and plan accordingly.",
      "Personalised action lists to help you understand what needs to be done and project manage the process.",
      "Notes and guidance along the way.",
    ],
  },
  {
    image: <Finances />,
    title: "A clear view of your finances",
    options: [
      "Understand how the court approaches situations like yours and get case studies showing how couples with similar circumstances to yours have separated their finances.",
    ],
  },
  {
    image: <Information />,
    title: "Tailored information",
    options: [
      "We will ask you some basic questions so that we can give you information, guidance and templates that are relevant to you.",
      "Resources designed to help you unlock your future including case studies selected to illustrate what others have done in circumstances similar to yours.",
    ],
  },
];
const FeatureData2 = [
  {
    image: <WellbeingImg />,
    title: "Holistic and wellbeing support",
    options: [
      "Divorce is much more than a legal problem. Our bank of wellbeing tools and resources has been created by a panel of mental health experts. They have been designed to practically assist and relieve some of the load that affects your family while you go through one of life’s toughest challenges.",
      "Our holistic support is wide-ranging; from help to support your children through this time of transition to tools that encourage a constructive relationship between you and your ex going forward.",
    ],
  },
];

function Features(): JSX.Element {
  return (
    <>
      <Helmet>
        <title>Our platform features | SeparateSpace</title>
        <meta
          name="description"
          content="Discover the features of SeparateSpace. Tools and resources designed to support you through your separation and help you manage the process effectively."
        />
      </Helmet>
      <section className={cn("section", "innerPageBlock")}>
        <div className="imgBlock">
          <HowIcon />
        </div>
        <div className="contentBlock">
          <p>
            Divorce can mean a profoundly different future to the one you
            imagined.
          </p>
          <p>
            We know that big decisions (such as where you will live and what
            arrangements you will make about your children) are best made if you
            can see the whole picture and have the headspace to think things
            through.
          </p>
          <p>
            That's why SeparateSpace gives you the legal, practical and
            emotional stuff you need, in one place and at your pace.
          </p>
        </div>
      </section>
      <section className={cn("section")}>
        {FeatureData.map((x, index) => (
          <div key={index} className={styles.featureItem}>
            <div className={styles.image}>{x.image}</div>
            <div className={styles.optionsContent}>
              <Htag tag="h3" className='mb32'>{x.title}</Htag>
              <ul>
                {x.options.map((option, index) => (
                  <li key={index + "-opt"}>
                    <CheckIcon />
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </section>
      <section className={styles.quoteBlock}>
        <div className={cn("section", styles.section)}>
          <Htag tag="h1">
            “Divorce can be confusing, stressful and costly. We want to provide
            affordable information and tools to empower people to navigate their
            divorce with confidence.”
          </Htag>
          <p>Amanda Bell and Victoria Nottage, Founders</p>
        </div>
      </section>

      <section className={cn("section")}>
        {FeatureData2.map((x, index) => (
          <div key={index} className={styles.featureItem}>
            <div className={styles.image}>{x.image}</div>
            <div className={styles.optionsContent}>
              <Htag tag="h3" className='mb32'>{x.title}</Htag>
              <ul>
                {x.options.map((option, index) => (
                  <li key={index + "-opt"}>
                    <CheckIcon />
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </section>
      <GetInTouchTeam />
    </>
  );
}

export default Features;
