import * as yup from "yup";

export const validationCaseStudySchema = yup.object({
  title: yup
    .string()
    .min(3, "Title should be at least 3 characters length")

    .required("Title is required"),
  subtitle: yup.string(),

  content: yup.string().required("Content is required"),
});
