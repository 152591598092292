import { Alert, Snackbar } from "@mui/material";
import { CustomSnackbarProps } from "./CustomSnackbar.props";
import { forwardRef, useImperativeHandle, useState } from "react";

export type CustomSnackbarRef = {
  refresh: () => void;
};

export const CustomSnackbar = forwardRef<
  CustomSnackbarRef,
  CustomSnackbarProps
>((props, ref): JSX.Element => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useImperativeHandle(ref, () => ({
    refresh: () => {
      setOpenSnackbar(true);
    },
  }));
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity="success"
        sx={{ width: "100%" }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
});
