import { Accordion, CheckIcon, Htag } from "components";
import s from "./Pricing.module.css";
import cn from "classnames";
import { useState } from "react";
import { IsItRight } from "layout/IsItRight/IsItRight.component";
import { PaymentComponent } from "components/PaymentComponent/Payment.component";
import { Helmet } from "react-helmet";


const pricingFaq = [
  {
    title: `How will SeparateSpace help me through my divorce?`,
    content: `<p>SeparateSpace will respond to your individual circumstances to create a personalised pathway taking you through each of the legal, financial and practical phases of your divorce.</p>
<p>Created by leading experts across law, finance, children and wellbeing, SeparateSpace gives you step-by-step guidance and templates created by our legal experts, alongside advice and scripts written by experts in child psychology and relationships.</p>
`,
  },
  {
    title: `How long does a divorce take?`,
    content: `<p>It typically takes around 12 months to deal with both divorce (the legal process of no longer being married to someone) and financial separation (the process of untangling your finances). </p> 
<p>There’s no such thing as a ‘quickie divorce’ - it’s legally impossible to get divorced in less than 6 months. </p> 
<p>The reason divorce usually takes longer than 6 months is the financial separation part of it.  Untangling finances can be complex but it’s important to deal with it properly.  Remember, the financial agreement you reach will impact you for years to come. </p> 
`,
  },
  {
    title: `How much does a divorce cost?`,
    content: `<p>There are standard government court fees of £593 for submitting your divorce application and £53 for formalising your financial separation.  In some circumstances, it’s possible to apply to reduce these fees and if this applies to you, we’ll guide you through how to do it. </p> 

<p>On top of the court fees, the cost of getting a divorce varies hugely.  It depends on the type and amount of support you pay for. </p> 

<p>Lawyers charge by the hour and their rates are usually in the region of £250 - £800 per hour.  Research says the average cost of having a lawyer by your side throughout the process is over £10,000.  Mediation can be a more cost effective route as a couple share the cost of each session.  Other options span divorce coaches to online solicitors. </p> 

<p>Many people don’t take any one-to-one professional advice - either because it's not affordable or they think they don’t need it.</p>  

<p>Whichever route you find yourself on, using SeparateSpace will help you reduce stress and cost by giving you a much better understanding of how to navigate your divorce and reach agreements about your children, property and money.</p>
`,
  },
  {
    title: `Do I still need a lawyer?`,
    content: `<p>SeparateSpace is not a law firm and does not provide legal advice.</p>
	<p>Whilst you are not required to take legal advice when you divorce, there are important steps within the process that have legal consequences and it’s vital that you understand these.
SeparateSpace provides a personalised guide through divorce and empowers you with expert knowledge so you can make informed decisions.</p>
<p>One of your decisions will be whether to instruct a lawyer - and if so, who to instruct.  We can help with that.</p>
<p>It may be that you can’t afford a lawyer. And if that’s the case, using SeparateSpace will give you a much better understanding of how to navigate your divorce and reach agreements about your children, property and money.</p><p>You might have a modest budget for a lawyer. If so, using SeparateSpace will help your budget go further. SeparateSpace is designed to support you to do more of the process yourself and lean less on your lawyer. Using SeparateSpace alongside your lawyer will save you thousands of pounds in legal fees.</p>`,
  },
  {
    title: `How much access will I have to the SeparateSpace experts?`,
    content: `<p>SeparateSpace doesn't offer one-to-one support.</p>
	<p>Our platform is designed to empower you to make informed decisions. One of those decisions will be whether to instruct a professional to work with you on a one-to-one basis. 
</p>
<p>If you'd like one-to-one support, we can suggest to you a number of firms and professionals: from lawyers, mediators and financial advisors, to therapists and coaches.</p>
<p>SeparateSpace is not a law firm and is not regulated as such. It’s important that you understand that the support our members receive doesn’t qualify as legal advice and SeparateSpace doesn’t create any lawyer-client or therapist-patient relationships. For more about this, please check out our Terms & Conditions.</p>`,
  },
  {
    title: `Does my ex need to join SeparateSpace too?`,
    content: `<p>No.  We know that having your own space to think through, make plans and take stock, is really important when you’re dealing with a divorce.  That’s why we’ve designed SeparateSpace as a tool to be used by individuals.  We’ll help you to understand where you are in the process and how to get to the end.</p>`,
  },
];

export const Pricing = (): JSX.Element => {
  const [openIRM, setOpenIRM] = useState(false);

  return (
    <>
      <Helmet>
        <title>Our Pricing | SeparateSpace</title>
        <meta
          name="description"
          content="Explore SeparateSpace pricing plans. Affordable options to support you through your separation journey. Find the plan that fits your needs."
        />
      </Helmet>
      <section className={cn("section", s.pricing)}>
        <Htag tag="h1">Get the support you need</Htag>

        <PaymentComponent />
      </section>

      <section className={cn("section", s.faq)}>
        <Htag tag="h1">FAQ</Htag>
        <Accordion accordionData={pricingFaq} />
      </section>
      <IsItRight
        open={openIRM}
        close={() => {
          setOpenIRM(!openIRM);
        }}
      />
    </>
  );
};

export default Pricing;
