import { FormControl } from "@mui/material";
import { AddFiledIcon, Button } from "components";
import s from "./OptionsWidget.module.css";
import { OLitItem } from "./ListItem.component";
import { useState } from "react";

export const OptionList = ({
  list,
  setList,
}: {
  list: any[];
  setList: (list: any[]) => void;
}) => {
  const [newOption, setNewOption] = useState("");
  const addNewOption = () => {
    if (newOption) {
      setList([...list, newOption]);
      setNewOption("");
    }
  };

  const [editField, setEditField] = useState<number | null>(null);

  const handleNewoption = (e: any) => {
    setNewOption(e.target.value);
  };

  const handleRemove = (id: number) => {
    const listUpdate = list.filter((item, index) => id !== index);
    setList([...listUpdate]);
  };

  const handleUpdate = (id: number) => {
    setEditField(id);
    setNewOption(list[id]);
  };

  const saveOption = () => {
    const updatedArr = list.map((item, index) => {
      if (index == editField) return (item = newOption);
      return item;
    });

    setList(updatedArr);
    setEditField(null);
    setNewOption("");
  };

  const cancel = () => {
    setEditField(null);
    setNewOption("");
  };

  return (
    <>
      <div className={s.list}>
        {list.map((option, index) => (
          <OLitItem
            key={index}
            option={option}
            handleRemove={() => handleRemove(index)}
            handleUpdate={() => handleUpdate(index)}
          />
        ))}
      </div>

      <div className={s.addNewopt}>
        <FormControl fullWidth>
          <input
            id="newOption"
            name="option"
            onChange={handleNewoption}
            value={newOption}
            type="text"
            placeholder="New option"
          />
        </FormControl>

        {editField || editField == 0 ? (
          <div className={s.button_group}>
            <Button
              type="button"
              apearance="primary"
              onClick={saveOption}
              className={s.opt_btn}
            >
              Save
            </Button>
            <Button
              type="button"
              apearance="primary"
              onClick={cancel}
              className={s.opt_btn}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <Button
            type="button"
            apearance="primary"
            onClick={addNewOption}
            className={s.opt_btn}
          >
            Add new option <AddFiledIcon />
          </Button>
        )}
      </div>
    </>
  );
};
