import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import "suneditor/dist/css/suneditor.min.css";
import plugins from "suneditor/src/plugins";
import { EditorComponentProps } from "./Editor.props";
import { useRef } from "react";

export const EditorComponent = ({
  value,
  height = "400px",
  handleChange,
  ...props
}: EditorComponentProps): JSX.Element => {
  const editor = useRef<SunEditorCore>();
  return (
    <div {...props}>
      <SunEditor
        defaultValue={value}
        setContents={value}
        setDefaultStyle={`height: ${height};font-family: GT Walsheim; font-size: 22px;`}
        lang="en"
        setOptions={{
          plugins: plugins,
          font: ["GT Walsheim", "Canela Deck"],
          tagsBlacklist: "span",
          position: "static",
          popupDisplay: "local",
          buttonList: [
            [
              "undo",
              "redo",
              "fontSize",
              "font",
              "formatBlock",
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
              "fontColor",
              "hiliteColor",
              "removeFormat",
              "outdent",
              "indent",
              "align",
              "horizontalRule",
              "list",
              "table",
              "link",
              "image",
              "video",
              "fullScreen",
              "codeView",
            ],
          ],
        }}
        getSunEditorInstance={(sunEditor: SunEditorCore): void => {
          editor.current = sunEditor;
        }}
        onChange={(e) => {
          handleChange(e);
        }}
      />
    </div>
  );
};
