import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import {
  CANCEL_STRIPE,
  REFUNDS_LIST_STRIPE,
  REFUND_DELETE_STRIPE,
  REFUND_RELOAD_STRIPE,
  REFUND_REQUEST_STRIPE,
  RESUME_STRIPE,
} from "../config";
import { IRequstRefundListData } from "store/waitingList/waitingList.interface";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const pauseSubscription = createAsyncThunk<
  { success: string },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@stripe/pause",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      CANCEL_STRIPE(),
      rejectWithValue,
      dispatch
    );
  }
);

export const resumeSubscription = createAsyncThunk<
  { success: string },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@stripe/resume",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      RESUME_STRIPE(),
      rejectWithValue,
      dispatch
    );
  }
);

export const refundSubscriptionRequest = createAsyncThunk<
  { success: string },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@stripe/refund",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      REFUND_REQUEST_STRIPE(),
      rejectWithValue,
      dispatch
    );
  }
);

export const refundRequestList = createAsyncThunk<
  IRequstRefundListData,
  { page: number; limit: number },
  { getState: IInitState; rejectValue: string }
>(
  "@@stripe/refundList",
  async function (data, { getState, rejectWithValue, dispatch }) {
    const { page, limit } = data;

    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      REFUNDS_LIST_STRIPE(page, limit),
      rejectWithValue,
      dispatch
    );
  }
);

export const refundResponse = createAsyncThunk<
  { success: string },
  { id: string; customer: string },
  { getState: IInitState; rejectValue: string }
>(
  "@@stripe/refundresponse",
  async function ({ id, customer }, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      REFUND_RELOAD_STRIPE(id),
      rejectWithValue,
      dispatch,
      JSON.stringify({
        customer: customer,
      })
    );
  }
);

export const refundDelete = createAsyncThunk<
  { success: string },
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@stripe/refundDelete",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      REFUND_DELETE_STRIPE(id),
      rejectWithValue,
      dispatch
    );
  }
);
