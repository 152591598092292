import { Button, Card, Htag } from "../../../components";
import s from "./AdminAnalytics.module.css";
import ReactApexChart from "react-apexcharts";
import { useAppSelector } from "utils/hooks";
import { PERIOD, curve, curveXaxisType } from "./constants";
import {  useAnaliticDataPerPeriod, useLastActivitiesData } from 'utils/useAnalyticsData';
import { getBookedSessionProgress, getUsersDataAllDaysStatistic, getUsersDataStatistic } from 'store/analytics/analytics-thunk';


export const AdminAnalytics = (): JSX.Element => {
  // Custom hooks for data fetching and handling
  const  userDataStatistic = useAnaliticDataPerPeriod(
    PERIOD.DAYS_7,
    getUsersDataStatistic
  );
  const usersDataAllDaysStatistic = useAnaliticDataPerPeriod(
    PERIOD.DAYS_7,
    getUsersDataAllDaysStatistic
  );

    const bookedSessionStatistic = useAnaliticDataPerPeriod(
      PERIOD.DAYS_7,
      getBookedSessionProgress
    );

  useLastActivitiesData();

  const usersAnalyticsData = useAppSelector(
    (state) => state.analyticsDataState.userData
  );
  const bookingSessionProgress = useAppSelector(
    (state) => state.analyticsDataState.bookedSessions
  );
  const usersAnalyticsFromStartData = useAppSelector(
    (state) => state.analyticsDataState.userFromStartData
  );
  const usersActivitiesData = useAppSelector(
    (state) => state.analyticsDataState.activities
  );
  const usersActivitiesTotal = useAppSelector(
    (state) => state.analyticsDataState.activities.total
  );

  const usersActivitiesKeys = Object.keys(usersActivitiesData)
    .filter((item) => item.includes("last"))
    .map((item: string) => item.split("_").join(" "));
  const usersActivitiesValues = Object.values(usersActivitiesData).slice(0, 4);

  const usersActivitiesOpt = {
    series: [{ name: "Users", data: [...usersActivitiesValues] }],
    options: {
      chart: { height: 300 },
      colors: ["#A00F72", "#ff5722", "#cddc39"],
      plotOptions: {
        bar: { columnWidth: "65%", distributed: true, endingShape: "rounded" },
      },
      dataLabels: { enabled: false },
      legend: { show: false },
      xaxis: {
        categories: [...usersActivitiesKeys],
        labels: {
          style: {
            colors: ["#A00F72", "#ff5722", "#cddc39"],
            fontSize: "15px",
            fontWeight: 600,
          },
        },
      },
    },
  };

  const usersActivitiesTotalOpt = {
    series: [
      {
        name: "Users",
        data: usersActivitiesTotal ? [...usersActivitiesTotal.data] : [],
      },
    ],
    options: {
      chart: { height: 300 },
      colors: ["#A00F72", "#ff5722", "#cddc39", "#0288d1"],
      plotOptions: {
        bar: { columnWidth: "65%", distributed: true, endingShape: "rounded" },
      },
      dataLabels: { enabled: false },
      legend: { show: false },
      xaxis: {
        categories: usersActivitiesTotal
          ? [...usersActivitiesTotal.categories]
          : [],
        labels: {
          style: {
            colors: ["#A00F72", "#ff5722", "#cddc39", "#0288d1"],
            fontSize: "15px",
            fontWeight: 600,
          },
          rotate: 0,
          hideOverlappingLabels: false,
          trim: true,
        },
      },
    },
  };

  const users_data = {
    series: usersAnalyticsData.data,
    options: {
      chart: { height: 350 },
      dataLabels: { enabled: false },
      stroke: { curve: curve },
      xaxis: {
        type: curveXaxisType,
        categories: usersAnalyticsData.categories,
        tickPlacement: "on",
      },
      yaxis: { show: true, showForNullSeries: true, tickAmount: 5 },
      tooltip: { x: { format: "MM/dd/yyyy" } },
    },
  };

  const bookedSession_data = {
    series: bookingSessionProgress.data,
    options: {
      chart: { height: 350 },
      dataLabels: { enabled: false },
      stroke: { curve: curve },
      xaxis: {
        type: curveXaxisType,
        categories: bookingSessionProgress.categories,
        tickPlacement: "on",
      },
      yaxis: { show: true, showForNullSeries: true, tickAmount: 5 },
      tooltip: { x: { format: "MM/dd/yyyy" } },
    },
  };

  const users_data_fromStart = {
    series: usersAnalyticsFromStartData.data,
    options: {
      chart: { height: 350 },
      dataLabels: { enabled: false },
      stroke: { curve: curve },
      xaxis: {
        type: curveXaxisType,
        categories: usersAnalyticsFromStartData.categories,
        tickPlacement: "on",
      },
      yaxis: { show: true, showForNullSeries: true, tickAmount: 5 },
      tooltip: { x: { format: "MM/dd/yyyy" } },
    },
  };

  const period_arr = Object.values(PERIOD).slice(
    0,
    Object.keys(PERIOD).length / 2
  );

  return (
    <>
      <div className={s.analytics_members_charts}>
        <Card className={s.chart_item}>
          <Htag className={s.chart_title} tag="h4">
            Members counter: registration progress for all the time
          </Htag>
          <div className={s.chartVariety}>
            {(period_arr as (keyof typeof PERIOD)[]).map((enumKey, index) => (
              <Button
                key={index}
                apearance={
                  usersDataAllDaysStatistic.period === PERIOD[enumKey]
                    ? "secondary"
                    : "default"
                }
                onClick={() =>
                  usersDataAllDaysStatistic.updateData(PERIOD[enumKey])
                }
              >
                {enumKey.split("_").reverse().join(" ").toLowerCase()}
              </Button>
            ))}
          </div>
          <ReactApexChart
            type="area"
            options={users_data_fromStart.options}
            series={users_data_fromStart.series}
            height={350}
          />
        </Card>

        <Card className={s.chart_item}>
          <Htag className={s.chart_title} tag="h4">
            Members counter: individual data per date
          </Htag>
          <div className={s.chartVariety}>
            {(period_arr as (keyof typeof PERIOD)[]).map((enumKey, index) => (
              <Button
                key={index}
                apearance={
                  userDataStatistic.period === PERIOD[enumKey]
                    ? "secondary"
                    : "default"
                }
                onClick={() => userDataStatistic.updateData(PERIOD[enumKey])}
              >
                {enumKey.split("_").reverse().join(" ").toLowerCase()}
              </Button>
            ))}
          </div>
          <ReactApexChart
            type="area"
            options={users_data.options}
            series={users_data.series}
            height={350}
          />
        </Card>

        <Card className={s.chart_item}>
          <Htag className={s.chart_title} tag="h4">
            Booked Sessions
          </Htag>
          <div className={s.chartVariety}>
            {(period_arr as (keyof typeof PERIOD)[]).map((enumKey, index) => (
              <Button
                key={index}
                apearance={
                  bookedSessionStatistic.period === PERIOD[enumKey]
                    ? "secondary"
                    : "default"
                }
                onClick={() =>
                  bookedSessionStatistic.updateData(PERIOD[enumKey])
                }
              >
                {enumKey.split("_").reverse().join(" ").toLowerCase()}
              </Button>
            ))}
          </div>
          <ReactApexChart
            type="area"
            options={bookedSession_data.options}
            series={bookedSession_data.series}
            height={350}
          />
        </Card>

        <Card className={s.chart_item}>
          <Htag className={s.chart_title} tag="h4">
            Users Activities
          </Htag>

          <ReactApexChart
            options={usersActivitiesOpt.options}
            series={usersActivitiesOpt.series}
            type="bar"
            height={300}
          />
        </Card>

        <Card className={s.chart_item}>
          <Htag className={s.chart_title} tag="h4">
            Users Total Count
          </Htag>

          <ReactApexChart
            options={usersActivitiesTotalOpt.options}
            series={usersActivitiesTotalOpt.series}
            type="bar"
            height={300}
          />
        </Card>
      </div>
    </>
  );
};
