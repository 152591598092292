import {
  AddBlogPost,
  AddCaseStudies,
  AddPage,
  AddSources,
  AdminAnalytics,
  AdminDashboard,
  AllPages,
  BlogList,
  CaseStudyUser,
  EResourceArticleComponent,
  EditCSPage,
  EditChallenge,
  EditERPage,
  EditFeeling,
  EditPage,
  EditPost,
  EditSRPage,
  EditSTPage,
  EditSourcePage,
  EditWSSourcePage,
  ListCaseStudies,
  ListExtraResources,
  ListSourceWelbeing,
  ListSpecialCaseStudies,
  ListSystemResources,
  ListSystemTickets,
  PreviewTicket,
  PreviewWSource,
  QuestionnaireAnalytics,
  ResourceArticleComponent,
  SubscriptionList,
  WaitingList,
  WellbeingAdmin,
  WellbeingAdminList,
} from "pages";
import { RouteType } from "./config";
import { IconsPage } from "pages/Icons-page.component";
import DashboardPageLayout from "layout/DashboardPageLayout.component";
import { AddExtraResource } from "pages/AdminDashboard/ExtraResources/AddExtraSources.component";
import { TotalWidgetsView } from "widgets/totalWidgets/TotalWidgetsView.component";

export const adminSidebarRoutes: RouteType[] = [
  {
    element: <SubscriptionList />,
    state: "adminDashboard.index",
    path: "/admin-dashboard",
  },
  {
    path: "/icons",
    element: <IconsPage />,
    state: "adminIcons",
  },
  {
    element: <DashboardPageLayout />,
    state: "analytics",

    menuProps: {
      displayText: "Analytics",
    },
    child: [
      {
        element: <SubscriptionList />,
        path: "/admin-dashboard/users",
        state: "analytics.users",
        menuProps: {
          displayText: "All members",
        },
      },
      {
        element: <AdminAnalytics />,
        path: "/admin-dashboard/analytics",
        state: "analytics.index",
        menuProps: {
          displayText: "Analytics",
        },
      },
      {
        element: <QuestionnaireAnalytics />,
        path: "/admin-dashboard/analyticsQuestionnaires",
        state: "analytics",
        menuProps: {
          displayText: "Questionnares Data",
        },
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "adminSystemTickets",

    menuProps: {
      displayText: "System tickets",
    },
    child: [
      {
        element: <ListSystemTickets />,
        path: "/admin-dashboard/system-tickets",
        state: "adminSystemTickets.tickets",
        menuProps: {
          displayText: "System tickets",
        },
      },
      {
        element: <EditSTPage />,
        path: "admin-dashboard/system-tickets/edit-ticket/:id",
        state: "adminSystemTickets.tickets",
      },
      {
        element: <PreviewTicket />,
        path: "/admin-dashboard/system-tickets/edit-ticket/:ticketId/preview",
        state: "adminSystemTickets.tickets",
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "adminSystemSources",
    menuProps: {
      displayText: "Wellbeing sources",
    },
    child: [
      {
        element: <WellbeingAdminList />,
        path: "/admin-dashboard/wellbeing",
        state: "adminSystemSources.wellbeing",
        menuProps: {
          displayText: "Wellbeing emotions and challenges",
        },
      },

      {
        element: <WellbeingAdmin />,
        path: "/admin-dashboard/add-new-challenge/",
        state: "adminSystemSources.wellbeing",
        menuProps: {
          displayText: "Add new wellbeing challenge",
        },
      },

      {
        element: <ListSourceWelbeing />,
        path: "/admin-dashboard/wellbeingSources",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
        menuProps: {
          displayText: "Tools and resources lists",
        },
      },
      {
        element: <EditWSSourcePage />,
        path: "/admin-dashboard/wellbeingSources/sresource/edit-page/:id",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
      },
      {
        element: <EditSourcePage />,
        path: "/admin-dashboard/wellbeingSources/resource/edit-page/:id",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
      },
      {
        element: <EditFeeling />,
        path: "/admin-dashboard/wellbeing/feeling/edit-page/:id",
        state: "adminSystemSources.wellbeing",
      },
      {
        element: <EditChallenge />,
        path: "/admin-dashboard/wellbeing/challenge/edit-page/:id",
        state: "adminSystemSources.wellbeing",
      },
      {
        element: <EditSourcePage />,
        path: "/admin-dashboard/wellbeingSources/tool/edit-page/:id",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
      },
      {
        element: <PreviewWSource />,
        path: "/admin-dashboard/wellbeingSources/sresource/edit-page/:id/preview/",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
      },
      {
        element: <PreviewWSource />,
        path: "/admin-dashboard/wellbeingSources/resource/edit-page/:id/preview/",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
      },
      {
        element: <PreviewWSource />,
        path: "/admin-dashboard/wellbeingSources/tool/edit-page/:id/preview/",
        state: "adminSystemSources.wellbeing.listWellbeingSources",
      },
      {
        element: <AddSources />,
        path: "/admin-dashboard/add-wellbeing-tools-resources",
        state: "adminSystemSources.wellbeing.addEdit",
        menuProps: {
          displayText: "Add wellbeing sources",
        },
      },
    ],
  },

  {
    element: <DashboardPageLayout />,
    state: "spaceArticles",
    menuProps: {
      displayText: "Space Resources",
    },
    child: [
      {
        element: <ListSystemResources />,
        path: "/admin-dashboard/system-resources",
        state: "spaceArticles.resources",
        menuProps: {
          displayText: "System  resources",
        },
      },

      {
        element: <EditSRPage />,
        path: "/admin-dashboard/system-resources/edit-resource/:id",
        state: "spaceArticles.resources",
      },
      {
        element: <ResourceArticleComponent />,
        path: "/admin-dashboard/system-resources/edit-resource/:resourceId/preview",
        state: "spaceArticles.resources",
      },
      {
        element: <ListExtraResources />,
        path: "/admin-dashboard/extra-resources",
        state: "spaceArticles.extra-resources",
        menuProps: {
          displayText: "Extra resources",
        },
      },
      {
        element: <EditERPage />,
        path: "/admin-dashboard/extra-resources/edit-page/:resourceId",
        state: "spaceArticles.extra-resources",
      },
      {
        element: <AddExtraResource />,
        path: "/admin-dashboard/add-resources",
        state: "spaceArticles.extra-resources",
        menuProps: {
          displayText: "Add extra resources",
        },
      },
      {
        element: <EResourceArticleComponent />,
        path: "/admin-dashboard/extra-resources/edit-page/:resourceId/preview",
        state: "spaceArticles.resources",
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "blog",
    menuProps: {
      displayText: "Blog",
    },
    child: [
      {
        element: <BlogList />,
        path: "/admin-dashboard/blog",
        state: "blog",
        menuProps: {
          displayText: "All pages",
        },
      },
      {
        element: <EditPost />,
        path: "/admin-dashboard/blog/edit-page/:slug",
        state: "blog.edit-post",
      },
      {
        element: <AddBlogPost />,
        path: "/admin-dashboard/add-post",
        state: "blog.addNewPost",
        menuProps: {
          displayText: "Add new post",
        },
      },
    ],
  },

  {
    element: <DashboardPageLayout />,
    state: "caseStudies",
    menuProps: {
      displayText: "Case Studies",
    },
    child: [
      {
        element: <ListCaseStudies />,
        path: "/admin-dashboard/caseStudies/list",
        state: "caseStudies.list",
        menuProps: {
          displayText: "Case studies list",
        },
      },
      {
        element: <ListSpecialCaseStudies />,
        path: "/admin-dashboard/caseStudies/special_list",
        state: "caseStudies.special_list",
        menuProps: {
          displayText: "Special Case studies list",
        },
      },
      {
        element: <AddCaseStudies />,
        path: "/admin-dashboard/add-newCS",
        state: "caseStudies.new",
        menuProps: {
          displayText: "Add new case study",
        },
      },

      {
        element: <CaseStudyUser />,
        path: "/admin-dashboard/caseStudies/edit-page/:id/preview",
        state: "caseStudies.preview",
      },

      {
        element: <EditCSPage />,
        path: "/admin-dashboard/caseStudies/edit-page/:id",
        state: "caseStudies.edit-cs",
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "subscribers",
    menuProps: {
      displayText: "Waiting list",
    },
    child: [
      {
        element: <WaitingList />,
        path: "/admin-dashboard/waiting-list",
        state: "subscribers.index",
        menuProps: {
          displayText: "Waiting List",
        },
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "contentPages",
    menuProps: {
      displayText: "Content pages",
    },
    child: [
      {
        index: true,
        element: <AdminDashboard />,
        path: "/admin-dashboard/content-pages",
        state: "contentPages.index",
      },
      {
        path: "/admin-dashboard/all-pages",
        element: <AllPages />,
        state: "contentPages.allSimplePage",
        menuProps: {
          displayText: "All simple pages",
        },
      },
      {
        path: "/admin-dashboard/all-pages/edit-page/:id",
        element: <EditPage />,
        state: "contentPages.allSimplePage",
      },
      {
        path: "/admin-dashboard/add-page",
        element: <AddPage />,
        state: "contentPages.addPage",
        menuProps: {
          displayText: "Add new page",
        },
      },
      {
        path: "/admin-dashboard/widgets",
        element: <TotalWidgetsView />,
        state: "contentPages.widgets",
        menuProps: {
          displayText: "Widgets",
        },
      },
    ],
  },
];
