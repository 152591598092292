export interface IMeta {
  title: string;
  description: string;
}

export class PageTemplate {
  title: string;
  content: string;
  type: string;
  meta: IMeta;
  constructor(
    title = "",
    content = "",
    type = "default",
    meta = {
      title: "",
      description: "",
    }
  ) {
    this.title = title;
    this.content = content;
    this.type = type;
    this.meta = meta;
  }

  formData(values: {
    title: string;
    content: string;
    type: string;
    meta: IMeta;
  }) {
    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("content", values.content);
    formData.append("type", values.type);
    formData.append("meta[title]", values.meta.title);
    formData.append("meta[description]", values.meta.description);
    return formData;
  }
}

export class MarketingPage extends PageTemplate {
  image?: Blob | string;
  form_type: number | null;
  constructor(image = "", form_type = 1) {
    super();
    this.image = image;
    this.form_type = form_type;
  }
  formData(values: {
    title: string;
    content: string;
    type: string;
    image?: Blob | string;
    form_type?: number | null;
    meta: IMeta;
  }) {
    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("content", values.content);
    formData.append("type", values.type);
    formData.append("meta[title]", values.meta.title);
    formData.append("meta[description]", values.meta.description);

    if (values.form_type)
      formData.append("form_type", JSON.stringify(values.form_type));

    if (values.image) {
      formData.append("image", values.image);
    } else {
      formData.append("image", "");
    }
    return formData;
  }
}
