import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

import s from "./Search.module.css";
import cn from "classnames";
import { SearchIcon } from "..";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import { get_search_result } from "../../store/search/search-thunk";
import { useLocation, useNavigate } from "react-router-dom";
import { setSearchResult } from "../../store/search/search-reducer";
import { createPortal } from "react-dom";
import { Htag } from "components";
import { SearchResults } from 'pages/Dashboard/SearchResults/SearchResults.component';

export const Search = () => {
  const [searchRequest, setSearchRequest] = useState("");
  const [showInputField, setShowInputField] = useState(false);
  const navigate = useNavigate();
 const location = useLocation();
  /* const { search_results, search } = useAppSelector((state) => state.search); */

  const dispatch = useAppDispatch();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault();
    dispatch(get_search_result(searchRequest))
      .unwrap()
      .then(() => {
        const searchParam = searchRequest.replace(/[+()\s-]/g, "+");
        dispatch(setSearchResult(searchRequest));
        navigate(`/dashboard/search-results/?search=${searchParam}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (e: any) => {
    setSearchRequest(e.target.value);
  };

  return (
    <>
      <form
        onSubmit={onSubmit}
        className={cn(s.searchForm, {
          [s.showInput]: showInputField,
          [s.hideInput]: !showInputField,
        })}
      >
        <TextField
          fullWidth
          id="title"
          name="title"
          placeholder="Search"
          className={cn(s.searchField)}
          value={searchRequest}
          onChange={handleSearch}
          onKeyDown={(e) => (e.key === "Enter" ? onSubmit : "")}
        />
        <SearchIcon
          onClick={() => setShowInputField(!showInputField)}
          className={s.searchIcon}
        />
      </form>
    {/*   {createPortal(
        <div className={s.modalSearch}>
          <Htag tag="h2">Search</Htag>
          <form
            onSubmit={onSubmit}
            className={cn(s.searchForm, {
              [s.showInput]: showInputField,
              [s.hideInput]: !showInputField,
            })}
          >
            <div className={cn(s.searchForm)}>
              <TextField
                fullWidth
                id="title"
                name="title"
                placeholder="Search"
                className={cn(s.searchField)}
                value={searchRequest}
                onChange={handleSearch}
                onKeyDown={(e) => (e.key === "Enter" ? onSubmit : "")}
              />
              <SearchIcon
                onClick={() => setShowInputField(!showInputField)}
                className={s.searchIcon}
              />{" "}
            </div>
          </form>
          <SearchResults />
        </div>,
        document.body
      )} */}
    </>
  );
};
