import { forwardRef } from "react";
import s from "./StackItem.module.css";
import { StackItemProps } from "./StackItem.props";
import cn from "classnames";
import { Chip } from "@mui/material";
import { getAttachTypeName, getTypeArticle } from "utils/utils";
import { ClockIcon } from "components";

export const StackItemComponent = forwardRef<HTMLDivElement, StackItemProps>(
  (props: StackItemProps, ref) => {
    let type = undefined;
    if (typeof props.data.type == "string") {
      type = getTypeArticle(props.data.type);
    } else if (typeof props.data.type == "number") {
      type = getAttachTypeName(+props.data.type);
    }
    return (
      <div ref={ref} {...props} className={cn(s.stackItem, props.className)}>
        <p className="b1">{props.data.title}</p>
        {(type || props.data.reading_time) && (
          <div className={s.stack_meta}>
            {type && (
              <Chip
                label={type.toLowerCase()}
                variant="outlined"
                size="medium"
                className={s.mailChimp}
              />
            )}
            {props.data.reading_time !== "null" && (
              <p className={s.guide_meta_time}>
                <ClockIcon /> {props.data.reading_time}
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
);
