import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import s from "../SResources/Resource.module.css";

import { IExtraResource } from "store/extra_resources/extra_resource.interface";
import { get_single_eresource } from "store/extra_resources/extra_resource-thunk";
import { ArticleContent } from "components";
import { Page404 } from "pages/404/404.component";

export const EResourceArticleComponent = () => {
  const { resourceId } = useParams();

  const dispatch = useAppDispatch();

  const item: IExtraResource = useAppSelector((state) => {
    return state.extraResources.resources.filter(
      (resource: IExtraResource) => resource.id == Number(resourceId)
    )[0];
  });

  useEffect(() => {
    if (!resourceId) return;
    if (!item || !Object.prototype.hasOwnProperty.call(item, "content")) {
      dispatch(get_single_eresource(resourceId));
    }
  }, [dispatch]);

  return (
    <div className={s.article}>
      {item ? (
        <ArticleContent data={item} type="article"></ArticleContent>
      ) : (
        <Page404 className={s.componentNotFound} />
      )}
    </div>
  );
};
