import { FormControl } from "@mui/material";
import { useEffect, useRef } from "react";
import {
  Button,
  EditorComponent,
  ErrorField,
  Htag,
  InputImageComponent,
  Multyselect,
} from "components";
import s from "../SpecialSources.module.css";
import { NavLink, useParams } from "react-router-dom";
import st from "./Blog.module.css";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useFormik } from "formik";
import { validationExtraCreateSchema } from "./AddBlogPage.component";

import { get_single_post, updatePost } from "store/blog/blog-thunk";
import { categories } from "store/blog/blog.interfaces";
import { BlogPost } from "models/Article.model";
import InputTags from "components/Tags/Tags.component";
import {
  CustomSnackbar,
  CustomSnackbarRef,
} from "components/CustomSnackbar/CustomSnackbar.component";
import { SEOWidget } from "widgets/seo/seo.component";

export const EditPost = () => {
  const { slug } = useParams();
  const ref = useRef<CustomSnackbarRef>(null);
  const dispatch = useAppDispatch();

  const { error } = useAppSelector((state) => state.blogPostsState);

  const formik = useFormik({
    initialValues: new BlogPost(),
    validationSchema: validationExtraCreateSchema,
    onSubmit: (values) => {
      if (!slug) return;

      dispatch(
        updatePost({
          post_id: slug,
          data: {
            title: values.title,
            content: values.content,
            reading_time: values.reading_time,
            image: values.image,
            categories: values.categories,
            tags: values.tags || [],
            seo: values.seo,
          },
        })
      )
        .unwrap()
        .then(() => {
          ref.current?.refresh();
        });
    },
  });

  useEffect(() => {
    if (!slug) return;

    dispatch(get_single_post(slug))
      .unwrap()
      .then((resp) => {
        formik.setFieldValue("content", resp.content);
        formik.setFieldValue("title", resp.title);
        formik.setFieldValue("reading_time", resp.reading_time);
        formik.setFieldValue("tags", resp.tags);
        formik.setFieldValue("image", resp.image);
        formik.setFieldValue("categories", resp.categories);
      });
  }, []);

  return (
    <div className={st.formContainer}>
      <Htag tag="h3" className="mb15">
        Edit post: "{formik.values.title}"{" "}
      </Htag>

      <form onSubmit={formik.handleSubmit}>
        <div className={s.formField}>
          <Htag tag="h4">Title</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4">Reading time: </Htag>
          <FormControl fullWidth className={s.reading_time}>
            <input
              id="reading_time"
              name="reading_time"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.reading_time}
              onChange={formik.handleChange}
              placeholder="Reading time"
            />
            {formik.touched.reading_time && formik.errors.reading_time && (
              <ErrorField error={formik.errors.reading_time} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload preview image
          </Htag>
          <InputImageComponent
            handleFiles={formik.setFieldValue}
            handleOnBlur={formik.setFieldValue}
            file={formik.values.image}
            id="image"
            name="image"
          />
        </div>

        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            handleChange={(e) => formik.setFieldValue("content", e)}
            name="content"
          />

          {formik.touched.content && formik.errors.content && (
            <ErrorField error={formik.errors.content} />
          )}
        </div>
        <div className={st.selects}>
          <Htag tag="h3">Choose categories:</Htag>
          <Multyselect
            label="Categories"
            selectData={formik.values.categories}
            selectArray={categories}
            handleData={(data) => formik.setFieldValue("categories", data)}
          />
        </div>
        <div>
          <Htag tag="h3">Add tags:</Htag>

          <InputTags
            hashtag={formik.values.tags}
            handleTags={(data) => formik.setFieldValue("tags", data)}
          />
        </div>

        <SEOWidget
          seoData={formik.values.seo}
          handleChangeSeo={formik.handleChange}
          handleBlur={formik.handleBlur}
        />
        {error &&
          Array.isArray(error) &&
          error.map((item, index) => (
            <ErrorField
              key={index}
              error={`Server error: ${Object.values(item)[0]}`}
            />
          ))}
        {error && typeof error == "string" && <ErrorField error={error} />}

        <p className={s.previewLink}>
          <NavLink target="_blank" to={`/blog/${slug}/`}>
            Preview
          </NavLink>
        </p>

        <Button apearance="secondary" type="submit">
          Save
        </Button>
      </form>

      <CustomSnackbar
        message={`Blog Post ${formik.values.title} is updated!`}
        ref={ref}
      />
    </div>
  );
};
