import { Cancel } from "@mui/icons-material";
import { Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { TagsProps } from "./Tags.props";

interface TagProps {
  data: string;
  handleDelete: (data: string) => void;
}

const Tags = ({ data, handleDelete }: TagProps) => {
  return (
    <Box
      sx={{
        background: "var(--main-dark)",
        height: "100%",
        display: "flex",
        padding: "0.4rem",
        margin: "0 0.5rem 0 0",
        justifyContent: "center",
        alignContent: "center",
        color: "#ffffff",
      }}
    >
      <Stack direction="row" gap={1}>
        <Typography sx={{ color: "#ffffff" }}>{data}</Typography>
        <Cancel
          sx={{ cursor: "pointer" }}
          onClick={() => {
            handleDelete(data);
          }}
        />
      </Stack>
    </Box>
  );
};

export default function InputTags({ handleTags, hashtag = [] }: TagsProps) {
  const tagRef = useRef<any>();
  const [message, setMessage] = useState("");

  const handleDelete = (value: string) => {
    const newtags = hashtag.filter((val) => val !== value);
    handleTags(newtags);
  };

  const handleKeyDown = (event: any) => {
    if (!hashtag) {
      if (event.key === "Enter") {
        handleTags([tagRef.current.value]);
        setMessage("");
      }
    } else if (event.key === "Enter") {
      handleTags([...hashtag, tagRef.current.value]);
      setMessage("");
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div>
        <TextField
          onChange={(event) => setMessage(event.target.value)}
          onKeyDown={handleKeyDown}
          inputRef={tagRef}
          fullWidth
          variant="standard"
          size="small"
          sx={{
            margin: "1rem 0",
            "&:before": {
              borderBottom: "0px",
            },
            "&:after": {
              borderBottom: "0px",
            },
          }}
          margin="none"
          placeholder={
            !hashtag || (hashtag && hashtag.length < 5) ? "Enter tags" : ""
          }
          value={message}
        />
        {hashtag && (
          <Box sx={{ margin: "0 0.2rem 20px 0", display: "flex" }}>
            {hashtag.map((data, index) => {
              return (
                <Tags data={data} handleDelete={handleDelete} key={index} />
              );
            })}
          </Box>
        )}
      </div>
    </Box>
  );
}
