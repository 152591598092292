import { StarIcon } from "..";
import { RatingProps } from "./Rating.props";

export const Rating = ({ rate,  }: RatingProps): JSX.Element => {
  return (
    <div>
      {Array(rate)
        .fill("")
        .map((x, i) => (
          <StarIcon
            style={{ width: "23px", height: "23px"}}
            key={i}
          
          />
        ))}
    </div>
  );
};
