import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { setToken, setUsedData } from "store/userInfo/userInfo-reducer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Login } from "layout/auth/Login/Login.component";
import { FullModalStyled } from "components";
import { openLoginModal, openPassModal } from "store/login/login-reducer";
import { ForgotPass } from "layout/auth/ForgotPass/ForgotPass.component";
import { OnBoardingComponent } from "layout/auth/Registration/OnBoarding.component";
import { setRegModalStatus } from "store/onBoarding/onboarding-reducer";

const ProtectedRoleRoute = ({
  isAllowed,
  redirectPath = "/landing",
  children,
}: {
  isAllowed: any;
  redirectPath?: string;
  children?: JSX.Element;
}): JSX.Element => {
  const userToken = useAppSelector((state) => state.userState.token);
  const userRole = useAppSelector((state) => state.userState.user.role);

  const [ApiError, setApiError] = useState("");
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const loginModalStatus = useAppSelector((state) => {
    return state.onLogin.openLoginModal;
  });
  const passwordModalStatus = useAppSelector((state) => {
    return state.onLogin.openPassModal;
  });
  const signupModalStatus = useAppSelector((state) => {
    return state.onRegistration.openModal;
  });

  useEffect(() => {
    if (userToken && !userRole) {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/is-user-logged`,
          {},
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + userToken,
            },
          }
        )
        .then((res) => {
          dispatch(setUsedData(res.data.user));
        })
        .catch((error) => {
          setApiError(error.response.data.message);
        });
    }
  }, [userRole, userToken]);

  const handleClose = () => {
    dispatch(openLoginModal(false));
    navigate("");
  };

  const handleClosePass = () => {
    dispatch(openPassModal(false));
  };

  const handleCloseReg = () => {
    dispatch(setRegModalStatus(false));
  };

  if (!isAllowed) {

    if (passwordModalStatus) {
      return (
        <FullModalStyled
          open={passwordModalStatus}
          close={handleClosePass}
          color="light"
        >
          <ForgotPass />
        </FullModalStyled>
      );
    } else if (signupModalStatus) {
      return (
        <FullModalStyled
          open={signupModalStatus}
          close={handleCloseReg}
          color="light"
        >
          <OnBoardingComponent />
        </FullModalStyled>
      );
    }
    return (
      <FullModalStyled
        open={true}
        close={handleClose}
        color="light"
      >
        <Login />
      </FullModalStyled>
    );
  }

  return children ? children : <Outlet />;
};
export default ProtectedRoleRoute;
