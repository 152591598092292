import {
  Box,
  Drawer,
  InternalStandardProps,
  List,
  ModalProps,
  Stack,
  SwipeableDrawer,
  Toolbar,
} from "@mui/material";
import useScreenType from "react-screentype-hook";
import { LogoText, SidebarCollapseIcon, UserInfo } from "components";


import s from "./Sidebar.module.css";
import SidebarItem from "./SidebarItem";

import cn from "classnames";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import SidebarItemCollapse from './SidebarItemCollapse';
import { adminSidebarRoutes } from 'routes/admins-routs';

const drawerWidth = 310;
interface SidebarProps
  extends InternalStandardProps<ModalProps, "open" | "children"> {
  window?: () => Window;
  mobileopen: boolean;
  closenav: () => void;
}

const Sidebar = ({ mobileopen, closenav, className }: SidebarProps) => {

  const screenType = useScreenType();
  const [state, setState] = useState(false);


  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  useEffect(() => {
    if (screenType.isMobile) {
      setSidebarCollapsed(false);
    }
  }, [screenType.isMobile]);

  const drawer = (
    <div>
      <List disablePadding>
        <Toolbar>
          <Stack
            direction="row"
            className={s.sidebarTop}
            justifyContent="center"
          >
            <NavLink to="/">
              <LogoText />
            </NavLink>
            <SidebarCollapseIcon
              onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
              className={s.sidebarCollapsedIconSvg}
            />
      
          </Stack>
        </Toolbar>
        {!sidebarCollapsed && (
          <div className={s.dashboardNav}>
            {adminSidebarRoutes.map((route, index) =>
              route.menuProps ? (
                route.child ? (
                  <SidebarItemCollapse item={route} key={index} />
                ) : (
                  <SidebarItem item={route} key={index} />
                )
              ) : null
            )}
          </div>
        )}
      </List>
      {!sidebarCollapsed && <UserInfo />}
    </div>
  );

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  useEffect(() => {
    setState(mobileopen);
  }, [mobileopen]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState(open);
    };
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
      onKeyDown={toggleDrawer(mobileopen)}
      role="presentation"
      onClick={toggleDrawer(mobileopen)}
      className={s.sidebar}
    >
      <SwipeableDrawer
        anchor="left"
        sx={{
          height: { xs: "100%" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,

            borderRight: "0px",
            backgroundColor: "#102D3E",
            color: "#C9EBF2",
          },
        }}
        open={mobileopen}
        onClose={closenav}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        onOpen={toggleDrawer(mobileopen)}
        className={cn(s.sidebarWrap, s.mobileNav, className)}
      >
        {drawer}
      </SwipeableDrawer>

      <Drawer
        variant="permanent"
        className={cn(s.sidebarWrap, className, {
          [s.sidebarCollapsed]: sidebarCollapsed,
        })}
        sx={{
          display: { xs: "none", sm: "block" },
          height: { xs: "100%" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,

            borderRight: "0px",
            backgroundColor: "#102D3E",
            color: "#C9EBF2",
            position: "relative",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
