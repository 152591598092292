import { Space } from "./spaces-interface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import { ALL_SPACES, GET_CHILDREN_SPACE } from "../config";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const loadSpaces = createAsyncThunk<
  Space[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@spaces/loadSpaces",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      ALL_SPACES(),
      rejectWithValue,
      dispatch
    );
  }
);

export const loadSingleSpace = createAsyncThunk<
  Space,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@spaces/loadSingleSpaces",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_CHILDREN_SPACE(),
      rejectWithValue,
      dispatch
    );
  }
);

export const getSpace = (state: Space[], id: number) => {
  return state.filter((stateItem: Space) => stateItem.id == id)[0];
};
