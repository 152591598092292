import s from "./Wellbeing.module.css";
import cn from "classnames";
import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { loadFeellings } from "store/challenges/challenges-thunk";
import { Feelling } from "store/challenges/challenges.interface";
import { Button, Htag, ModalStyled } from "components";
import axios from "axios";
import { SET_FIRST_ACCESS } from "store/config";
import { setVisibilityFT } from "store/challenges/challenges-reducer";

export const WellBeingFeelling = () => {
  const graph = useRef<HTMLDivElement>(null);
  const href = window.location.pathname;
  const dispatch = useAppDispatch();
  const feellings = useAppSelector(
    (state) => state.wellbeing.feellings.feelings
  );
  const userToken = useAppSelector((state) => state.userState.token);
  const visibilityFT = useAppSelector(
    (state) => state.wellbeing.feellings.visibleFirstTime
  );

  const handleClose = () => {
    axios
      .put(
        SET_FIRST_ACCESS(),
        { visibleFirstTime: true },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + userToken,
          },
          responseType: "json",
        }
      )
      .then(() => {
        dispatch(setVisibilityFT(true));
      });
  };
  useEffect(() => {
    dispatch(loadFeellings());
  }, [dispatch]);

  useEffect(() => {
    const ciclegraph = graph.current;
    if (!ciclegraph) return;
    const circleElements = ciclegraph.childNodes as NodeListOf<HTMLElement>;

    let angle = 360 - 150;
    const dangle = 360 / circleElements.length;

    for (let i = 0; i < circleElements.length; i++) {
      const circle = circleElements[i];
      angle += dangle;
      circle.style.transform = `rotate(${angle}deg) translate(${
        ciclegraph.clientWidth / 2.5
      }px) rotate(-${angle}deg)`;
    }
  }, [feellings]);

  return (
    <div className={s.wellbeingWrap}>
      <div className={s.wellbeing_explain_text}>
        <p>
          Being aware of our feelings and emotions can give us space to think
          about solutions.
        </p>
      </div>
      <div className={s.ciclegraph} ref={graph}>
        {feellings.map((feelling: Feelling) => (
          <NavLink
            to={`${href}/${feelling.id}`}
            key={feelling.id}
            className={cn(`${s[feelling.title.toLocaleLowerCase()]}`, s.circle)}
          >
            {feelling.title}
          </NavLink>
        ))}
      </div>
      <ModalStyled open={!visibilityFT} close={handleClose} size="l">
        <div className={cn(s.informText, s.challengeModal)}>
          <Htag tag="h3">
            Whether it's your own choice or it's been thrust upon you, divorce
            is hard.
          </Htag>
          <p>
            You might be feeling all sorts of emotions day to day, hour to hour.
            These are some of the emotions that people can feel as they move
            through the process. Do any of these emotions resonate with you
            today? If so, click through to find some resources that might be
            helpful. <br />
            They may not, and that's fine. If it feels right, you could explore
            some of the common challenges people navigate as they separate.
          </p>
          <Button
            apearance="secondary"
            onClick={handleClose}
            className={s.button}
          >
            Explore emotions
          </Button>
        </div>
      </ModalStyled>
    </div>
  );
};
