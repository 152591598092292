import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { MultyselectProps } from "./MultySelect.props";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const Multyselect = ({
  label,
  selectData,
  selectArray,
  handleData,
}: MultyselectProps) => {
  const handleMultySelect = (event: SelectChangeEvent<typeof selectData>) => {
    const {
      target: { value },
    } = event;

    handleData(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          labelId={label}
          id={`set${label}`}
          multiple
          renderValue={(selected) => {
            return selected
              .map((item) => {
                if (typeof item == "string") {
                  return item ? item : "";
                } else {
                  const obj = selectArray.find(({ id }) => id == Number(item));
                  return obj ? obj.title : "";
                }
              })
              .join(", ");
          }}
          value={selectData}
          label={label}
          onChange={handleMultySelect}
          MenuProps={MenuProps}
        >
          {selectArray.map((item, index) =>
            typeof item == "string" ? (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ) : (
              <MenuItem key={item.id} value={item.id}>
                {item.title}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </>
  );
};
