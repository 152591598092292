import { ButtonProps } from "./Button.props";
import s from "./Button.module.css";
import cn from "classnames";

export const Button = ({
  children,
  apearance,
  className,
  size,
  disabled,
  align,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <button
      className={cn(s.btn, className, {
        [s.primary]: apearance === "primary",
        [s.primaryLight]: apearance === "primary-light",
        [s.secondary]: apearance === "secondary",
        [s.warning]: apearance === "warning",
        [s.broun]: apearance === "broun",
        [s.error]: apearance === "error",
        [s.dark]: apearance === "dark",
        [s.white]: apearance === "white",
        [s.default]: apearance === "default",
        [s.disable]: apearance === "disable" || disabled,
        [s.secondaryDark]: apearance === "secondaryDark",
        [s.big]: size === "L",
        [s.middle]: size === "M",
        [s.center]: align === "center",
      })}
      {...props}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
