import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import {
  get_system_single_ticket,
  get_system_tickets,
  update_system_ticket,
} from "./system-ticket-thunk";
import { ISystemTicket, ISystemTickets } from "./system_ticket.interface";

const initialState: ISystemTickets = {
  tickets: [],
  tickets_db: [],
  error: "",
  loading: false,
};

const specialTicketsState = createSlice({
  name: "@@systemTickets",
  initialState,
  reducers: {
    addId: (
      state: ISystemTickets,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.tickets.push(action.payload);
      } else {
        const toRemove = new Set(action.payload);
        state.tickets = state.tickets.filter((x) => !toRemove.has(x)) || [];
        state.tickets = state.tickets.concat(action.payload);
      }
    },
    removeId: (
      state: ISystemTickets,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.tickets = state.tickets.filter((item) => item !== action.payload);
      } else {
        const toRemove = new Set(action.payload);

        state.tickets = state.tickets.filter((x) => !toRemove.has(x)) || [];
      }
    },
    resetTicketsIds: (state) => {
      state.tickets = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initialState;
      })

      .addCase(
        get_system_tickets.fulfilled,
        (state: ISystemTickets, action: PayloadAction<ISystemTicket[]>) => {
          state.tickets_db = action.payload;
        }
      )
      .addCase(
        get_system_single_ticket.fulfilled,
        (state: ISystemTickets, action) => {
			
          state.tickets_db.push(action.payload);
        }
      )
      .addCase(
        update_system_ticket.fulfilled,
        (state: ISystemTickets, action) => {
     
          state.tickets_db = state.tickets_db.map((ticket) => {
            if (ticket.id == action.payload.special_ticket.id) {
              return action.payload.special_ticket;
            }
            return ticket;
          });
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          if (action.payload) {
            state.error = action.payload;
          } else if (action.error) {
            state.error = `Server error:  ${action.error.message}`;
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = "";
          state.loading = false;
        }
      );
  },
});

export const { addId, removeId, resetTicketsIds } = specialTicketsState.actions;

export default specialTicketsState.reducer;
