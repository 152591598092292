import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import {
  deleteChallenge,
  loadChallenges,
  loadFeellings,
} from "../../../store/challenges/challenges-thunk";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import { Button, Htag } from "../../../components";
import s from "./WellbeingAdmin.module.css";
import cn from 'classnames';

export const WellbeingAdminList = () => {
  const chalenges = useAppSelector(
    (state) => state.wellbeing.challenges.challenges
  );
  const feeling = useAppSelector((state) => state.wellbeing.feellings.feelings);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(loadChallenges());
    dispatch(loadFeellings());
  }, [dispatch]);

  const deleteFunc = (id: string | number) => {
    if (!id) return;

    dispatch(deleteChallenge(Number(id)))
      .unwrap()
      .then(() => {
        return;
      });
  };
  const handleClose = () => {
    setOpenDeleteDialog(false);
    setDeleteId(undefined);
  };

  const confirmDelete = () => {
    if (!deleteId) return;
    deleteFunc(deleteId);
    setOpenDeleteDialog(false);
    setDeleteId(undefined);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: s.headerClassName,
      width: 70,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerClassName: s.headerClassName,
      headerName: "Title",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: `Action`,
      headerClassName: s.headerClassName,
      sortable: false,
      width: 110,
      disableColumnMenu: true,
      renderCell: (params) => {
        const data = params;

        return (
          <>
            <NavLink to={`${pathname}/challenge/edit-page/${params.row.id}`}>
              <IconButton aria-label="edit" color="info">
                <Edit />
              </IconButton>
            </NavLink>
            <IconButton
              aria-label="delete"
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                setOpenDeleteDialog(true);
                setDeleteId(Number(data.id));
              }}
            >
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];

  const feelingColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: s.headerClassName,
      width: 70,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerClassName: s.headerClassName,
      headerName: "Title",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: s.headerClassName,
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <NavLink to={`${pathname}/feeling/edit-page/${params.row.id}`}>
              <IconButton aria-label="edit" color="info">
                <Edit />
              </IconButton>
            </NavLink>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Htag tag="h3" className='mb15'>List of challenges</Htag>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={chalenges}
          columns={columns}
          className={s.tableWrap}
          pageSizeOptions={[15, 30, 50, 70, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
        />
      </Box>

      <Htag tag="h3" className={cn(s.table_title, 'mb15')}>
        List of feelings
      </Htag>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={feeling}
          className={s.tableWrap}
          columns={feelingColumn}
          pageSizeOptions={[15, 30, 50, 70, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
        />
      </Box>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={s.deleteConfirmDialog}
      >
        <DialogContent className={s.deleteConfirmText}>
          Please, confirm deleting
        </DialogContent>
        <DialogActions className={s.deleteConfirmBtn}>
          <Button apearance="secondaryDark" onClick={handleClose}>
            NO
          </Button>
          <Button apearance="secondaryDark" onClick={confirmDelete}>
            AGREE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
