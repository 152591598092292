import {
  Alert,
  FormControl,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";

import { useEffect, useState } from "react";
import {
  Button,
  DropDownArrowIcon,
  EditorComponent,
  ErrorField,
  Htag,
  InputFileComponent,
} from "components";
import s from "../SpecialSources.module.css";
import { NavLink, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import {
  get_system_single_ticket,
  update_system_ticket,
} from "store/system_tickets/system-ticket-thunk";
import { useFormik } from "formik";
import { validationCreateSchemaTicket } from "../validation";
import {
  FileProps,
  IAttach,
} from "store/system_resources/system_resource.interface";
import { milestones } from "store/milestones/milestones-interface";
import InputTags from "components/Tags/Tags.component";
import { ISystemTicket } from "../../../store/system_tickets/system_ticket.interface";

export const EditSTPage = () => {
  const { id } = useParams<{ id: string }>();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const dispatch = useAppDispatch();

  const [currentAttachments, setAttachments] = useState<Partial<IAttach>[]>([]);
  const [attachmentsOld, setOldAttachments] = useState<Partial<FileProps>[]>(
    []
  );

  const system_ticket = useAppSelector((state) =>
    state.specialTicketsState.tickets_db.filter(
      (ticket) => ticket.id == Number(id)
    )
  )[0];

  const { error } = useAppSelector((state) => state.specialTicketsState);
  const initState: {
    title: string;
    description: string;
    reading_time: string;
    milestone_id: number;
    attachments: any[];
    unique_number: string;
    guides: any[];
  } = {
    title: "",
    description: "",
    reading_time: "",
    milestone_id: 1,
    attachments: [],
    unique_number: "",
    guides: [],
  };
  const formik = useFormik({
    initialValues: initState,
    validationSchema: validationCreateSchemaTicket,
    onSubmit: async (values) => {
      if (!id || !system_ticket) return;
      const updatedValue = values;

      try {
        await dispatch(
          update_system_ticket({ id, data: updatedValue })
        ).unwrap();
        setOpenSnackbar(true);
      } catch (error) {
        console.error("Error updating ticket:", error);
      }
    },
  });

  useEffect(() => {
    if (!id) return;
    const fetchTicket = async () => {
      try {
        const ticket =
          system_ticket && system_ticket.description
            ? system_ticket
            : await dispatch(get_system_single_ticket(Number(id))).unwrap();
        if (!ticket) return;

        setOldAttachments(ticket.attachments);

        formik.setValues({
          title: ticket.title,
          description: ticket.description || "",
          reading_time: ticket.reading_time || "",
          milestone_id: ticket.milestone_id || 1,
          unique_number: ticket.unique_number,
          attachments: [],
          guides: mapGuides(ticket.guides),
        });
      } catch (error) {
        console.error("Error fetching ticket:", error);
      }
    };

    fetchTicket();
  }, [id, system_ticket]);

  const mapGuides = (obj: any): string[] => {
    const transformedArr = Object.keys(obj);
    return transformedArr;
  };

  const handleAtachments = (attachments: FileProps | IAttach) => {
    setAttachments((old) => [...old, attachments]);

    formik.setFieldValue("attachments", [...currentAttachments, attachments]);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <>
      <div className={s.post_header}>
        <Htag tag="h2" className="mb25">
          Edit ticket: "{formik.values.title}"{" "}
        </Htag>
        <Htag tag="h4">Unique number: {formik.values.unique_number} </Htag>
      </div>

      <form onSubmit={formik.handleSubmit} className={s.formsWrap}>
        <div className={s.formField}>
          <Htag tag="h4">Title</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              type="text"
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4">Reading time: </Htag>
          <FormControl fullWidth className={s.reading_time}>
            <input
              id="reading_time"
              name="reading_time"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.reading_time}
              onChange={formik.handleChange}
              placeholder="Reading time"
            />
            {formik.touched.reading_time && formik.errors.reading_time && (
              <ErrorField error={formik.errors.reading_time} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload template
          </Htag>

          <InputFileComponent
            files={attachmentsOld}
            handleFiles={handleAtachments}
            id="EdditRP"
            type="ticket"
          />
        </div>
        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.description}
            name="description"
            handleChange={(e) => formik.setFieldValue("description", e)}
          />

          {formik.touched.description && formik.errors.description && (
            <ErrorField error={formik.errors.description} />
          )}
        </div>
        {system_ticket?.space_id == 1 && (
          <TextField
            variant="outlined"
            name="milestone_id"
            id="milestone_id"
            fullWidth
            select
            className={s.selectStatus}
            label={formik.values.milestone_id === null ? "Task is…" : ""}
            defaultValue={
              formik.values.milestone_id === null
                ? ""
                : formik.values.milestone_id
            }
            InputLabelProps={{ shrink: false, disableAnimation: true }}
            value={
              formik.values.milestone_id === null
                ? ""
                : formik.values.milestone_id
            }
            onChange={formik.handleChange}
            sx={{
              "& .MuiSelect-iconOpen": { transform: "rotate(90deg)" },
              "& .MuiSelect-icon": { top: "16px" },
              "& .Mui-focused": {
                color: "#102D3E",
              },
              "& .Mui-focused.MuiOutlinedInput-root": {
                border: "2px solid #91CCD1;",
              },
            }}
            SelectProps={{
              IconComponent: (props) => <DropDownArrowIcon {...props} />,
            }}
          >
            {milestones.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        {error &&
          Array.isArray(error) &&
          error.map((item, index) => (
            <ErrorField
              key={index}
              error={`Server error: ${Object.values(item)[0]}`}
            />
          ))}
        {error && typeof error == "string" && <ErrorField error={error} />}
        <div className={s.guides}>
          <Htag tag="h3">Add guides:</Htag>

          <InputTags
            hashtag={formik.values.guides}
            handleTags={(data) => formik.setFieldValue("guides", data)}
          />
        </div>
        <p className={s.previewLink}>
          <NavLink
            target="_blank"
            to={`/admin-dashboard/system-tickets/edit-ticket/${id}/preview`}
          >
            Preview
          </NavLink>
        </p>
        <Button type="submit" apearance="secondary">
          Save
        </Button>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          System task: "{formik.values.title}" is updated!
        </Alert>
      </Snackbar>
    </>
  );
};
