import { ReactNode } from "react";

import { Route } from "react-router-dom";

import PageWrapper from "../layout/PageWrapper.component";
import { RouteType } from "./config";

import { Space } from "../store/spaces/spaces-interface";


export const generateRoute = (
  routes: RouteType[],
  spaces: Space[]
): ReactNode => {


  return routes.map((route, index) =>
    route.index ? (
      <Route
        index
        path={route.path}
        element={<PageWrapper state={route.state}>{route.element}</PageWrapper>}
        key={index}
      />
    ) : (
      <Route
        path={route.path}
        loader={route.loader}
        element={
          <PageWrapper
            state={route.child ? undefined : route.state}
            name={route.path}
          >
            {route.element}
          </PageWrapper>
        }
        key={index}
      >
        {route.child && generateRoute(route.child, spaces)}
      </Route>
    )
  );
};
