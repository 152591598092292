import { Card, ClockIcon, NoImgIcon } from "components";
import { ICaseStudies } from "store/caseStudy/caseStudy.interface";
import { BookmarkItem } from "store/bookmarks/bookmark-interface";

import s from "./CaseStudies.module.css";
import { Chip, List, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

type csArticleType = ICaseStudies | BookmarkItem;

export const CaseStudyList = ({
  data,
}: {
  data: (ICaseStudies | BookmarkItem)[];
}) => {
  const navigate = useNavigate();

  const getRightId = (item: csArticleType) => {
    return item.resource_id ? item.resource_id : item.id;
  };

  return (
    <>
      <div className={s.caseStudyWrap}>
        {data.length > 0 ? (
          <List className={s.cs_list}>
            {data.map((cs: ICaseStudies | BookmarkItem) => (
              <ListItem key={`${cs.id}${Math.random()}`} className={s.listItem}>
                <Card
                  type="resource_card"
                  onClick={() =>
                    navigate(`/dashboard/case_studies/${getRightId(cs)}`)
                  }
                >
                  <div className={s.resource_preview}>
                    {cs.image && typeof cs.image == "string" ? (
                      <img src={cs.image} alt="" />
                    ) : (
                      <NoImgIcon />
                    )}
                  </div>
                  <div className={s.resource_meta}>
                    <p> {cs.title}</p>
                    <div className={s.resource_meta_extra}>
                      <Chip
                        label="Case Study"
                        variant="outlined"
                        size="medium"
                        className={s.chip}
                      />
                      {cs.reading_time && (
                        <span className={s.resource_meta_readingTime}>
                          <ClockIcon /> {cs.reading_time}
                        </span>
                      )}
                    </div>
                  </div>
                </Card>
              </ListItem>
            ))}
          </List>
        ) : (
          <p>
            When you go into your Children Space for the first time, there are a
            few questions to answer so we can give you the most helpful
            resources to support your children. We also use your answers to
            populate your Options Explorer with case studies that we think may
            be of interest to you.
          </p>
        )}
      </div>
    </>
  );
};
