import { createAsyncThunk } from "@reduxjs/toolkit";
import { SEND_EBOOKING } from "store/config";
import { IInitState } from "store/root-interface";
import { API_TYPE, getRequestFabric } from "../thunk-helper";

export const sendTriggerByBooking = createAsyncThunk<
  { success: string },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@coaching/triggerAfterBooking",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      SEND_EBOOKING(),
      rejectWithValue,
      dispatch
    );
  }
);
