import { useEffect, useState } from "react";
import { Htag, StepCircle, Notification } from "components";
import { useAppDispatch } from "utils/hooks";
import { loadMilestones } from "store/milestones/milestones-thunk";
import { Milestone } from "store/milestones/milestones-interface";
import s from "./PathWay.module.css";
import { NavLink } from "react-router-dom";
import cn from "classnames";

const PathWayComponent = () => {
  const dispatch = useAppDispatch();
  const [milestones, setMilestones] = useState<Milestone[]>([]);

  useEffect(() => {
    dispatch(loadMilestones())
      .unwrap()
      .then((res: Milestone[]) => {
        setMilestones(res);
      });
  }, []);
  return (
    <div>
      <Htag tag="h1" className='mb45'>Your divorce pathway</Htag>
      <Notification name={`Pathway`}>
        Here’s your personalised pathway. It’s been created for you and reflects
        your circumstances. Within each stage you’ll see actions to take. We’ll
        give you templates to use and guides to help you. You can also a look at
        real examples to help you consider your options. Visit your Children and
        Wellbeing spaces at any point in your journey.
      </Notification>
      <ul className={s.pathwayList}>
        {milestones.map((item) => (
          <li
            className={cn(s.pathwayList_item, {
              [s.finishedItem]: item.complete_percent == 100,
            })}
          >
            <StepCircle
              className={cn(s.pathwayList_item_step, {
                [s.finishedCircle]: item.complete_percent == 100,
              })}
              size="M"
              backgroundColor="var(--third-logo-color)"
            >
              {item.id}
            </StepCircle>
            <Htag tag="h3">
              <NavLink
                className="hover-underline-animation_back"
                to={`/dashboard/milestone/${item.id}`}
              >
                {item.title && item.title}
              </NavLink>
            </Htag>
            {item.tickets.length > 0 && (
              <ul className={s.nestedList}>
                {item.tickets
                  .filter((ticket) => ticket.title)
                  .map((ticket) => (
                    <li>{ticket.title}</li>
                  ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PathWayComponent;
