import s from "../Questionnaire.module.css";

import { Button, Htag } from "components";
import { QuestionnaireProps } from "../Questionnaire.props";

export const FirstScreenChildren = ({
  handleBack,
  setData,
  handleNextStep,
}: QuestionnaireProps) => {
  return (
    <div className={s.firstSlide}>
      <Htag tag="h3">Welcome to your Children Space. </Htag>
      <p>
        We know that your children will be at the forefront of your mind at the
        moment. Research tells us that children can suffer when there is
        significant, long-term unresolved conflict between their parents. On the
        other hand, when managed well, children show remarkable resilience and
        adaptability during times of change, including when their family shape
        and circumstances change.
      </p>
      <p>
        Our Children Space has been created by a team of highly skilled legal,
        mental health and educational experts with the aim of guiding you
        through the steps you need to support your children in the best possible
        way through this family transition.
      </p>

      <p>
        To make this space as relevant as possible to you and your family, we
        have a few questions for you. It should take you around 5 minutes to
        work through these questions. Do you want to answer them now?
      </p>

      <div className={s.groupButton}>
        <Button
          onClick={() => handleNextStep()}
          apearance="secondary"
          className={s.dark}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};
