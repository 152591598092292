import { useState } from "react";
import { AccordionProps } from "./Accordion.props";
import styles from "./Accordion.module.css";
import { DropDownArrowIcon } from "..";
import ReactHtmlParser from "html-react-parser";
export const Accordion = ({
  accordionData = [],
  className,
  ...props
}: AccordionProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggle = (index: number | null): void => {
    setActiveIndex((prev) => (prev === index ? null : index));
  };

  return (
	
    <div className={`${styles.accordionSection} ${className}`} {...props}>
      {accordionData.map((item, index) => (
        <div key={index} className={styles.accordionItemContainer}>
          <div
            className={`${styles.accordionTitle} ${
              activeIndex === index && styles.active
            }`}
            onClick={() => toggle(index)}
          >
            <h5>{item.title}</h5>
            {activeIndex === index ? (
              <DropDownArrowIcon className={styles.down} />
            ) : (
              <DropDownArrowIcon />
            )}
          </div>
          <div
            className={`${styles.dropdown} ${
              activeIndex === index && styles.show
            }`}
          >
          {ReactHtmlParser(item.content)}</div>
        </div>
      ))}
    </div>
  );
};
