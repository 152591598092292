export const faq = [
  {
    title: "Do I still need a lawyer?",
    content: `<p>SeparateSpace is not a law firm and does not provide legal advice.  
Whilst you are not required to take legal advice when you divorce, there are important steps within the process that have legal consequences and it’s vital that you understand these.
SeparateSpace provides a personalised guide through divorce and empowers you with expert knowledge so you can make informed decisions.
</p>

<p>One of your decisions will be whether to instruct a lawyer - and if so, who to instruct.  We can help with that.</p>

<p>It may be that you can’t afford a lawyer. And if that’s the case, using SeparateSpace will give you a much better understanding of how to navigate your divorce and reach agreements about your children, property and money.</p>

<p>You might have a modest budget for a lawyer. If so, using SeparateSpace will help your budget go further. SeparateSpace is designed to support you to do more of the process yourself and lean less on your lawyer. Using SeparateSpace alongside your lawyer will save you thousands of pounds in legal fees.
</p>`,
  },
  {
    title: "How much does SeparateSpace cost?",
    content: `<p>We’re on a mission to make expert divorce support accessible to as many people as possible.</p>  
<p>Membership costs as little as £25 a month.  Find out more about our membership plans <a href="/pricing" target="_blank">here</a>.</p>`,
  },
  {
    title: "Can I reach a legally binding agreement through SeparateSpace?",
    content: `<p>No.  The only way to make the agreement you reach with your ex final and legally binding is to get it approved by the Family Court.  The Family Court will check the agreement you have reached is fair - you shouldn’t enter into an agreement lightly as the Court can hold you to it.  
Our guidance takes you through the steps you can take to reach an agreement and explains how to formalise it. 
</p>`,
  },
  {
    title: "Who is SeparateSpace for?",
    content: `<p>SeparateSpace is designed for people who are getting divorced in England and Wales.</p>`,
  },
  {
    title: "Does my ex have to sign up?",
    content: `<p>No.  We know that having your own space to think through, make plans and take stock, is really important when you’re dealing with a divorce.  That’s why we’ve designed SeparateSpace as a tool to be used by individuals.  We’ll help you to understand where you are in the process and how to get to the end.  
</p>`,
  },
];