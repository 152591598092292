import styles from "layout/GetInTouch/GetInTouch.module.css";
import cn from "classnames";
import { Button, GetItTeam, Htag } from "components";
import { NavLink } from "react-router-dom";

export const GetInTouchTeam = (): JSX.Element => {
  return (
    <section className={cn(styles.callToAction, styles.innerCallToAction)}>
      <div className={styles.callToAction_left}>
       <GetItTeam />
      </div>

      <div className={styles.callToAction_right}>
        <Htag tag="h2">
          Need to ask us a <span className="green_color">question?</span>
          <br />
          Send us a <span className="blue_color">message!</span>
        </Htag>
      </div>

      <div className={styles.joinBtn}>
        <NavLink to="/contact">
          <Button apearance="warning">Contact us</Button>
        </NavLink>
      </div>
    </section>
  );
};
