import { useParams } from "react-router-dom";

import { ArticleContent } from "../../../../components/ArticleContent/ArticleContent.component";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { Wellbeing } from "../../../../store/challenges/challenges.interface";
import { useEffect, useState } from "react";
import {
  loadSingleResource,
  loadSingleTool,
} from "../../../../store/challenges/challenges-thunk";
import s from "./AddSources.module.css";

export const PreviewWSource = () => {
  const { id } = useParams();

  const [type, setType] = useState("");
  const dispatch = useAppDispatch();

  const href = window.location.pathname;

  const item: Wellbeing = useAppSelector((state) => {
    if (href.includes("sresource")) {
      return state.wellbeing.system_resources.filter(
        (resource: Wellbeing) => resource.id == id
      )[0];
    } else if (href.includes("resource")) {
      return state.wellbeing.resources.filter(
        (item: Wellbeing) => item.id == id
      )[0];
    } else {
      return state.wellbeing.tools.filter(
        (item: Wellbeing) => item.id == id
      )[0];
    }
  });

  useEffect(() => {
    if (!id) return;

    if (href.includes("resource")) {
      dispatch(loadSingleResource(Number(id)));
    }
    if (href.includes("tool")) {
      dispatch(loadSingleTool(Number(id)));
    }
  }, []);

  useEffect(() => {
    if (href.includes("resource")) {
      setType("wresources");
    } else {
      setType("wtools");
    }
  }, []);

  return (
    <div className={s.article}>
      <ArticleContent data={item} type={type} />
    </div>
  );
};
