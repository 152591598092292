import s from "../PageTemplate.module.css";
import cn from "classnames";
import parse from "html-react-parser";
import { TemplateProps } from "./templates.props";
import { useAppDispatch } from "utils/hooks";
import { useEffect } from "react";
import { loadSinglePage } from "store/simplePages/simplePages-thunk";
import { Helmet } from "react-helmet";

export const DefaultTemplateComponent = ({ page }: TemplateProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadSinglePage(Number(page.id))).unwrap();
  }, [page.id]);
  return (
    <div>
      <Helmet>
        <title>{page.meta?.title}</title>
        <meta name="description" content={page.meta?.description} />
      </Helmet>{" "}
      <div className={cn(s.templatePage, "section")}>{parse(page.content)}</div>
    </div>
  );
};
