import cn from 'classnames';
import s from './Home.module.css';
import { Htag, ProfIcon, Rating } from 'components';
export const ProffessionalFeedbackComponent = () => {
	return (
    <section className={cn("section", s.proffesionals)}>
      <div className={s.feedBackTitle}>
        <Htag tag="h2">What professionals say</Htag>
        <ProfIcon />
      </div>
      <div className={s.feedbackList}>
        <div className={cn(s.feedbackItem)}>
          <p>
            “SeparateSpace is brilliant, there’s nothing else out there like
            it.”
          </p>
          <div className={s.meta}>
            <h5 className={cn(s.author)}>Lawyer and mediator</h5>
            <Rating rate={5} />
          </div>
        </div>
        <div className={cn(s.feedbackItem)}>
          <p>
            “One of the most painful aspects of divorce for people going through
            it, is feeling they are on their own in a completely unfamiliar
            terrain. The support SeparateSpace offers can really make a
            difference to this and reduce the overwhelm.”
          </p>
          <div className={s.meta}>
            <h5 className={cn(s.author)}>Therapist</h5>
            <Rating rate={5} />
          </div>
        </div>
      </div>
    </section>
  );
}