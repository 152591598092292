import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";

import {
  addNewChallenge,
  deleteChallenge,
  deleteResource,
  deleteTool,
  loadChallenges,
  loadFeellings,
  loadResources,
  loadSingleResource,
  loadSingleTool,
  loadTools,
  updateChallenge,
  updateFeeling,
  updateResource,
  updateTool,
} from "./challenges-thunk";
import {
  Challenge,
  Feelling,
  IinitChallengeState,
  Wellbeing,
} from "./challenges.interface";
import {
  loadSingleWSResource,
  loadSystemWResources,
} from "./wsystem_resources.thunk";

const initChallenges = {
  challenges: {
    visibleFirstTime: false,
    challenges: [],
  },
  feellings: {
    visibleFirstTime: false,

    feelings: [],
  },
  resources: [],
  system_resources: [],
  system_resources_id: [],
  tools: [],
  loading: false,
  error: "",
};

const challengesState = createSlice({
  name: "@@challenges",
  initialState: initChallenges,
  reducers: {
    addWResourceId: (
      state: IinitChallengeState,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.system_resources_id.push(action.payload);
      } else {
        const toRemove = new Set(action.payload);
        state.system_resources_id =
          state.system_resources_id.filter((x) => !toRemove.has(x)) || [];
        state.system_resources_id = state.system_resources_id.concat(
          action.payload
        );
      }
    },
    removeWResourceId: (
      state: IinitChallengeState,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.system_resources_id = state.system_resources_id.filter(
          (item) => item !== action.payload
        );
      } else {
        const toRemove = new Set(action.payload);

        state.system_resources_id =
          state.system_resources_id.filter((x) => !toRemove.has(x)) || [];
      }
    },
    resetWResourcesIds: (state) => {
      state.system_resources_id = [];
    },
    setVisibilityFT: (state, action: PayloadAction<boolean>) => {
      state.feellings.visibleFirstTime = action.payload;
    },
    setVisibilityCT: (state, action: PayloadAction<boolean>) => {
      state.challenges.visibleFirstTime = action.payload;
    },
    setSourceVisible: (state: IinitChallengeState, action) => {
      if (action.payload.type == "tool") {
        state.tools = state.tools.map((tool) => {
          if (tool.id == action.payload.id) {
            tool.viewed = true;
          }

          return tool;
        });
      }
      if (action.payload.type == "resource") {
        state.resources = state.resources.map((resource) => {
          if (resource.id == action.payload.id) {
            resource.viewed = true;
          }

          return resource;
        });
      }
    },
    saveWBookmark: (state: IinitChallengeState, action) => {
      if (action.payload.type == "wresources") {
        state.resources = state.resources.map((item) => {
          if (item.id == action.payload.id) {
            item.bookmark = true;
          }
          return item;
        });
      } else {
        state.tools = state.tools.map((item) => {
          if (item.id == action.payload.id) {
            item.bookmark = true;
          }
          return item;
        });
      }
    },
    removeWBookmark: (state: IinitChallengeState, action) => {
      if (action.payload.type == "wresources") {
        state.resources = state.resources.map((item) => {
          if (item.id == action.payload.id) {
            item.bookmark = false;
          }
          return item;
        });
      } else {
        state.tools = state.tools.map((item) => {
          if (item.id == action.payload.id) {
            item.bookmark = false;
          }
          return item;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initChallenges;
      })
      .addCase(
        addNewChallenge.fulfilled,
        (state: IinitChallengeState, action) => {
          if (!action.payload.challenge.id) return;
          state.challenges.challenges.push({
            title: action.payload.challenge.title,
            id: action.payload.challenge.id,
            tools_id: [],
            resources_id: [],
          });
        }
      )
      .addCase(
        loadChallenges.fulfilled,
        (
          state: IinitChallengeState,
          action: PayloadAction<{
            visibleFirstTime: boolean;
            challenges: Challenge[];
          }>
        ) => {
          state.challenges.challenges = action.payload.challenges;
          state.challenges.visibleFirstTime = action.payload.visibleFirstTime;
        }
      )

      .addCase(
        loadFeellings.fulfilled,
        (
          state: IinitChallengeState,
          action: PayloadAction<{
            visibleFirstTime: boolean;
            feelings: Feelling[];
          }>
        ) => {
          state.feellings.feelings = action.payload.feelings;
          state.feellings.visibleFirstTime = action.payload.visibleFirstTime;
        }
      )
      .addCase(
        deleteChallenge.fulfilled,
        (state: IinitChallengeState, action) => {
          state.challenges.challenges = state.challenges.challenges.filter(
            (challenge) => challenge.id !== action.payload
          );
        }
      )
      .addCase(
        updateChallenge.fulfilled,
        (state: IinitChallengeState, action) => {
          state.challenges.challenges = state.challenges.challenges.map(
            (item) => {
              if (item.id == action.payload.challenge.id) {
                return { ...item, ...action.payload.challenge };
              }
              return item;
            }
          );
        }
      )
      .addCase(
        updateFeeling.fulfilled,
        (state: IinitChallengeState, action) => {
          state.feellings.feelings = state.feellings.feelings.map((item) => {
            if (item.id == action.payload.feeling.id) {
              return action.payload.feeling;
            }
            return item;
          });
        }
      )
      .addCase(
        deleteResource.fulfilled,
        (state: IinitChallengeState, action) => {
          state.loading = false;

          state.resources = state.resources.filter(
            (resource) => resource.id !== action.payload
          );
        }
      )
      .addCase(
        loadTools.fulfilled,
        (state: IinitChallengeState, action: PayloadAction<Wellbeing[]>) => {
          state.tools = action.payload;
        }
      )
      .addCase(
        loadSingleTool.fulfilled,
        (state: IinitChallengeState, action) => {
          state.tools = state.tools.filter(
            (tool) => tool.id !== action.payload.id
          );

          state.tools.push(action.payload);
        }
      )
      .addCase(deleteTool.fulfilled, (state: IinitChallengeState, action) => {
        state.tools = state.tools.filter((tool) => tool.id !== action.payload);
      })
      .addCase(updateTool.fulfilled, (state: IinitChallengeState, action) => {
        state.tools = state.tools.map((item) => {
          if (item.id == action.payload.tool.id) {
            return action.payload.tool;
          }
          return item;
        });
      })

      .addCase(
        loadResources.fulfilled,
        (state: IinitChallengeState, action: PayloadAction<Wellbeing[]>) => {
          state.resources = action.payload;
        }
      )
      .addCase(
        loadSystemWResources.fulfilled,
        (state: IinitChallengeState, action: PayloadAction<Wellbeing[]>) => {
          state.system_resources = action.payload;
        }
      )
      .addCase(
        loadSingleResource.fulfilled,
        (state: IinitChallengeState, action) => {
          state.resources = state.resources.filter(
            (resource) => resource.id !== action.payload.id
          );

          state.resources.push(action.payload);
        }
      )
      .addCase(
        loadSingleWSResource.fulfilled,
        (state: IinitChallengeState, action) => {
          state.system_resources = state.system_resources.filter(
            (resource) => resource.id !== action.payload.id
          );

          state.system_resources.push(action.payload);
        }
      )
      .addCase(
        updateResource.fulfilled,
        (state: IinitChallengeState, action) => {
          state.resources = state.resources.map((item) => {
            if (item.id == action.payload.resource.id) {
              return action.payload.resource;
            }
            return item;
          });
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
          state.error = "";
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          if (action.error.message) {
            state.error = action.error.message;
          } else {
            state.error = "Some server error";
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.loading = false;
          state.error = "";
        }
      );
  },
});

export const {
  setVisibilityFT,
  setVisibilityCT,
  setSourceVisible,
  saveWBookmark,
  removeWBookmark,
  addWResourceId,
  removeWResourceId,
  resetWResourcesIds,
} = challengesState.actions;

export default challengesState.reducer;
