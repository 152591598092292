import { Alert, FormControl, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  EditorComponent,
  ErrorField,
  Htag,
  InputImageComponent,
  ResourceIcon,
} from "components";
import s from "./CaseStudies.module.css";
import { NavLink, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useFormik } from "formik";

import { validationCaseStudySchema } from "./CSValidation";
import { IOption, extraCases } from "store/caseStudy/caseStudy.interface";
import { OptionsWidget } from "./Options/OptionsWidget.component";
import { ExtraCaseStudy } from "widgets/extraCS/ExtraCaseStudy.component";
import { CSSummary } from "widgets/caseStudySummary/caseStudySummary.component";

import { edit_cs, get_cs } from "store/caseStudy/caseStudy_thunk";
import { CaseStudy } from "models/CaseStudy.model";
import { formData } from "utils/utils";

export const EditCSPage = () => {
  const { id } = useParams();

  const CS = useAppSelector((state) =>
    state.caseStudiesState.caseStudiesArray.find((item) => item.id == id)
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const dispatch = useAppDispatch();
  const [serverError, setServerError] = useState<any[]>([]);

  const formik = useFormik({
    initialValues: new CaseStudy(),
    validationSchema: validationCaseStudySchema,
    onSubmit: (values) => {
      const formaValue = formData(values);
      if (id)
        dispatch(edit_cs({ data: formaValue, id: id }))
          .unwrap()
          .then(() => {
            setOpenSnackbar(true);
          })
          .catch((err) => {
            if (err && Array.isArray(err)) {
              setServerError(err);
            } else {
              setServerError([{ serverError: "Something wrong with server" }]);
            }
          });
    },
  });

  useEffect(() => {
    if (!id) return;

    dispatch(get_cs(id))
      .unwrap()
      .then((resp) => {
        formik.setFieldValue("content", resp.content);
        formik.setFieldValue("title", resp.title);
        formik.setFieldValue("image", resp.image);
        if (resp.reading_time)
          formik.setFieldValue("reading_time", resp.reading_time);
        formik.setFieldValue("subtitle", resp.subtitle);
        formik.setFieldValue("summary", resp.summary);
        formik.setFieldValue("options", resp.options);
        formik.setFieldValue("problems", resp.problems);
      });
  }, []);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOptions = useCallback(
    (newOption: IOption | number) => {
      const arr: undefined[] | IOption[] = formik.values.options;

      if (typeof newOption == "number") {
        arr[newOption] = undefined;
      } else {
        arr[newOption.id] = newOption;
      }
      formik.setFieldValue("options", arr);
    },
    [formik.values.options]
  );

  return (
    <>
      <Htag tag="h3">
        <ResourceIcon />
        Edit case study: "{formik.values.title}"
      </Htag>

      <form onSubmit={formik.handleSubmit} className={s.csForm}>
        <div className={s.formField}>
          <Htag tag="h4">Title</Htag>
          <FormControl fullWidth className={s.subtitle}>
            <input
              id="title"
              name="title"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              type="text"
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          {CS?.unique_number && (
            <Htag tag="h4">Unique number: {CS?.unique_number} </Htag>
          )}
        </div>

        <div className={s.formField}>
          <Htag tag="h4">Subtitle: </Htag>
          <FormControl fullWidth className={s.subtitle}>
            <input
              id="subtitle"
              name="subtitle"
              onBlur={formik.handleBlur}
              value={formik.values.subtitle}
              type="text"
              onChange={formik.handleChange}
              placeholder="Subtitle"
            />
            {formik.touched.subtitle && formik.errors.subtitle && (
              <ErrorField error={formik.errors.subtitle} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4">Reading time: </Htag>
          <FormControl className={s.reading_time}>
            <input
              id="reading_time"
              name="reading_time"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.reading_time}
              onChange={formik.handleChange}
              placeholder="Reading time"
            />
            {formik.touched.reading_time && formik.errors.reading_time && (
              <ErrorField error={formik.errors.reading_time} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload preview image
          </Htag>
          <InputImageComponent
            handleFiles={formik.setFieldValue}
            handleOnBlur={formik.setFieldValue}
            file={formik.values.image}
            id="image"
            name="image"
          />
        </div>

        <CSSummary
          summary={formik.values.summary}
          handleSummary={(summary) => formik.setFieldValue("summary", summary)}
        />

        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            handleChange={(e) => {
              formik.setFieldValue("content", e);
            }}
          />

          {formik.touched.content && formik.errors.content && (
            <ErrorField error={formik.errors.content} />
          )}
        </div>

        <Htag tag="h4">Options</Htag>
        <div className={s.options}>
          <OptionsWidget
            data={formik.values.options[0]}
            handleOptions={handleOptions}
            id="0"
          />
          <OptionsWidget
            data={formik.values.options[1]}
            handleOptions={handleOptions}
            id="1"
          />
          <OptionsWidget
            data={formik.values.options[2]}
            handleOptions={handleOptions}
            id="2"
          />
        </div>
        <div className={s.widgetBlock}>
          <Htag tag="h4">Problems</Htag>
          <div className={s.ecs_list}>
            {extraCases.map((item, index) => (
              <ExtraCaseStudy
                index={index}
                title={item}
                key={index}
                data={formik.values.problems}
                handleOptions={(e) => formik.setFieldValue("problems", e)}
              />
            ))}
          </div>
        </div>
        <NavLink
          to={`/admin-dashboard/caseStudies/edit-page/${id}/preview`}
          className={s.preview}
          target="_blank"
        >
          Preview
        </NavLink>
        {serverError
          ? serverError.map((item, index) => (
              <ErrorField
                error={Object.entries(item).map((a) => a.join(": "))[0]}
              />
            ))
          : null}
        <Button apearance="secondary" type="submit">
          Save
        </Button>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Case study "{formik.values.title}" is updated!
        </Alert>
      </Snackbar>
    </>
  );
};
