import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Card, Features, Htag } from "../../components";

import s from "./AdminDashboard.module.css";
import ReactApexChart from "react-apexcharts";
import { useEffect } from "react";
import { getChartsData } from "store/analytics/analytics-thunk";

const colors = [
  "#66bb6a",
  "#4fc3f7",
  "#A00F72",
  "#ff5722",
  "#cddc39",
  "#673ab7",
  "#009688",
  "#b388ff",
  "#8DDDE4",
  "#e040fb",
  "#0288d1",
  "#e53935",
  "#1b5e20",
  "#ff9800",
];

interface Iseries {
  name: string;
  data: number[];
}

const curve: "smooth" | "straight" | "stepline" | "monotoneCubic" = "smooth";

const defaultOpt = {
  options: {
    plotOptions: {
      bar: {
        columnWidth: "65%",
        endingShape: "rounded",
      },
    },
    colors: colors,
    legend: {
      show: false,
    },
    xaxis: {
      labels: {
        style: {
          fontSize: "15px",
          fontWeight: 600,
        },
        rotate: 0,
        hideOverlappingLabels: false,
        trim: true,
      },
    },
  },
};

const createNewChartOpt = (
  serries: Iseries[],
  id: string,
  categories: (string | number)[],
  title: string,
  distributed?: boolean
) => {
  const currentChart = {
    title,
    options: {
      ...defaultOpt.options,
      plotOptions: {
        ...defaultOpt.options.plotOptions,
        bar: {
          ...defaultOpt.options.plotOptions.bar,
          distributed: distributed,
        },
      },
      colors: colors,
      xaxis: {
        ...defaultOpt.options.xaxis,
        labels: {
          ...defaultOpt.options.xaxis.labels,
          style: {
            ...defaultOpt.options.xaxis.labels.style,
            colors: distributed ? colors : "#000000",
          },
        },
        categories: categories,
      },
    },

    series: serries,
    chart: {
      id: id,
    },
  };

  return currentChart;
};

const curveXaxisType: "category" | "datetime" | "numeric" = "datetime";

export const AdminDashboard = (): JSX.Element => {
  const charts = useAppSelector((state) =>
    state.analyticsDataState.chartData.map((chart: any, key: any) => {
      let distributed = true;
      if (chart.title == "Length of marriage") {
        distributed = false;
        return Object.assign(
          createNewChartOpt(
            chart.data,
            key.toString(),
            chart.categories,
            chart.title
          ),

          {
            options: {
              dataLabels: { enabled: false },
              legend: {
                show: false,
              },
              xaxis: {
                categories: chart.categories,
              },
            },
          }
        );
      }

      if (chart.title == "Status of child arrangements at time of onboarding") {
        distributed = false;
        return Object.assign(
          createNewChartOpt(
            chart.data,
            key.toString(),
            chart.categories,
            chart.title,
            distributed
          ),

          {
            options: {
              chart: {
                stacked: true,
                toolbar: {
                  show: true,
                },
              },
              colors: ["#e53935", "#4fc3f7", "#b388ff"],

              plotOptions: {
                bar: {
                  distributed: false,
                  borderRadius: 5,
                },
              },

              xaxis: {
                categories: chart.categories,
                labels: { rotate: 0, hideOverlappingLabels: false, trim: true },
              },
            },
          }
        );
      }
      if (chart.title == "Financial picture") {
        return Object.assign(
          createNewChartOpt(
            chart.data,
            key.toString(),
            chart.categories,
            chart.title
          ),

          {
            options: {
              colors: colors,
              dataLabels: { enabled: false },
              legend: {
                show: false,
              },
              plotOptions: {
                bar: {
                  distributed: true,
                },
              },
              xaxis: {
                categories: chart.categories,
              },
            },
          }
        );
      }
      return Object.assign(
        createNewChartOpt(
          chart.data,
          key.toString(),
          chart.categories,
          chart.title,
          distributed
        )
      );
    })
  );

  const usersAnalyticsData = useAppSelector(
    (state) => state.analyticsDataState.userData
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getChartsData());
  }, []);

  const usersAnalyticsOpt = {
    series: usersAnalyticsData.data,
    options: {
      chart: {
        height: 350,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: curve,
      },
      xaxis: {
        type: curveXaxisType,
        categories: usersAnalyticsData.categories,
      },
      tooltip: {
        x: {
          format: "yy/MM/dd",
        },
      },
    },
  };

  return (
    <>
      <header className={s.dashboardHeader}>
        <Features className={s.dashboard_img} />
        <Htag tag="h2">Admin Dashboard</Htag>
      </header>

      <div className={s.charts}>
        <Card className={s.chart_item}>
          <ReactApexChart
            options={usersAnalyticsOpt.options}
            series={usersAnalyticsOpt.series}
            type="area"
            height={350}
          />
        </Card>
        {charts.map((chart: any, index: number) => (
          <Card className={s.chart_item} key={index}>
            <Htag className={s.chart_title} tag="h4">
              {chart.title}
            </Htag>
            <ReactApexChart
              options={chart.options}
              series={chart.series}
              type="bar"
            />
          </Card>
        ))}
      </div>
    </>
  );
};
