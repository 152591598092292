import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createNewPage,
  deleteSimplePage,
  loadSimplePages,
  loadSinglePage,
  updatePage,
} from "./simplePages-thunk";
import { InitPages, Page } from "./simplePages-inderfaces";

const initPages: InitPages = {
  pages: [],
  loading: false,
  error: "",
};

const allPagesState = createSlice({
  name: "@@custompages",
  initialState: initPages,
  reducers: {
    savePage: (state: InitPages, action: PayloadAction<Page>) => {
      state.pages.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        loadSimplePages.fulfilled,
        (state: InitPages, action: PayloadAction<Page[]>) => {
          let someArr: Page[] = [];
          someArr = action.payload.map((obj) => {
            const index = state.pages.findIndex((el) => el["id"] == obj["id"]);
            const content =
              index !== -1 && state.pages[index].content
                ? state.pages[index].content
                : "";
            return {
              ...obj,
              content,
            } as Page;
          });

          state.pages = someArr;
        }
      )
      .addCase(deleteSimplePage.fulfilled, (state: InitPages, action) => {
        state.pages = state.pages.filter((page) => page.id !== action.payload);
      })
      .addCase(createNewPage.fulfilled, (state: InitPages, action) => {
        state.pages.push(action.payload.page);
      })
      .addCase(updatePage.fulfilled, (state: InitPages, action) => {
        state.pages = state.pages.map((page) => {
          if (page.id == action.payload.page.id) {
            page = action.payload.page;
          }
          return page;
        });
      })
      .addCase(loadSinglePage.fulfilled, (state: InitPages, action) => {
        state.pages = state.pages.filter(
          (page) => page.id !== action.payload.id
        );

        state.pages.push(action.payload);
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
          state.error = "";
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          if (action.payload) {
            state.error = action.payload;
          } else if (action.error) {
            state.error = `Server error:  ${action.error}`;
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.loading = false;
          state.error = "";
        }
      );
  },
});

export const { savePage } = allPagesState.actions;

export default allPagesState.reducer;
