import { createSlice } from "@reduxjs/toolkit";
import { get_search_result } from "./search-thunk";
import { ISearchState } from "./search-interface";
import { resetToDefault } from "../login/login-reducer";

const initialState: ISearchState = {
  search: "",
  search_results: [],
  loading: false,
  error: "",
};

const search = createSlice({
  name: "Search",
  initialState,
  reducers: {
    setSearchResult: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_search_result.fulfilled, (state, action) => {
        state.search_results = action.payload;
      })
      .addCase(resetToDefault, () => {
        return initialState;
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;

          if (action.payload) {
            // state.error = action.payload;
          } else if (action.error) {
            // state.error = `Server error:  ${action.error}`;
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.loading = false;
          state.error = "";
        }
      );
  },
});

export const { setSearchResult } = search.actions;

export default search.reducer;
