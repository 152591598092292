import { Wellbeing } from "store/challenges/challenges.interface";
import { Article } from "./Article.model";

export class CWellbeing extends Article {
  image: Blob | null | string;
  attachments: any[];
  need_journal: boolean;
  viewed?: boolean;
  challenges: number[];
  feelings: number[];

  constructor(
    title = "",
    content = "",
    reading_time = "",
    image = "",
    attachments = [],
    need_journal = false,
    challenges = [],
    feelings = [],
    viewed = false
  ) {
    super(title, content, reading_time);
    this.image = image;
    this.attachments = attachments;
    this.challenges = challenges;
    this.feelings = feelings;
    this.need_journal = need_journal;
    this.viewed = viewed;
  }

  formData(values: Wellbeing) {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("content", values.content);
    formData.append("reading_time", values.reading_time);
    formData.set("need_journal", JSON.stringify(values.need_journal));
    if (values.image) {
      formData.append("image", values.image);
    } else {
      formData.append("image", "");
    }
    formData.set("viewed", JSON.stringify(values.viewed));
    values.attachments.map((file: any) => {
      formData.append("attachments[]", file);
    });
    if (values.feelings)
      values.feelings.map((file: any) => {
        formData.append("feelings[]", file);
      });
    if (values.challenges)
      values.challenges.map((file: any) => {
        formData.append("challenges[]", file);
      });
    return formData;
  }
}
