import s from "./Wellbeing.module.css";

import { List, ListItem } from "@mui/material";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { NavLink, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { loadChallenges } from "store/challenges/challenges-thunk";
import { Challenge } from "store/challenges/challenges.interface";

import axios from "axios";
import { SET_FIRST_ACCESS_CHALLENGE } from "store/config";

export const WelbeingChallenges = () => {
  const { challenges } = useAppSelector((state) => state.wellbeing.challenges);

  const dispatch = useAppDispatch();

  const href = window.location.pathname;
  const userToken = useAppSelector((state) => state.userState.token);

  axios.put(
    SET_FIRST_ACCESS_CHALLENGE(),
    { visibleFirstTime: true },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      responseType: "json",
    }
  );

  useEffect(() => {
    dispatch(loadChallenges());
  }, [dispatch]);

  return (
    <div className={(s.challenges_list, s.wellbeingWrap)}>
      <div className={s.wellbeing_explain_text}>
        <p>
          Separation and divorce can create really difficult challenges. What
          would you like help with today?
        </p>
      </div>
      <List className={s.challengeList}>
        {challenges.map((challenge: Challenge) => (
          <ListItem key={challenge.id}>
            <NavLink
              className={s.challengeTitle}
              to={`${href}/${challenge.id}`}
            >
              {challenge.title}
            </NavLink>
          </ListItem>
        ))}
      </List>
      <Outlet />
    </div>
  );
};
