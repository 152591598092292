import { GetInTouchProps } from "./GetInTouch.props";
import styles from "./GetInTouch.module.css";

import cn from "classnames";
import { NavLink } from "react-router-dom";
import { Button, FullModalStyled, KeepIn, KeepInSmall, ModalStyled } from "components";

import { useState } from "react";

import { OnBoardingComponent } from "../auth/Registration/OnBoarding.component";
import { setInterviewToInit, setRegModalStatus } from "store/onBoarding/onboarding-reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";

const defaultTitle = (
  <h2>
    Ready to <span>create your space?</span>
  </h2>
);

export const GetInTouch = ({
  isHome,
  title = defaultTitle,
}: GetInTouchProps): JSX.Element => {
  const dispatch = useAppDispatch();

  // REGISTRATION //

  const handleOpenReg = () => dispatch(setRegModalStatus(true));

 
  return (
    <section
      className={cn(styles.callToAction, {
        darkBg: isHome === true,
        [styles.innerCallToAction]: isHome === false,
      })}
    >
      {isHome ? (
        <div className={styles.callToAction_left}>
          <KeepIn />
        </div>
      ) : (
        <div className={styles.callToAction_left}>
          <KeepInSmall />
        </div>
      )}

      <div className={styles.callToAction_right}>
        {title}

        {isHome && (
          <p>
            SeparateSpace has been created by leading experts to provide you
            with the robust legal and practical support that you need to
            navigate your divorce.
          </p>
        )}
      </div>
      {isHome && (
        <div className={styles.moreInfo}>
          <p>
            <small>
              Do you need more information?{" "}
              <NavLink className="hover-underline-animation" to="/contact">
                Get in touch!
              </NavLink>
            </small>
          </p>
        </div>
      )}

      <div className={styles.joinBtn}>
        <Button apearance="warning" onClick={handleOpenReg}>
          Join now!
        </Button>
      </div>

    </section>
  );
};
