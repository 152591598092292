import { Alert, FormControl, SelectChangeEvent, Snackbar } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  ArrowLeftIcon,
  Button,
  EditorComponent,
  ErrorField,
  Htag,
  InputImageComponent,
} from "components";
import s from "./AddPage.module.css";
import a from "../SpecialSources.module.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import {
  loadSinglePage,
  updatePage,
} from "store/simplePages/simplePages-thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useFormik } from "formik";
import { validationCreatePage } from "../validation";
import { Page } from "store/simplePages/simplePages-inderfaces";
import { resetToDefault } from "store/login/login-reducer";
import { MarketingPage } from "models/PageTemplates.model";
import { SelectComponent } from "components/Select/Select.component";
import { formTypes, pageTemplates } from "./config";
import cn from "classnames";
import {
  CustomSnackbar,
  CustomSnackbarRef,
} from "components/CustomSnackbar/CustomSnackbar.component";

export const EditPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [formType, setFormType] = useState<number>(1);
  const ref = useRef<CustomSnackbarRef>(null);
  const page: Page = useAppSelector((state) =>
    state.pages.pages.filter((page: Page) => page.id == id)
  )[0];

  const { error } = useAppSelector((state) => state.specialTicketsState);
  const navigate = useNavigate();

  const titleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
  };

  const formik = useFormik({
    initialValues: new MarketingPage(),
    validationSchema: validationCreatePage,
    onSubmit: (values) => {
      if (!id) return;
      const updatedValue = formik.initialValues.formData(values);
      dispatch(
        updatePage({
          page_id: Number(id),
          page_data: updatedValue,
        })
      )
        .unwrap()
        .then(() => {
          ref.current?.refresh();
        })
        .catch((error) => {
          if (error == "Unauthenticated.") {
            dispatch(resetToDefault());
            navigate("/");
          } else {
            console.log(error);
            throw new Error(error);
          }
        });
    },
  });

  const handlePageTemplate = (e: SelectChangeEvent) => {
    const value = e.target.value;
    formik.setFieldValue("type", value);
  };

  const handleFormType = (e: SelectChangeEvent) => {
    setFormType(Number(e.target.value));
    formik.setFieldValue("form_type", e.target.value);
  };

  useEffect(() => {
    if (!page) return;

    formik.setFieldValue("content", page.content);
    formik.setFieldValue("title", page.title);
    formik.setFieldValue("image", page.image);
    formik.setFieldValue("type", page.type);
    formik.setFieldValue("form_type", page.form_type);
    if (page.meta) formik.setFieldValue("meta[title]", page.meta.title);
    if (page.meta)
      formik.setFieldValue("meta[description]", page.meta.description);
  }, [page]);

  useEffect(() => {
    dispatch(loadSinglePage(Number(id)));
  }, [id]);

  return (
    <>
      <Htag tag="h3">Edit Page - {formik.values.title}</Htag>
      <form onSubmit={formik.handleSubmit}>
        <div className={a.formField}>
          <Htag tag="h4">Templates: </Htag>
          <SelectComponent
            selectData={formik.values.type}
            label={formik.values.type === null ? "Page template" : ""}
            selectArray={pageTemplates}
            handleData={handlePageTemplate}
          />
        </div>
        <div className={a.formField}>
          <Htag tag="h4">Title: </Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              onChange={titleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>
        {formik.values.type !== "default" && (
          <div className={a.formField}>
            <Htag tag="h4" className={s.headline}>
              Upload preview image
            </Htag>
            <InputImageComponent
              handleFiles={formik.setFieldValue}
              handleOnBlur={formik.setFieldValue}
              file={formik.values.image}
              id="image"
              name="image"
            />
          </div>
        )}
        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            handleChange={(e) => formik.setFieldValue("content", e)}
          />

          {formik.touched.content && formik.errors.content && (
            <ErrorField error={formik.errors.content} />
          )}
        </div>
        <div className={a.formField}>
          <Htag tag="h4">Meta data:</Htag>
          <FormControl fullWidth className={cn(s.title, s.meta)}>
            <p>
              <input
                id="meta_title"
                name="meta.title"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.meta.title}
                onChange={formik.handleChange}
                placeholder="Meta title"
              />
              {formik.touched.meta?.title && formik.errors.meta?.title && (
                <ErrorField error={formik.errors.meta.title} />
              )}
            </p>
            <p>
              <input
                id="meta_description"
                name="meta.description"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.meta.description}
                onChange={formik.handleChange}
                placeholder="Meta description"
              />
              {formik.touched.meta?.description &&
                formik.errors.meta?.description && (
                  <ErrorField error={formik.errors.meta.description} />
                )}
            </p>
          </FormControl>
        </div>

        {formik.values.type == "marketing" && (
          <div className={a.formField}>
            <Htag tag="h3">Choose form</Htag>
            <div>
              <SelectComponent
                selectData={formType}
                label={formType === null ? "Type of form" : ""}
                selectArray={formTypes}
                handleData={handleFormType}
              />
            </div>
          </div>
        )}

        {error &&
          Array.isArray(error) &&
          error.map((item, index) => (
            <ErrorField
              key={index}
              error={`Server error: ${Object.values(item)[0]}`}
            />
          ))}
        {error && typeof error == "string" && <ErrorField error={error} />}
        <p className={s.previewLink}>
          <NavLink to={`/${page.slug}`} target="_blank">
            Preview <ArrowLeftIcon />
          </NavLink>
        </p>

        <Button apearance="secondary" type="submit">
          Save
        </Button>
      </form>

      <CustomSnackbar
        message={` Page ${formik.values.title} is updated!`}
        ref={ref}
      />
    </>
  );
};
