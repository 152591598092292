import { forwardRef, LegacyRef } from 'react';
import s from "./Message.module.css";
import { MessageProps } from "./Message.props";
import cn from 'classnames';

export const MessageComponent = forwardRef<HTMLDivElement, MessageProps>(
  (props: MessageProps, ref) => {
    return (
      <div ref={ref} {...props} className={cn(s.msg, props.className)}>
        <div className='b1'>{props.children}</div>
      </div>
    );
  }
);
