import { AddBookmarkProps } from "./AddBookmark.props";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { remove_api, set_api } from "../../store/bookmarks/bookmark_api";
import cn from "classnames";
import {
  removeBookmark,
  saveBookmark,
} from "../../store/system_resources/system_resource-reducer";
import {
  removeWBookmark,
  saveWBookmark,
} from "../../store/challenges/challenges-reducer";
import s from "./AddBookmark.module.css";
import { BookmarkIcon, Flag } from "components";

export const AddBookmark = ({ type, item }: AddBookmarkProps): JSX.Element => {
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const userToken = useAppSelector((state) => state.userState.token);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!item ) return;
    if (typeof item.bookmark == "undefined") return;
    setIsAdded(item.bookmark);
  }, [item]);

  const addToBookmarks = (): void => {
	if(!userToken) return;
    const some = !isAdded;

    setIsAdded(!isAdded);

    if (some) {
      set_api(type, item.title, item.id, userToken)
        .then(() => {
          if (type == "sresource") {
            dispatch(saveBookmark(item.id));
          } else {
            dispatch(saveWBookmark({ type: type, id: item.id }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      remove_api(type, item.title, item.id, userToken)
        .then(() => {
          if (type == "sresource") {
            dispatch(removeBookmark({ type: type, id: item.id }));
          } else {
            dispatch(removeWBookmark({ type: type, id: item.id }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div
      onClick={addToBookmarks}
      className={cn(s.addToBook, {
        [s.booked]: isAdded,
      })}
    >
      {isAdded ? <Flag /> : <BookmarkIcon />}
      Save{isAdded && `d`} resource
    </div>
  );
};
