
import { GoToTopProps } from "./GoToTop.props";
import s from "./GoToTop.module.css";
import { useState } from 'react';
import { ArrowUpIcon } from '../Icons/Icons.component';

export const GoToTop = (props: GoToTopProps): JSX.Element => {
const [visible, setVisible] = useState(false);


const toggleVisible = () => {
  const scrolled = document.documentElement.scrollTop;
  if (scrolled > 300) {
    setVisible(true);
  } else if (scrolled <= 300) {
    setVisible(false);
  }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",

  });
};
window.addEventListener("scroll", toggleVisible);
  return (
    <div
      {...props}
      className={s.goToTop}
      onClick={scrollToTop}
      style={{ display: visible ? "grid" : "none" }}
    >
      <ArrowUpIcon />
    </div>
  );
};
