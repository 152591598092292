import s from "./LogoText.module.css";
import cn from "classnames";

import { logoProps } from './LogoText.props';

export const LogoText = (props: logoProps): JSX.Element => {
  return (
    <div className={cn(props.className, s.mainStyleLogo)}>
      <span className={s.logoSpan}>Separate</span>Space
    </div>
  );
};
