import * as yup from "yup";

export const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
 
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  name: yup
    .string()
    .min(2, "Name should be at least 8 characters length")
    .required("Name is required"),
  isInProcess: yup.string().required("This is required field"),
  isLegal: yup.string().required("This is required field"),
  message: yup
    .string()
    .required("This is required field")
    .max(500, "Max 500 characters"),
});

export const validationLoginSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const validationSignupSchema = yup.object({
  has_partner: yup
    .bool()
    .oneOf([true], "You need to accept the terms and conditions"),
  local: yup
    .bool()
    .oneOf([true], "Field is required"),
  
});
