import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  CREATE_NEW_CHALLENGE,
  CREATE_RESOURCE,
  CREATE_TOOL,
  DELETE_CHALLENGE,
  DELETE_RESOURCE,
  DELETE_TOOL,
  GET_ALL_CHALLENGES,
  GET_ALL_FEELLINGS,
  GET_ALL_RESOURCES,
  GET_ALL_TOOLS,
  GET_SINGLE_WELLBEING_RESOURCE,
  GET_SINGLE_WELLBEING_TOOL,
  UPDATE_CHALLENGE,
  UPDATE_FEELING,
  UPDATE_RESOURCE,
  UPDATE_TOOL,
  VIEWED_RESOURCE,
  VIEWED_TOOL,
} from "../config";
import { IInitState } from "../root-interface";
import { Challenge, Feelling, Wellbeing } from "./challenges.interface";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

type AddChallengeI = {
  success: string;
  challenge: Challenge;
};

export const loadChallenges = createAsyncThunk<
  {
    visibleFirstTime: boolean;
    challenges: Challenge[];
  },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/allChallenges",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_CHALLENGES(),
      rejectWithValue,
      dispatch
    );
  }
);

export const addNewChallenge = createAsyncThunk<
  AddChallengeI,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/newChallenge",
  async function (title, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      CREATE_NEW_CHALLENGE(),
      rejectWithValue,
      dispatch,
      JSON.stringify({ title: title })
    );
  }
);

export const loadFeellings = createAsyncThunk<
  { visibleFirstTime: boolean; feelings: Feelling[] },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/allFillings",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_FEELLINGS(),
      rejectWithValue,
      dispatch
    );
  }
);

export const updateFeeling = createAsyncThunk<
  { success: string; feeling: Feelling },
  { feeling_id: number; description: string },
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/updateFeeling",
  async function (
    { feeling_id, description },
    { getState, rejectWithValue, dispatch }
  ) {
    return await getRequestFabric(
      API_TYPE.PUT,
      getState,
      UPDATE_FEELING(feeling_id),
      rejectWithValue,
      dispatch,
      JSON.stringify({ description: description })
    );
  }
);

export const deleteChallenge = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/deleteChallenge",
  async function (challenge_id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_CHALLENGE(challenge_id),
      rejectWithValue,
      dispatch
    );
  }
);

export const updateChallenge = createAsyncThunk<
  { success: string; challenge: Challenge },
  { challenge_id: number; title: string },
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/updateChallenge",
  async function (
    { challenge_id, title },
    { getState, rejectWithValue, dispatch }
  ) {
    return await getRequestFabric(
      API_TYPE.PUT,
      getState,
      UPDATE_CHALLENGE(challenge_id),
      rejectWithValue,
      dispatch,
      JSON.stringify({ title: title })
    );
  }
);

// Resources
export const loadResources = createAsyncThunk<
  Wellbeing[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/allResources",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_RESOURCES(),
      rejectWithValue,
      dispatch
    );
  }
);

export const loadSingleResource = createAsyncThunk<
  Wellbeing,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/loadSingleReducer",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SINGLE_WELLBEING_RESOURCE(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const addResource = createAsyncThunk<
  { success: string; resource: Wellbeing },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/addResource",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      CREATE_RESOURCE(),
      rejectWithValue,
      dispatch,
      data
    );
  }
);

export const deleteResource = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/deleteResource",
  async function (resource_id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_RESOURCE(resource_id),
      rejectWithValue,
      dispatch
    );
  }
);

// TOOLS
export const loadTools = createAsyncThunk<
  Wellbeing[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/allTools",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_TOOLS(),
      rejectWithValue,
      dispatch
    );
  }
);

export const loadSingleTool = createAsyncThunk<
  Wellbeing,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/loadSingleTools",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SINGLE_WELLBEING_TOOL(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const addTool = createAsyncThunk<
  { success: string; tool: Wellbeing },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/addTool",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      CREATE_TOOL(),
      rejectWithValue,
      dispatch,
      data
    );
  }
);

export const deleteTool = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/deleteTool",
  async function (tool_id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_TOOL(tool_id),
      rejectWithValue,
      dispatch
    );
  }
);

export const updateResource = createAsyncThunk<
  { success: string; resource: Wellbeing },
  { resource_id: number; data: FormData },
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/updateResource",
  async function (
    { resource_id, data },
    { getState, rejectWithValue, dispatch }
  ) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      UPDATE_RESOURCE(resource_id),
      rejectWithValue,
      dispatch,
      data
    );
  }
);

export const updateTool = createAsyncThunk<
  { success: string; tool: Wellbeing },
  { tool_id: number; data: FormData },
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/updateTool",
  async function ({ tool_id, data }, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      UPDATE_TOOL(tool_id),
      rejectWithValue,
      dispatch,
      data
    );
  }
);

// IF RESOURSE/TOOL was readed
export const viewedTool = createAsyncThunk<
  { success: string },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/viewedTool",
  async function (tool_id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.PUT,
      getState,
      VIEWED_TOOL(tool_id),
      rejectWithValue,
      dispatch,
      JSON.stringify({ viewed: true })
    );
  }
);

export const viewedResource = createAsyncThunk<
  { success: string },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/viewedResource",
  async function (resource_id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.PUT,
      getState,
      VIEWED_RESOURCE(resource_id),
      rejectWithValue,
      dispatch,
      JSON.stringify({ viewed: true })
    );
  }
);
