import s from "../Questionnaire.module.css";
import { Accordion, Button, ErrorField, Htag } from "components";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

import { setCQuestionnaire } from "store/questionnaires/questionnaires.reducer";
import { useRef, useState } from "react";
import { addId, removeId } from "store/system_tickets/system_ticket-reducer";
import { QuestionnaireProps } from "../Questionnaire.props";
import {
  addResourceId,
  removeResourceId,
} from "store/system_resources/system_resource-reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { addCSId, removeCSId } from "store/caseStudy/caseStudy.reducer";
import { removeWResourceId } from "store/challenges/challenges-reducer";
const answers = [
  {
    title: "Why are we asking this?",
    content: `<p>We want to give you information that’s relevant to your child’s age and stage. For instance, the
way you’d talk to a 4 year old about divorce is very different to the way you’d discuss it with a 14
year old.
</p>`,
  },
  {
    title: "What about step-children?",
    content:
      "Yes, please include any children who one or both of you have responsibilities for.",
  },
];

interface IDispatchAdd {
  adults: {
    resourceId: string[];
    csId: string[];
    id: string[];
  };
  babies: {
    resourceId: string[];
    csId: string[];
    id: string[];
  };
  toddlers: {
    resourceId: string[];
    csId: string[];
    id: string[];
  };
  teenagers: {
    resourceId: string[];
    csId: string[];
    id: string[];
  };
}

interface IDispatchRemomve {
  adults: {
    resourceId: string[];
    csId: string[];
    id?: string[];
  };
  babies: {
    resourceId: string[];
    csId: string[];
    id: string[];
  };
  toddlers: {
    resourceId: string[];
    id: string[];
    csId?: string[];
  };
  teenagers: {
    resourceId: string[];
    id: string[];
    csId?: string[];
  };
}

export const FormCB1 = ({
  handleBack,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const сhildrenOnboarding = useAppSelector(
    (state) => state.questionnairesState.cQuestionnaire
  );

  const home_property = useAppSelector(
    (state) =>
      state.userState.user.questionnaires.generalQuestionnary?.home_property
  );
  const living_property = useAppSelector(
    (state) =>
      state.userState.user.questionnaires.generalQuestionnary?.living_property
  );

  const livingVariety =
    living_property == "different"
      ? [
          "CR1",
          "CR6",
          "CR8",
          "CR9",
          "CR10a",
          "CR1A",
          "CR3A",
          "CR4A",
          "CR15A",
          "CR20A",
          "NG23",
          "CR13",
          "CR11",
          "CR12",
          "NG31",
          "NG32",
        ]
      : [
          "CR1",
          "CR2",
          "CR8",
          "CR9",
          "CR10a",
          "CR1A",
          "CR3A",
          "CR4A",
          "CR15A",
          "CR20A",
          "NG23",
          "CR13",
          "CR11",
          "CR12",
          "NG31",
          "NG32",
        ];

  const [state, setState] = useState({
    errorMsg: false,
  });

  const checkboxesRef = useRef<{
    babies: HTMLInputElement | null;
    toddlers: HTMLInputElement | null;
    teenagers: HTMLInputElement | null;
    adults: HTMLInputElement | null;
  }>({
    babies: null,
    toddlers: null,
    teenagers: null,
    adults: null,
  });

  const dispatch = useAppDispatch();

  const dispatchRemoveActions: IDispatchRemomve = {
    adults: {
      resourceId: ["CR10b", "CR2A", "CR3A", "CR11"],
      csId: ["F-CS-1", "F-CS-2", "F-CS-3", "F-CS-4"],
    },
    babies: {
      id: ["C16", "C8"],
      resourceId: ["CR5", "CR12A", "NG20"],
      csId: ["F-CS-5", "F-CS-6", "F-CS-7", "F-CS-8"],
    },
    toddlers: {
      id: ["C17", "C9"],
      resourceId: ["CR4", "CR13A", "NG21"],
    },
    teenagers: {
      id: ["C18", "C10"],
      resourceId: ["CR14A", "NG22"],
    },
  };

  const dispatchAddActions: IDispatchAdd = {
    adults: {
      resourceId: ["CR10b", "CR2A"],
      csId:
        home_property === "rented"
          ? ["F-CS-1", "F-CS-2"]
          : ["F-CS-3", "F-CS-4"],
      id: [],
    },
    babies: {
      id: ["C16"],
      resourceId: ["CR5", "CR12A", "NG20"],
      csId:
        home_property === "rented"
          ? ["F-CS-5", "F-CS-6"]
          : ["F-CS-7", "F-CS-8"],
    },
    toddlers: {
      id: ["C17"],
      resourceId: ["CR4", "CR13A", "NG21"],
      csId:
        home_property === "rented"
          ? ["F-CS-9", "F-CS-10"]
          : ["F-CS-11", "F-CS-12"],
    },
    teenagers: {
      id: ["C18"],
      resourceId: ["CR14A", "NG22"],
      csId:
        home_property === "rented"
          ? ["F-CS-9", "F-CS-10"]
          : ["F-CS-11", "F-CS-12"],
    },
  };

  const updateInterview = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = ev.target;
    const hasAdult = checkboxesRef.current.adults?.checked;
    const hasUnderU18 =
      checkboxesRef.current.babies?.checked ||
      checkboxesRef.current.toddlers?.checked ||
      checkboxesRef.current.teenagers?.checked;

    if (!checked) {
      const { resourceId, csId, id } =
        dispatchRemoveActions[name as keyof typeof dispatchRemoveActions];
      dispatch(removeResourceId(resourceId));
      if (csId) dispatch(removeCSId(csId));
      if (id) dispatch(removeId(id));
    } else {
      const { resourceId, csId, id } =
        dispatchAddActions[name as keyof typeof dispatchAddActions];
      dispatch(addResourceId(resourceId));
      dispatch(addCSId(csId));
      dispatch(addId(id));
    }

    if (hasUnderU18) {
      dispatch(addId(["C19"]));
      dispatch(addResourceId(livingVariety));
    } else {
      dispatch(removeId(["C19", "C5a", "C5b", "C6", "C21", ]));
      dispatch(removeResourceId(livingVariety));
      dispatch(removeResourceId(["CR12", "CR7A", "NG29", "NG28", "NG30"]));
      dispatch(removeWResourceId(["WR20", "WR18", "WR20", "WR21", "WR17", "WR17A"]));
    }

    dispatch(
      setCQuestionnaire({
        ...сhildrenOnboarding,
        agesGroup: {
          ...сhildrenOnboarding.agesGroup,
          [name]: checked,
        },
      })
    );
    if (!hasAdult && hasUnderU18) {
      setState((prevState) => ({
        ...prevState,
        errorMsg: false,
      }));
    }
  };

  const goToNext = (e: any) => {
    e.preventDefault();

    const choosed =
      !checkboxesRef.current.babies?.checked &&
      !checkboxesRef.current.toddlers?.checked &&
      !checkboxesRef.current.teenagers?.checked &&
      !checkboxesRef.current.adults?.checked;

    setState((prevState) => ({
      ...prevState,
      errorMsg: choosed,
    }));

    if (!choosed) {
      handleNextStep();
    }
  };

  return (
    <form id="form-1" onSubmit={goToNext} className={s.form}>
      <span className={s.stepMark}>1</span>

      <>
        <Htag tag="h3">
          Please tell us about your children - tick all of the age categories
          that apply to you and your ex.
        </Htag>

        <div className={s.form_oneline}>
          <div className={s.formField}>
            <FormGroup>
              <FormControlLabel
                checked={сhildrenOnboarding.agesGroup.babies}
                name="babies"
                control={
                  <Checkbox
                    color="default"
                    onChange={updateInterview}
                    inputRef={(ref) => (checkboxesRef.current.babies = ref)}
                  />
                }
                label="We have one or more children aged 0-3."
              />
              <FormControlLabel
                checked={сhildrenOnboarding.agesGroup.toddlers}
                name="toddlers"
                control={
                  <Checkbox
                    color="default"
                    onChange={updateInterview}
                    inputRef={(ref) => (checkboxesRef.current.toddlers = ref)}
                  />
                }
                label="We have one or more children aged 4-11."
              />
              <FormControlLabel
                checked={сhildrenOnboarding.agesGroup.teenagers}
                name="teenagers"
                control={
                  <Checkbox
                    color="default"
                    onChange={updateInterview}
                    inputRef={(ref) => (checkboxesRef.current.teenagers = ref)}
                  />
                }
                label="We have one or more children aged 12-17."
              />
              <FormControlLabel
                checked={сhildrenOnboarding.agesGroup.adults}
                name="adults"
                control={
                  <Checkbox
                    inputRef={(ref) => (checkboxesRef.current.adults = ref)}
                    color="default"
                    onChange={updateInterview}
                  />
                }
                label="We have one or more adult children."
              />
            </FormGroup>
            {state.errorMsg && (
              <ErrorField error={`Please select an option.`} />
            )}
          </div>
        </div>
      </>

      <Accordion className={s.desc} accordionData={answers} />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
