import { Alert, FormControl, Snackbar } from "@mui/material";
import { useState } from "react";
import s from "../SpecialSources.module.css";

import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import {
  Button,
  EditorComponent,
  ErrorField,
  Htag,
  InputFileComponent,
  InputImageComponent,
  ResourceIcon,
} from "components";
import { addExtraResource } from "store/extra_resources/extra_resource-thunk";
import * as yup from "yup";
import {
  FileProps,
  IAttach,
} from "store/system_resources/system_resource.interface";
import { Resource } from "models/Resource.model";
import InputTags from "components/Tags/Tags.component";
import { ITemplates } from 'store/tickets/tickets-interface';

export const validationExtraCreateSchema = yup.object({
  title: yup
    .string()
    .min(3, "Title should be at least 3 characters length")

    .required("Title is required"),
  unique_number: yup
    .string()
    .min(3, "Unique number should be at least 3 characters length")
    .required("Unique number is required"),
  reading_time: yup.string().required("Reading time is required"),
  content: yup.string().required("Content is required"),
});

export const AddExtraResource = () => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [attachments, setAttachments] = useState<
    Partial<FileProps | ITemplates>[]
  >([]);
  const { error } = useAppSelector((state) => state.specialResourcesState);
  const formik = useFormik({
    initialValues: new Resource(),
    validationSchema: validationExtraCreateSchema,
    onSubmit: (values) => {
      const updatedValue = formik.initialValues.formData(values);

      dispatch(addExtraResource(updatedValue))
        .unwrap()
        .then(() => {
          formik.resetForm();

          setAttachments((old) => []);
          setOpen(true);
        });
    },
  });

  const handleAttachments = (attachments: FileProps | IAttach) => {
    setAttachments((old) => [...old, attachments]);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div className={s.formWrap}>
        <Htag tag="h3">
          <ResourceIcon /> Add resource
        </Htag>
        <form onSubmit={formik.handleSubmit}>
          <div className={s.formField}>
            <Htag tag="h4">Title</Htag>
            <FormControl fullWidth className={s.title}>
              <input
                id="title"
                name="title"
                onBlur={formik.handleBlur}
                value={formik.values.title}
                type="text"
                onChange={formik.handleChange}
                placeholder="Title"
              />
              {formik.touched.title && formik.errors.title && (
                <ErrorField error={formik.errors.title} />
              )}
            </FormControl>
          </div>
          <div className={s.formField}>
            <Htag tag="h4">Reading time: </Htag>
            <FormControl fullWidth className={s.reading_time}>
              <input
                id="reading_time"
                name="reading_time"
                onBlur={formik.handleBlur}
                value={formik.values.reading_time}
                type="text"
                onChange={formik.handleChange}
                placeholder="Reading time"
              />
              {formik.touched.reading_time && formik.errors.reading_time && (
                <ErrorField error={formik.errors.reading_time} />
              )}
            </FormControl>
          </div>
          <div className={s.formField}>
            <Htag tag="h4">Unique number: </Htag>
            <FormControl fullWidth className={s.reading_time}>
              <input
                id="unique_number"
                name="unique_number"
                onBlur={formik.handleBlur}
                value={formik.values.unique_number}
                type="text"
                className={s.field_unique_number}
                onChange={formik.handleChange}
                placeholder="Unique number"
              />
              {formik.touched.unique_number && formik.errors.unique_number && (
                <ErrorField error={formik.errors.unique_number} />
              )}
            </FormControl>
          </div>
          <div className={s.formField}>
            <Htag tag="h4" className={s.headline}>
              Upload preview image
            </Htag>
            <InputImageComponent
              handleFiles={formik.setFieldValue}
              handleOnBlur={formik.setFieldValue}
              file={formik.values.image}
              id="image"
              name="image"
            />
          </div>
          <div className={s.formField}>
            <Htag tag="h4" className={s.headline}>
              Upload attachments
            </Htag>
            <InputFileComponent
              handleFiles={handleAttachments}
              id="extraResource"
            />
          </div>

          <div className={s.wyswyg_editorWrap}>
            <EditorComponent
              value={formik.values.content}
              handleChange={(e) => formik.setFieldValue("content", e)}
              name="content"
            />

            {formik.touched.content && formik.errors.content && (
              <ErrorField error={formik.errors.content} />
            )}
          </div>
          <div>
            <Htag tag="h3">Add tags:</Htag>

            <InputTags
              hashtag={formik.values.tags ? formik.values.tags : []}
              handleTags={(data) => formik.setFieldValue("tags", data)}
            />
          </div>
          {error &&
            Array.isArray(error) &&
            error.map((item, index) => (
              <ErrorField
                key={index}
                error={`Server error: ${Object.values(item)[0]}`}
              />
            ))}
          {error && typeof error == "string" && <ErrorField error={error} />}
          <Button apearance="secondary" type="submit">
            Save
          </Button>
        </form>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Resource {formik.values.title} is added successfully!
        </Alert>
      </Snackbar>
    </>
  );
};
