import s from "./GeneralOnboarding.module.css";
import { Accordion, Button, ErrorField, Htag } from "components";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useCallback } from "react";
import { addId, removeId } from "store/system_tickets/system_ticket-reducer";
import { QuestionnaireProps } from "pages/Dashboard/Spaces/Questionnaire.props";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useFormik } from "formik";
import * as yup from "yup";
import { addResourceId, removeResourceId } from 'store/system_resources/system_resource-reducer';

const validationForm6 = yup.object({
  place_of_marrige: yup
    .string()
    .nullable()
    .required("Please select an option."),
});

export const FormGB6 = ({
  handleBack,
  handleNextStep,
  setData,
}: QuestionnaireProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const updateInterview = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = ev.target;

      if (value === "no") {
        dispatch(addId(["D1"]));
        dispatch(addResourceId("NG15"));
      } else {
        dispatch(removeId(["D1"]));
		dispatch(removeResourceId("NG15"));
      }
      formik.handleChange(ev);
      setData(name, value);
    },
    [dispatch, generalOnboarding]
  );

  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationForm6,
    onSubmit: () => {
      handleNextStep();
    },
  });

  return (
    <form id="form-6" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>

      <Htag tag="h3">
        Did you get married or enter into a civil partnership in England or
        Wales?
      </Htag>

      <div className={s.form_oneline}>
        <div className={s.formField}>
          <RadioGroup row name="place_of_marrige" className={s.radioboxes}>
            <FormControlLabel
              control={<Radio color="default" onChange={updateInterview} />}
              label="Yes."
              value="yes"
              checked={formik.values.place_of_marrige == "yes"}
            />
            <FormControlLabel
              value="no"
              control={<Radio color="default" onChange={updateInterview} />}
              label="No, we had a ceremony abroad."
              checked={formik.values.place_of_marrige == "no"}
            />
          </RadioGroup>
          {formik.touched.place_of_marrige &&
            formik.errors.place_of_marrige && (
              <ErrorField error={formik.errors.place_of_marrige} />
            )}
        </div>
      </div>

      <Accordion
        className={s.desc}
        accordionData={[
          {
            title: `Why are we asking?`,
            content: `<p>Most people who live in England and Wales get married here.  If you got married abroad, it’s important to check that your ceremony was legally valid in that country. We’ll add this step to your action list if it’s relevant to you.</p>`,
          },
        ]}
      />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
