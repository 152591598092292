import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import { GET_ALL_MILESTONES, GET_MILESTONE } from "../config";
import { Milestone } from "./milestones-interface";
import { ITicket } from "../tickets/tickets-interface";
import { API_TYPE, getRequestFabric } from "../thunk-helper";

// Load data for 6 steps

export const loadMilestones = createAsyncThunk<
  Milestone[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@spaces/milestones",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_MILESTONES(),
      rejectWithValue,
      dispatch
    );
  }
);
// Load single step
export const loadMilestone = createAsyncThunk<
  ITicket[],
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@spaces/milestone",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_MILESTONE(id),
      rejectWithValue,
      dispatch
    );
  }
);
