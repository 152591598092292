import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_SYSTEM_TICKETS,
  GET_SINGLE_SYSTEM_TICKET,
  GET_SYSTEM_TICKETS,
  UPDATE_SYSTEM_TICKET,
} from "../config";
import { IInitState } from "../root-interface";
import { ISystemTicket } from "./system_ticket.interface";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const add_system_tickets = createAsyncThunk<
  { success: string },
  string[],
  { getState: IInitState; rejectValue: string }
>(
  "@@systemtickets/adding",
  async function (unique_numbers, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      ADD_SYSTEM_TICKETS(),
      rejectWithValue,
      dispatch,
      JSON.stringify({ unique_numbers: unique_numbers })
    );
  }
);

export const get_system_tickets = createAsyncThunk<
  ISystemTicket[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemtickets/getting",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SYSTEM_TICKETS(),
      rejectWithValue,
      dispatch
    );
  }
);

export const get_system_single_ticket = createAsyncThunk<
  ISystemTicket,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemtickets/getSingleTicket",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SINGLE_SYSTEM_TICKET(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const update_system_ticket = createAsyncThunk<
  { success: string; special_ticket: ISystemTicket },
  {
    id: string;
    data: {
      title: string;
      description: string;
      milestone_id?: number;
      attachments: any[];
      reading_time: string;
      guides: string[];
    };
  },
  { getState: IInitState; rejectValue: string }
>(
  "@@systemtickets/updateTicket",
  async function ({ id, data }, { getState, rejectWithValue, dispatch }) {
    const formData = new FormData();

    Object.entries({ ...data }).forEach(([key, value]) => {
      if (typeof value == "string") {
        formData.append(key, value);
      } else if (typeof value == "number") {
        formData.append(key, value.toString());
      }
    });
    data.attachments.forEach((file) => {
      formData.append("attachments[][file]", file.file);
      formData.append("attachments[][type]", file.type);
    });
    data.guides.map((guide: string) => {
      formData.append("guides[]", guide);
    });

    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      UPDATE_SYSTEM_TICKET(id),
      rejectWithValue,
      dispatch,
      formData
    );
  }
);
