import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import s from "../WellbeingAdmin.module.css";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import {
  deleteResource,
  deleteTool,
  loadResources,
  loadTools,
} from "../../../../store/challenges/challenges-thunk";
import { Button, Htag } from "../../../../components";
import { loadSystemWResources } from "../../../../store/challenges/wsystem_resources.thunk";

interface deleteObj {
  id: number;
  delFunc: (id: number) => void;
}

export const ListSourceWelbeing = () => {
  const { resources, system_resources } = useAppSelector(
    (state) => state.wellbeing
  );
  const { pathname } = useLocation();

  const tools = useAppSelector((state) => state.wellbeing.tools);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [checkedItem, setCheckedItem] = useState<deleteObj | null>(null);
  const handleClose = () => {
    setOpenDeleteDialog(false);
    setCheckedItem(null);
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadResources());
    dispatch(loadSystemWResources());
    dispatch(loadTools());
  }, [dispatch]);

  const copyResource = async (resource_id: string) => {
    await navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/dashboard/my-wellbeing/resource/${resource_id}`
    );
  };

  const copyTool = async (tool_id: string) => {
    await navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/dashboard/my-wellbeing/tool/${tool_id}`
    );
  };

  const handleDeleteTool = (id: number) => {
    if (!id) return;

    dispatch(deleteTool(id));
  };

  const handleDeleteResource = (id: number) => {
    if (!id) return;
    dispatch(deleteResource(id));
  };

  const confirmDelete = () => {
    checkedItem?.delFunc(checkedItem.id);
    setCheckedItem(null);
    setOpenDeleteDialog(false);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: s.headerClassName,
      width: 70,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerClassName: s.headerClassName,
      headerName: "Title",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "category",
      headerClassName: s.headerClassName,
      headerName: "Category",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.challenges.length > 0 && "Challenges"}
            {params.row.challenges.length > 0 &&
              params.row.feelings.length > 0 &&
              ", "}
            {params.row.feelings.length > 0 && "Feelings"}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: `Action`,
      headerClassName: s.headerClassName,
      sortable: false,
      width: 110,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <NavLink to={`${pathname}/tool/edit-page/${params.row.id}`}>
              <IconButton aria-label="edit" color="info">
                <Edit />
              </IconButton>
            </NavLink>
            <ContentCopyIcon
              className={s.externalLink}
              onClick={() => copyTool(params.row.id.toString())}
            />
            <IconButton
              aria-label="delete"
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                // handleDeleteTool(Number(params.id));
                setCheckedItem({
                  id: Number(params.row.id),
                  delFunc: handleDeleteTool,
                });
                setOpenDeleteDialog(true);
              }}
            >
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];

  const resourceColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: s.headerClassName,
      width: 70,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerClassName: s.headerClassName,
      headerName: "Title",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "category",
      headerClassName: s.headerClassName,
      headerName: "Category",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.challenges.length > 0 && "Challenges"}
            {params.row.challenges.length > 0 &&
              params.row.feelings.length > 0 &&
              ", "}
            {params.row.feelings.length > 0 && "Feelings"}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: s.headerClassName,
      sortable: false,
      disableColumnMenu: true,
      width: 115,
      renderCell: (params) => {
        return (
          <>
            <NavLink to={`${pathname}/resource/edit-page/${params.row.id}`}>
              <IconButton aria-label="edit" color="info">
                <Edit />
              </IconButton>
            </NavLink>
            <ContentCopyIcon
              className={s.externalLink}
              onClick={() => copyResource(params.row.id.toString())}
            />
            <IconButton
              aria-label="delete"
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                // handleDeleteResource(Number(params.id));
                setCheckedItem({
                  id: Number(params.row.id),
                  delFunc: handleDeleteResource,
                });
                setOpenDeleteDialog(true);
              }}
            >
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];

  const systemResourceColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: s.headerClassName,
      width: 70,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerClassName: s.headerClassName,
      headerName: "Title",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "category",
      headerClassName: s.headerClassName,
      headerName: "Category",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.challenges.length > 0 && "Challenges"}
            {params.row.challenges.length > 0 &&
              params.row.feelings.length > 0 &&
              ", "}
            {params.row.feelings.length > 0 && "Feelings"}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: s.headerClassName,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params) => {
        return (
          <>
            <NavLink to={`${pathname}/sresource/edit-page/${params.row.id}`}>
              <IconButton aria-label="edit" color="info">
                <Edit />
              </IconButton>
            </NavLink>
            <ContentCopyIcon
              className={s.externalLink}
              onClick={() => copyResource(params.row.id.toString())}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Htag tag="h3">List of tools</Htag>
      <Box sx={{ height: 475, width: "100%" }}>
        <DataGrid
          rows={tools}
          columns={columns}
          className={s.tableWrap}
          pageSizeOptions={[15, 30, 50, 70, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
        />
      </Box>

      <Htag tag="h3" className={s.table_title}>
        List of system resources
      </Htag>
      <Box sx={{ height: 475, width: "100%" }}>
        <DataGrid
          rows={system_resources}
          className={s.tableWrap}
          columns={systemResourceColumn}
          pageSizeOptions={[15, 30, 50, 70, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
        />
      </Box>

      <Htag tag="h3" className={s.table_title}>
        List of resources
      </Htag>
      <Box sx={{ height: 475, width: "100%" }}>
        <DataGrid
          rows={resources}
          className={s.tableWrap}
          columns={resourceColumn}
          pageSizeOptions={[15, 30, 50, 70, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
        />
      </Box>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={s.deleteConfirmDialog}
      >
        <DialogContent className={s.deleteConfirmText}>
          Please, confirm deleting
        </DialogContent>
        <DialogActions className={s.deleteConfirmBtn}>
          <Button apearance="secondaryDark" onClick={handleClose}>
            NO
          </Button>
          <Button apearance="secondaryDark" onClick={confirmDelete}>
            AGREE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
