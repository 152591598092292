import { Card, Htag } from "../../components";
import { IIssue } from "../../store/caseStudy/caseStudy.interface";
import s from "./ExtraCS.module.css";

import ReactHtmlParser from "html-react-parser";

interface IssuesData {
  data: IIssue[];
}

export const ExtraCaseStudyUser = ({ data }: IssuesData): JSX.Element => {
  return (
    <div className={s.problems_user_widget}>
      <Htag tag="h2" className={s.problemsTitle}>
        But what if…
      </Htag>
      <p>
        We know life isn’t strightforward. Click on an issue below to understand
        what impact it might have on the financial separation for this
        separating couple.
      </p>
      <div className={s.problemsList}>
        {data.map((item, index) => (
          <Card className={s.problem_item} key={index}>
            <Htag tag="h3" className={s.problem_title}>
              {item.title}
            </Htag>

            <div className={s.content}>
              {item &&
                typeof item.content == "string" &&
                ReactHtmlParser(item.content)}
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};
