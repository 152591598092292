import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ALL_TICKETS,
  CREATE_TICKET,
  DELETE_TICKET,
  GET_MILESTONE,
  GET_SINGLE_TICKET,
  SET_TICKET_STATUS,
  UPDATE_TICKET,
} from "../config";
import { IInitState } from "../root-interface";
import { ITicket, ResponseTypeTickets } from "./tickets-interface";

import { API_TYPE, getRequestFabric } from "store/thunk-helper";

export const loadTickets = createAsyncThunk<
  ResponseTypeTickets[],
  number,
  { getState: IInitState; rejectValue: string }
>(
  "loadTickets",
  async function (space_id, { getState, rejectWithValue, dispatch }) {
    return (await getRequestFabric(
      API_TYPE.GET,
      getState,
      ALL_TICKETS(space_id),
      rejectWithValue,
      dispatch
    ));
  }
);

export const loadMilestoneTickets = createAsyncThunk<
  ITicket[],
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@spaces/milestone",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return (await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_MILESTONE(id),
      rejectWithValue,
      dispatch
    )) ;
  }
);

export const loadSingleTicket = createAsyncThunk<
  ITicket,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/loadTicket",
  async function (ticket_id, { getState, rejectWithValue, dispatch }) {
    return (await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SINGLE_TICKET(ticket_id),
      rejectWithValue,
      dispatch
    )) as ITicket;
  }
);

export const setNewTicketStatus = createAsyncThunk<
  ITicket,
  { ticket_id: number | undefined; status: number; sort: number | undefined },
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/setStatus",
  async function (
    { ticket_id, status, sort },
    { getState, rejectWithValue, dispatch }
  ) {
    const data = JSON.stringify({ status: Number(status), sort: sort });

    return (await getRequestFabric(
      API_TYPE.PUT,
      getState,
      SET_TICKET_STATUS(ticket_id),
      rejectWithValue,
      dispatch,
      data
    )) as ITicket;
  }
);

export const createNewTicket = createAsyncThunk<
  { success: string; ticket: ITicket },
  { space_id: number | undefined; ticket_data: ITicket },
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/createTicket",
  async function (
    { space_id, ticket_data },
    { getState, rejectWithValue, dispatch }
  ) {
    const data = JSON.stringify(ticket_data);

    return (await getRequestFabric(
      API_TYPE.POST,
      getState,
      CREATE_TICKET(space_id),
      rejectWithValue,
      dispatch,
      data
    )) as { success: string; ticket: ITicket };
  }
);

export const updateTicket = createAsyncThunk<
  { success: string },
  { ticket_id: number; ticket: ITicket },
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/updateTicket",
  async function (
    { ticket_id, ticket },
    { getState, rejectWithValue, dispatch }
  ) {
    const data = JSON.stringify(ticket);

    return (await getRequestFabric(
      API_TYPE.PUT,
      getState,
      UPDATE_TICKET(ticket_id),
      rejectWithValue,
      dispatch,
      data
    )) as { success: string };
  }
);

export const deleteTicket = createAsyncThunk<
  { ticket_id: number },
  number | undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/deleteTicket",
  async function (ticket_id, { getState, rejectWithValue, dispatch }) {
    return (await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_TICKET(ticket_id),
      rejectWithValue,
      dispatch
    )) as { ticket_id: number };
  }
);
