import { createSlice } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import { IAnalyticsData } from "./analytics.interface";
import {
  getBookedSessionProgress,
  getChartsData,
  getLastActivitiesData,
  getUsersDataAllDaysStatistic,
  getUsersDataStatistic,
} from "./analytics-thunk";

const initialState: IAnalyticsData = {
  chartData: [],
  userData: {
    categories: [],
    data: [],
  },
  userFromStartData: {
    categories: [],
    data: [],
  },
  activities: {
    last_7_days: 0,
    last_14_days: 0,
    last_30_days: 0,
    last_90_days: 0,
    total: {
      data: [],
      categories: [],
    },
  },
  bookedSessions: {
    categories: [],
    data: [],
  },
  error: "",
  loading: false,
};

const analyticsData = createSlice({
  name: "@@analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initialState;
      })

      .addCase(getChartsData.fulfilled, (state, action) => {
        state.chartData = action.payload.chartData;
      })

      .addCase(getLastActivitiesData.fulfilled, (state, action) => {
        state.activities = action.payload;
      })
      .addCase(getUsersDataStatistic.fulfilled, (state, action) => {
        state.userData = action.payload.userData;
      })

      .addCase(getBookedSessionProgress.fulfilled, (state, action) => {
        state.bookedSessions = action.payload.bookedData;
      })

      .addCase(getUsersDataAllDaysStatistic.fulfilled, (state, action) => {
        state.userFromStartData = action.payload.userData;
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;

          if (action.payload) {
            state.error = action.payload;
          } else if (action.error.message) {
            state.error = action.error.message;
          } else {
            state.error = "Some server error";
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = "";
          state.loading = false;
        }
      );
  },
});

export default analyticsData.reducer;
