import s from "../User.module.css";
import { Alert, FormControl, Snackbar } from "@mui/material";

import { useFormik } from "formik";

import cn from "classnames";
import { useState } from "react";
import * as yup from "yup";

import { Button, ErrorField, Htag } from "components";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { changePassThunk, updateProfile } from "store/userInfo/user-thunk";

import { PasswordField } from "components/PasswordField/PasswordField.component";

const validationEmailSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const validationPasswordSchema = yup.object({
  old_password: yup
    .string()
    .min(8, "Old password should be at least 8 characters length")
    .required("Old password is required"),
  password: yup
    .string()
    .min(8, "Password should be at least 8 characters length")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Password is required"),

  password_confirmation: yup
    .string()
    .min(8, "Confirm Password should be at least 8 characters length")
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const ChangePassAccount = (): JSX.Element => {
  const account_email = useAppSelector((state) => state.userState.user.email);
  const [serverPassError, setServerPassError] = useState("");
  const [serverEmailError, setServerEmailError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const dispatch = useAppDispatch();
  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const initChangePass = {
    old_password: "",
    password: "",
    password_confirmation: "",
  };

  const initChangeEmail = {
    email: account_email,
  };

  const emailSettings = useFormik({
    initialValues: initChangeEmail,
    validationSchema: validationEmailSchema,
    onSubmit: (values) => {
      dispatch(
        updateProfile({
          email: values.email,
        })
      )
        .unwrap()
        .then(() => {
          setOpenSnackbar(true);
        })
        .catch((err) => {
          setServerEmailError(err);
        });
    },
  });

  const passSettings = useFormik({
    initialValues: initChangePass,
    validationSchema: validationPasswordSchema,
    onSubmit: (values) => {
      dispatch(changePassThunk(values))
        .unwrap()
        .then(() => {
          passSettings.resetForm();
          setOpenSnackbar(true);
        })
        .catch((err) => {
          console.log(err);
          setServerPassError(err);
        });
    },
  });

  return (
    <>
      <div className={s.loginWrapper}>
        <>
          <form onSubmit={emailSettings.handleSubmit} className={s.form}>
            <Htag tag="h3">Email settings</Htag>
            <div className={s.form_oneline}>
              <FormControl fullWidth>
                <input
                  id="email"
                  name="email"
                  type="text"
                  className={cn(s.form_field, {
                    [s.error_field]:
                      emailSettings.errors.email && emailSettings.touched.email,
                  })}
                  placeholder="Confirm old password"
                  value={emailSettings.values.email}
                  onChange={emailSettings.handleChange}
                  onBlur={emailSettings.handleBlur}
                />
                {emailSettings.touched.email && emailSettings.errors.email ? (
                  <ErrorField error={emailSettings.errors.email} />
                ) : null}
              </FormControl>
            </div>
            {serverEmailError ? <ErrorField error={serverEmailError} /> : null}
            <div className={s.buttonGroup}>
              <Button apearance="secondary" type="submit">
                Change email
              </Button>
            </div>
          </form>
          <form onSubmit={passSettings.handleSubmit} className={s.form}>
            <Htag tag="h3">Password settings</Htag>
            <div className={s.form_oneline}>
              <FormControl fullWidth>
                <PasswordField
                  name="old_password"
                  placeholder="Confirm old password"
                  value={passSettings.values.old_password}
                  handleChange={passSettings.handleChange}
                  handleBlur={passSettings.handleBlur}
                />

                {passSettings.touched.old_password &&
                passSettings.errors.old_password ? (
                  <ErrorField error={passSettings.errors.old_password} />
                ) : null}
              </FormControl>
            </div>
            <div className={s.form_oneline}>
              <FormControl fullWidth>
                <PasswordField
                  name="password"
                  placeholder="New password"
                  value={passSettings.values.password}
                  handleChange={passSettings.handleChange}
                  handleBlur={passSettings.handleBlur}
                />

                {passSettings.touched.password &&
                passSettings.errors.password ? (
                  <ErrorField error={passSettings.errors.password} />
                ) : null}
              </FormControl>
            </div>
            <div className={s.form_oneline}>
              <FormControl fullWidth>
                <PasswordField
                  name="password_confirmation"
                  placeholder="Confirm new password"
                  value={passSettings.values.password_confirmation}
                  handleChange={passSettings.handleChange}
                  handleBlur={passSettings.handleBlur}
                />

                {passSettings.touched.password_confirmation &&
                passSettings.errors.password_confirmation ? (
                  <ErrorField
                    error={passSettings.errors.password_confirmation}
                  />
                ) : null}
              </FormControl>
            </div>
            {serverPassError ? <ErrorField error={serverPassError} /> : null}
            <div className={s.buttonGroup}>
              <Button apearance="secondary" type="submit">
                Change Password
              </Button>
            </div>
          </form>
        </>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Security data was changed successfully!
        </Alert>
      </Snackbar>
    </>
  );
};
