import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { FullModalStyledProps } from "./FullModal.props";
import s from "./FullModal.module.css";
import cn from "classnames";
import { CloseIcon, LogoText } from "..";
import { useEffect, useRef } from "react";

const style = {
  boxShadow: 24,
  p: 4,
  transition: "all 0.2s ease",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

export const FullModalStyled = ({
  children,
  open,
  close,
  color,
  className,
}: FullModalStyledProps): JSX.Element => {
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
   
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      scroll="paper"
      className={cn(className, {
        [s.dark]: color == "dark",
        [s.light]: color == "light",
      })}
    >
      <DialogTitle id="scroll-dialog-title" className={s.modalTitle}>
        <LogoText className={s.logo} />
        <div onClick={close} className={s.close}>
          Close <CloseIcon />
        </div>
      </DialogTitle>
      <DialogContent className={cn(s.modalWrap)}>
        <div id="scroll-dialog-description" tabIndex={-1}>
          <Box sx={style} className={s.styledBox}>
            {children}
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  );
};
