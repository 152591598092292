import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import { ICaseStudies } from "./caseStudy.interface";
import {
  ADD_CS_KEYS,
  CREATE_CS,
  DELETE_CS,
  GET_ALL_CS,
  GET_ALL_SP_CS,
  GET_CS,
  GET_USER_SP_CS,
  UPDATE_CS,
} from "../config";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const add_cs = createAsyncThunk<
  { success: string },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/adding",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      CREATE_CS(),
      rejectWithValue,
      dispatch,
      data
    );
  }
);

export const edit_cs = createAsyncThunk<
  { success: string },
  { data: FormData; id: string },
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/edit",
  async function ({ data, id }, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      UPDATE_CS(id),
      rejectWithValue,
      dispatch,
      data
    );
  }
);

export const get_all_cs = createAsyncThunk<
  ICaseStudies[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/getAllCS",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_CS(),
      rejectWithValue,
      dispatch
    );
  }
);

export const get_user_all_cs = createAsyncThunk<
  ICaseStudies[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/getUserAllCS",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_USER_SP_CS(),
      rejectWithValue,
      dispatch
    );
  }
);

export const get_all_special_cs = createAsyncThunk<
  ICaseStudies[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/getAllCS",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_SP_CS(),
      rejectWithValue,
      dispatch
    );
  }
);

export const get_cs = createAsyncThunk<
  ICaseStudies,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/getCS",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_CS(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const deleteCS = createAsyncThunk<
  { id: number },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/deleteCS",
  async function (cs_id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_CS(cs_id),
      rejectWithValue,
      dispatch
    );
  }
);

export const add_case_studies = createAsyncThunk<
  { success: string },
  string[],
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/addingCs",
  async function (unique_numbers, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      ADD_CS_KEYS(),
      rejectWithValue,
      dispatch,
      JSON.stringify({ unique_numbers: unique_numbers })
    );
  }
);
