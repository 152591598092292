import {
  Checkbox,
  FormControl,
  InputBase,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import {
  Accordion,
  Htag,
  ModalStyled,
  PencilIcon,
  ResourceIcon,
  ScholarHat,
} from "components";
import ReactHtmlParser from "html-react-parser";
import s from "./Wellbeing.module.css";
import cn from "classnames";

import { useAppDispatch, useAppSelector } from "utils/hooks";
import relativeTime from "dayjs/plugin/relativeTime";

import {
  Challenge,
  Feelling,
  Wellbeing,
} from "store/challenges/challenges.interface";
import { useEffect, useState } from "react";
import { viewedResource, viewedTool } from "store/challenges/challenges-thunk";
import { setSourceVisible } from "store/challenges/challenges-reducer";

import dayjs from "dayjs";
import { Page404 } from "../../404/404.component";
const MenuProps = {
  PopoverClasses: {
    root: "customMenuPopover",
  },

  style: { backgroundColor: "#ff0000!important" },
};

const BootstrapInput = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    borderRadius: 0,
    position: "relative",
    backgroundColor: "transparent",
    borderBottom: "2px solid #102D3E",
    fontSize: 16,
    height: 30,
    minHeight: 28,
    padding: 0,
  },
  "& .MuiInputBase-input span": {
    display: "none",
  },
  "& .MuiInputBase-input:focus": {
    borderRadius: 0,
  },
}));

const timeFilterTypes = ["Resource", "Tool"];

export const WelbeingChallengeItem = () => {
  const { id } = useParams(); // id of resource or tool item
  dayjs.extend(relativeTime);
  const dispatch = useAppDispatch();

  const [viewedRCounter, setViewedRCounter] = useState(0); // Show how many times user click on view other resource
  const [viewedTCounter, setViewedTCounter] = useState(0); // Show how many times user click on view other tool

  const [filterTime, setFilterTime] = useState("");
  const [filterType, setFilterType] = useState("");

  const handleChangeTime = (event: SelectChangeEvent) => {
    setFilterTime(event.target.value);
  };
  const handleChangeType = (event: SelectChangeEvent) => {
    setFilterType(event.target.value);
  };

  const [showMessageExplain, setShowMessageExplain] = useState(
    viewedRCounter > 1 || viewedTCounter > 1
  );

  const diffFromNowR = dayjs(localStorage.getItem(`WellResource${id}`)).diff(
    dayjs().format(),
    "d"
  );

  const diffFromNowT = dayjs(localStorage.getItem(`WellTools${id}`)).diff(
    dayjs().format(),
    "d"
  );
  const [typeArticle, setTypeArticle] = useState("");

  const href = window.location.pathname;

  const item: Feelling = useAppSelector((state) => {
    if (href.includes("feelling")) {
      return state.wellbeing.feellings.feelings.filter(
        (item: Feelling) => item.id == id
      )[0];
    } else {
      return state.wellbeing.challenges.challenges.filter(
        (item: Challenge) => item.id == id
      )[0];
    }
  });

  useEffect(() => {
    if (href.includes("feelling")) {
      setTypeArticle("feelling");
    } else {
      setTypeArticle("challenges");
    }
  }, []);

  const resources: any[] = useAppSelector((state) => {
    if (!item) return [];
    const tempArr = state.wellbeing.resources
      .filter(
        (resource: Wellbeing) =>
          resource.id &&
          item.resources_id &&
          item.resources_id.find((el) => el == resource.id)
      )
      .filter((resource: Wellbeing) => !resource.viewed)
      //   .splice(0, 3)
      .map((el: Wellbeing) => ({ ...el, type: "wresources" }));

    return tempArr;
  });

  const tools: any[] = useAppSelector((state) => {
    if (!item) return [];
    const tempArr = state.wellbeing.tools
      .filter(
        (tool: Wellbeing) =>
          tool.id && item.tools_id && item.tools_id.find((el) => el == tool.id)
      )
      .filter((tool: Wellbeing) => !tool.viewed)
      .splice(0, 3)
      .map((el: Wellbeing) => ({ ...el, type: "wtools" }));

    return tempArr;
  });

  const concatSourceArr = resources
    .concat(tools)
    .sort(() => (Math.random() > 0.5 ? 1 : -1));

  const getTimeValues = () => {
    const allValues = resources
      .concat(tools)
      .map((a) => {
        if (a.reading_time) return a.reading_time.replace(/[^\w\s]/gi, "");
      })
      .filter((x) => x)
      .sort((a, b) => b - a);

    return allValues.filter((element, index) => {
      return allValues.indexOf(element) === index;
    });
  };

  const handleClose = () => {
    setShowMessageExplain(false);
  };

  useEffect(() => {
    if (viewedRCounter == 0 && diffFromNowR !== 0) {
      localStorage.removeItem(`WellResource${id}`);
    } else if (viewedRCounter > 1) {
      const d1 = dayjs().format();
      localStorage.setItem(`WellResource${id}`, d1);
    }
  }, [viewedRCounter]);

  useEffect(() => {
    if (viewedTCounter == 0 && diffFromNowT !== 0) {
      localStorage.removeItem(`WellTools${id}`);
    } else if (viewedTCounter > 1) {
      const d1 = dayjs().format();
      localStorage.setItem(`WellTools${id}`, d1);
    }
  }, [viewedTCounter]);

  const getUrl = (type: string | undefined, id: number | undefined) => {
    switch (type) {
      case "wresources":
      case "sresource":
        return `/dashboard/my-wellbeing/${typeArticle}/${item.id}/resource-${id}`;
        break;
      default:
        return `/dashboard/my-wellbeing/${typeArticle}/${item.id}/tool-${id}`;
    }
  };

  const showNextTool = () => {
    if (viewedTCounter > 2) return;
    dispatch(viewedTool(tools[0].id))
      .unwrap()
      .then(() => {
        dispatch(setSourceVisible({ id: tools[0].id, type: "tool" }));
        setViewedTCounter(viewedTCounter + 1);
      });
  };

  const showNextResource = () => {
    if (viewedTCounter > 2) return;
    dispatch(viewedResource(resources[0].id))
      .unwrap()
      .then(() => {
        dispatch(setSourceVisible({ id: resources[0].id, type: "resource" }));
        setViewedRCounter(viewedRCounter + 1);
      });
  };

  return (
    <>
      {item ? (
        <div
          className={cn(
            s.challenges_list,
            s.challenges_list_details,
            s.wellbeingWrap
          )}
        >
          {item.description && (
            <div className={s.wellbeing_explain_text}>
              <Accordion
                className={cn(s.accordionData, s.feeling_desc)}
                accordionData={[
                  {
                    title: `Take a moment to understand and support yourself with any ${item.title.toLowerCase()} you’re feeling`,
                    content: item.description,
                  },
                ]}
              ></Accordion>
            </div>
          )}
          {
            <>
              <Htag tag="h2" className={s.mainTitleWellbeing}>
                Here’s your toolkit
              </Htag>
              <Htag tag="h4">
                <ScholarHat /> Here’s a resource that might help
              </Htag>
              <List className={cn(s.challengeList, s.sourceItem)}>
                {resources.length > 0 &&
                (diffFromNowR || isNaN(diffFromNowR)) ? (
                  <ListItem key={Math.random()}>
                    <NavLink
                      className={s.challengeTitle}
                      to={`/dashboard/my-wellbeing/${typeArticle}/${item.id}/resource-${resources[0].id}`}
                    >
                      {resources[0].title}
                    </NavLink>
                  </ListItem>
                ) : (
                  ""
                )}
              </List>
              {resources.length > 0 && (diffFromNowR || isNaN(diffFromNowR)) ? (
                <span
                  className={cn(s.showMore, "hover-underline-animation")}
                  onClick={showNextResource}
                >
                  Show me something different
                </span>
              ) : (
                <p>There are no resources for you</p>
              )}
            </>
          }
          {
            <div className={s.toolsRecommendation}>
              <Htag tag="h4">
                <PencilIcon />
                And here’s a tool to help you
              </Htag>

              <List className={cn(s.challengeList, s.sourceItem)}>
                {tools.length > 0 && (diffFromNowT || isNaN(diffFromNowT)) ? (
                  <ListItem key={Math.random()}>
                    <NavLink
                      className={s.challengeTitle}
                      to={`/dashboard/my-wellbeing/${typeArticle}/${item.id}/tool-${tools[0].id}`}
                    >
                      {tools[0].title}
                    </NavLink>
                  </ListItem>
                ) : (
                  ""
                )}
              </List>
              {tools.length > 0 && (diffFromNowT || isNaN(diffFromNowT)) ? (
                <span
                  className={cn(s.showMore, "hover-underline-animation")}
                  onClick={showNextTool}
                >
                  Show me something different
                </span>
              ) : (
                <p>There are no tools for you</p>
              )}
            </div>
          }
          {tools.length !== 0 ||
          (resources.length !== 0 && (diffFromNowT || isNaN(diffFromNowT))) ? (
            <div className={s.recommendations}>
              <Htag tag="h3">
                <ResourceIcon /> Or take a look at this list
              </Htag>
              <div className={s.filter}>
                Filter
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  className={s.selectFilter}
                >
                  <Select
                    value={filterTime}
                    onChange={handleChangeTime}
                    displayEmpty
                    MenuProps={MenuProps}
                    input={<BootstrapInput className={s.inputSelect} />}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">By reading time</MenuItem>

                    {getTimeValues().map((x, index) => (
                      <MenuItem key={index} value={x}>
                        <Checkbox checked={filterTime.indexOf(x) > -1} /> {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  className={s.selectFilter}
                >
                  <Select
                    value={filterType}
                    onChange={handleChangeType}
                    displayEmpty
                    input={<BootstrapInput className={s.inputSelect} />}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">By type</MenuItem>

                    {timeFilterTypes.map((x, index) => (
                      <MenuItem key={index} value={x}>
                        <Checkbox checked={filterType.indexOf(x) > -1} /> {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {concatSourceArr
                .filter((x) => {
                  if (!filterTime)
                    return x.type.includes(filterType.toLowerCase());
                  return (
                    x.type.includes(filterType.toLowerCase()) &&
                    x.reading_time == filterTime
                  );
                })
                .map((source: Wellbeing) => (
                  <div className={s.recomendsItem} key={`${Math.random()}`}>
                    <Htag tag="h4">
                      <NavLink to={getUrl(source.type, source.id)}>
                        {source.type == "wresources" ||
                        source.type == "sresource" ? (
                          <ScholarHat />
                        ) : (
                          <PencilIcon />
                        )}
                        {source.title}
                      </NavLink>
                    </Htag>

                    {source.short_desc && (
                      <div className={s.shortDesc}>
                        {ReactHtmlParser(source.short_desc)}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          ) : (
            ""
          )}

          <NavLink
            to=".."
            relative="path"
            className={cn(s.backBtn, "hover-underline-animation_back")}
          >
            {"<"} Back
          </NavLink>
          <ModalStyled open={showMessageExplain} close={handleClose} size="l">
            <div className={cn(s.informText, s.challengeModal)}>
              <p>
                it doesn't look like you're finding what you're looking for
                today, it might be a good idea to come back to this another day
              </p>
            </div>
          </ModalStyled>
        </div>
      ) : (
        <Page404 className={s.componentNotFound} />
      )}
    </>
  );
};
