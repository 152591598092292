import { createAsyncThunk } from "@reduxjs/toolkit";
import { VERIFY_EMAIL } from "store/config";
import { IInitState } from "store/root-interface";
import { IUser } from "store/userInfo/user-interface";
import { API_TYPE, getRequestFabric } from "../thunk-helper";

export const verifyEmailAction = createAsyncThunk<
  { user: IUser; token: string },
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@verifyEmail",
  async function (verify_hash, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      VERIFY_EMAIL(),
      rejectWithValue,
      dispatch,
      JSON.stringify({ verify_hash: verify_hash })
    );
  }
);
