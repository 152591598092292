import { NavLink, useNavigate } from "react-router-dom";
import { ClockIcon, Htag } from "components";
import { useAppSelector } from "utils/hooks";
import s from "./SearchResults.module.css";
import { useEffect } from "react";
import { getArticleLink } from "controlls/use-link";
import { getTypeArticle } from "utils/utils";

export const SearchResults = (): JSX.Element => {
  const { search_results, search } = useAppSelector((state) => state.search);
  const navigate = useNavigate();

  useEffect(() => {
    if (!search) navigate("/dashboard");
  }, []);
  return (
    <div className={s.searchWrap}>
      {/* <Htag tag="h2" className={s.pageTitle}>
        Search
      </Htag> */}
      <Htag tag="h4" className={s.pageTitle}>
        Search results for "{search}"
      </Htag>
      {search_results.length ? (
        search_results.map((result, index) => (
          <div className={s.resultBlock} key={index}>
            <Htag className={s.title} tag="h4">
              <NavLink
                to={getArticleLink(result.type, result.id, result.space_id)}
              >
                {result.title}
              </NavLink>
            </Htag>
            <div className={s.typeMark}>{getTypeArticle(result.type)}</div>
            {result.reading_time && <span className={s.reading_time}>
              <ClockIcon /> {result.reading_time}
            </span>}
          </div>
        ))
      ) : (
        <>There no results</>
      )}
    </div>
  );
};



