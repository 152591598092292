import { FormControl } from "@mui/material";
import { SeoProps } from "./seo.props";
import { Htag } from "components";
import s from "./seo.module.css";
import cn from 'classnames';

export const SEOWidget = ({
  seoData,
  handleChangeSeo,
  handleBlur,
  className,
  ...props
}: SeoProps) => {
  return (
    <div className={cn(s.seoWidget, className)} {...props}>
      <Htag tag="h2" className="mb32">
        SEO settings
      </Htag>

      <FormControl fullWidth >
        <input
          className="mb15"
          id="seo.title"
          name="seo.title"
          type="text"
          onBlur={handleBlur}
          value={seoData.title}
          onChange={handleChangeSeo}
          placeholder="Title"
        />
      </FormControl>
      <FormControl fullWidth>
        <input
          className="mb15"
          id="seo.description"
          name="seo.description"
          type="text"
          onBlur={handleBlur}
          value={seoData.description}
          onChange={handleChangeSeo}
          placeholder="Description"
        />
      </FormControl>
      <FormControl fullWidth>
        <input
          id="seo.keywords"
          name="seo.keywords"
          type="text"
          onBlur={handleBlur}
          value={seoData.keywords}
          onChange={handleChangeSeo}
          placeholder="Keywords"
        />
      </FormControl>
    </div>
  );
};
