import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_WELLBEING_SYSTEM_RESOURCE,
  GET_SINGLE_WELLBEING_RESOURCE,
  GET_SYSTEM_WELLBEING_RESOURCES,
} from "../config";
import { IInitState } from "../root-interface";
import { Wellbeing } from "./challenges.interface";
import { API_TYPE, getRequestFabric } from "../thunk-helper";

export const loadSystemWResources = createAsyncThunk<
  Wellbeing[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/allWSystemResources",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SYSTEM_WELLBEING_RESOURCES(),
      rejectWithValue,
      dispatch
    );
  }
);

export const loadSingleWSResource = createAsyncThunk<
  Wellbeing,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/loadSingleWSReducer",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SINGLE_WELLBEING_RESOURCE(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const add_wsystem_resources = createAsyncThunk<
  { success: string },
  string[],
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/systemresourcesadding",
  async function (unique_numbers, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      ADD_WELLBEING_SYSTEM_RESOURCE(),
      rejectWithValue,
      dispatch,
      JSON.stringify({ unique_numbers: unique_numbers })
    );
  }
);
