import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useAppDispatch } from "utils/hooks";

import s from "./SavedArticles.module.css";
import { get_single_sresource } from "store/system_resources/system_resource-thunk";
import {
  loadSingleResource,
  loadSingleTool,
} from "store/challenges/challenges-thunk";
import { Wellbeing } from "store/challenges/challenges.interface";
import { Page404 } from "../../404/404.component";
import { ArticleContent } from "components";

export const SavedSingleArticle = () => {
  const { id, source } = useParams();
  const dispatch = useAppDispatch();

  const [item, setItem] = useState<Partial<Wellbeing>>(Object);

  useEffect(() => {
    if (!id || !item) return;
    if (Object.keys(item).length === 0) {
      if (source == "wresources") {
        dispatch(loadSingleResource(Number(id)))
          .unwrap()
          .then((res) => {
            setItem(res);
          });
      } else if (source == "wtools") {
        dispatch(loadSingleTool(Number(id)))
          .unwrap()
          .then((res) => {
            setItem(res);
          });
      } else {
        dispatch(get_single_sresource(id))
          .unwrap()
          .then((res: any) => {
            setItem(res);
          });
      }
    }
  }, []);

  return (
    <div className={s.article}>
      {Object.keys(item).length !== 0 && source ? (
        <ArticleContent type={source} data={item} />
      ) : (
        <>
          <Page404 className={s.componentNotFound} />
        </>
      )}
    </div>
  );
};
