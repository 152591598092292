import { FormControl, MenuItem, Select } from "@mui/material";
import { SelectProps } from "./Select.props";
import { DropDownArrowIcon } from "components/Icons/Icons.component";

export const SelectComponent = ({
  label,
  selectData,
  selectArray,
  handleData,
}: SelectProps) => {
  return (
    <>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectData.toString()}
          label={label}
          onChange={handleData}
          sx={{
            "& .MuiSelect-iconOpen": { transform: "rotate(90deg)" },
            "& .MuiSelect-icon": { top: "8px" },
            "& .Mui-focused": {
              color: "#102D3E",
            },
            "& .Mui-focused.MuiOutlinedInput-root": {
              border: "2px solid #91CCD1;",
            },
            "& .MuiOutlinedInput-input": {
              padding: "12px 10px 8px",
            },

            // height: "51px",
            // padding: "5px 0",
          }}
          IconComponent={(props) => {
            return <DropDownArrowIcon {...props} />;
          }}
        >
          {selectArray.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
