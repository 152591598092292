import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import { Registration } from "./onBoarding-interface";

const initialState: Registration = {
  interview: {
    local: false,
    terms: false,
    name: "",
    email: "",
    has_partner: false,
    verifyGoal: false,
    password: "",
    password_confirmation: "",
  },
  openModal: false,
  activeStep: 0,
  server_error: "",
};

const onBoardingState = createSlice({
  name: "@@onboarding",
  initialState,
  reducers: {
    setBoardingStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload + 1;
    },
    setPrevBoardingStep: (state, action) => {
      state.activeStep =
        action.payload > 0 ? action.payload - 1 : (action.payload = 0);
    },
    setInterview: (state, action) => {
      state.interview = action.payload;
    },
    addServerError: (state, action) => {
      state.server_error = action.payload;
    },
    setInterviewToInit: () => {
      return initialState;
    },
    setRegModalStatus: (state, action) => {
      state.openModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetToDefault, () => {
      return initialState;
    });
  },
});

export const {
  setBoardingStep,
  setPrevBoardingStep,
  setInterview,
  addServerError,
  setRegModalStatus,
  setInterviewToInit,
} = onBoardingState.actions;

export default onBoardingState.reducer;
