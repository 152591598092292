import { PageTemplateProps } from "./PageTemplate.props";
import { Page } from "store/simplePages/simplePages-inderfaces";
import { useAppSelector } from "utils/hooks";
import { MarketingTemplateComponent } from "./templates/MarketingTemplate.component";
import { DefaultTemplateComponent } from "./templates/DefaultTemplate.component";

export const PageTemplateComponent = ({ id }: PageTemplateProps) => {
  const page: Page = useAppSelector((state) =>
    state.pages.pages.filter((page: Page) => page.id == id)
  )[0];

  if (page.type == "marketing") {
    return <MarketingTemplateComponent page={page} />;
  } else {
    return <DefaultTemplateComponent page={page} />;
  }
};
