import { StepperProps } from "./Stepper.props";
import s from "./Stepper.module.css";
import cn from "classnames";
export const Stepper = ({
  steps,
  activeStep,
  className,
  ...props
}: StepperProps): JSX.Element => {
  const stepper: string[] = Array(steps).fill("");

  return (
    <div className={s.stepperWrap}>
      <p>{`${activeStep}/${stepper.length}`}</p>
      <div className={cn(s.stepper, className)} {...props}>
        {stepper.map((x, i) => (
          <span
            key={i}
            className={cn(s.stepIndicator, {
              [s.active]: i < activeStep  && activeStep > 0,
            })}
          ></span>
        ))}
      </div>
    </div>
  );
};
